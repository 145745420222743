import { Button, DatePicker, Flex, Form, Modal } from 'antd'
import { Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'

const ModalAddNew = ({ isModalOpen, handleCancel, handleOk }) => {
  const [form] = Form.useForm()

  const onSubmit = (values) => {
    const formData = { ...values }
    console.log(formData)
  }

  return (
    <Modal title="Tạo kiểm tra chất lượng" open={isModalOpen} onCancel={handleCancel} footer={false}>
      <Form layout="vertical" name="" form={form} onFinish={onSubmit}>
        <Typography.Text>Bạn muốn thêm mới kiểm tra chất lượng?</Typography.Text>
        <Form.Item
          label="Thời gian"
          name="date"
          style={{
            marginTop: 8
          }}
        >
          <DatePicker placeholder="Chọn thời gian" style={{ width: '100%' }} showTime format={'DD MM YYYY hh:mm'} />
        </Form.Item>
        <Form.Item label="Ý kiến phản hồi của KH" name="note">
          <TextArea placeholder="Nhập ý kiến phần hồi của khách hàng" />
        </Form.Item>
        <Flex justify="flex-end" gap={8}>
          <Button onClick={handleCancel}>Đóng</Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </Flex>
      </Form>
    </Modal>
  )
}

export default ModalAddNew
