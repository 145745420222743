import { Flex, Space } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import ListPage from '../../../components/list-page'
import ActivisionColumn from '../../../components/list-page/ActivisionColumn'
const Complain = () => {
  let navigate = useNavigate()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80
    },
    {
      title: 'Nguồn',
      dataIndex: 'source',
      key: 'source',
      width: 80,
      search: true
    },
    {
      title: 'Thời gian',
      dataIndex: 'date',
      key: 'date',
      search: true,
      inputType: 'dateRange',
      searchField: 'date'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      showSetting: false,
      search: true
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      showSetting: false,
      search: true
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customer',
      key: 'customer'
    },
    {
      title: 'Công trình',
      dataIndex: 'construction',
      key: 'construction',
      search: true,
      inputType: 'select',
      searchField: 'construction'
    },
    {
      title: 'Công ty',
      dataIndex: 'company',
      key: 'company',
      showSetting: false,
      search: true,
      inputType: 'select',
      searchField: 'company'
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title',
      showSetting: false
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
      showSetting: false,
      search: true,
      inputType: 'text',
      searchField: 'content'
    },
    {
      title: 'Quá hạn',
      dataIndex: 'overdue',
      key: 'overdue',
      width: 120
    },
    {
      title: 'Ngày giải quyết',
      dataIndex: 'resolutionDate',
      key: 'resolutionDate'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_, record) => (
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <ActivisionColumn status={record.status} />
        </Flex>
      ),
      inputType: 'select',
      search: true,
      searchField: 'status',
      option: []
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`chi-tiet/${record.id}`}>Edit</Link>
        </Space>
      ),
      align: 'center',
      fixed: 'right',
      width: 100
    }
  ]

  const handleCreate = () => {
    navigate('them-moi')
  }

  const fetchData = () => {
    const data = [
      {
        id: 0,
        source: '',
        date: '15/06/2024 11:56',
        email: 'username@gmail.com',
        phoneNumber: '09090909090',
        customer: 'User 1',
        construction: 'CT_Leme',
        company: 'CAREVN',
        title: 'Good',
        overdue: '00:20',
        resolutionDate: '15/06/2024 11:56',
        status: 'PROCESSING'
      },
      {
        id: 1,
        source: '',
        date: '15/06/2024 11:56',
        email: 'username@gmail.com',
        phoneNumber: '09090909090',
        customer: 'User 2',
        construction: 'CT_Leme',
        company: 'CAREVN',
        title: 'Good',
        overdue: '00:20',
        resolutionDate: '15/06/2024 11:56',
        status: 'SUCCESS'
      },
      {
        id: 2,
        source: '',
        date: '15/06/2024 11:56',
        email: 'username@gmail.com',
        phoneNumber: '09090909090',
        customer: 'User 2',
        construction: 'CT_Leme',
        company: 'CAREVN',
        title: 'Good',
        overdue: '00:20',
        resolutionDate: '15/06/2024 11:56',
        status: 'DEACTIVE'
      }
    ]
    return { data }
  }

  return <ListPage exportFile request={fetchData} key={'company'} columns={columns} onCreate={handleCreate} />
}

export default Complain
