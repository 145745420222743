import Status from './constantStatus'

export const enumBooleanSelectOptions = [
  { value: null, label: 'Tất cả' },
  { value: true, label: 'Có' },
  { value: false, label: 'Không' }
]

export const enumStatusSelectOptions = [
  { value: Status.ACTIVE.description, label: 'Hoạt động' },
  { value: Status.DEACTIVE.description, label: 'Khóa' },
  { value: Status.PENDING_PASSWORD_CHANGE.description, label: 'Chờ kích hoạt' },
  { value: Status.RECEIVE.description, label: 'Tiếp nhận' }
]

export const enumReasonType = [
  { id: 0, value: 'COMPLAINT', label: 'Khiếu nại' },
  { id: 1, value: 'INQUIRY', label: 'Hỏi thông tin' },
  { id: 2, value: 'FEEDBACK', label: 'Phản hồi' },
  { id: 3, value: 'SUPPORT', label: 'Yêu cầu hỗ trợ' },
  { id: 4, value: 'SERVICE', label: 'Yêu cầu dịch vụ' },
  { id: 5, value: 'OTHER', label: 'Khác' }
]

export const enumCustomer = [
  { id: 0, value: 'STAFF', label: 'Nhân viên' },
  { id: 1, value: 'CUSTOMER', label: 'Khách hàng' },
  { id: 2, value: 'OTHER', label: 'Khác' }
]

export const enumConstructionType = [
  { id: 0, value: 'R1', label: 'Công trình đang complain chất lượng từ các nguồn: Call center, điện thoại, email' },
  { id: 1, value: 'R2', label: 'Công trình theo yêu cầu nội bộ(Điều hành, HCNS, Ban giám đốc' },
  { id: 2, value: 'R3', label: 'Công trình nằm tronh DS gia hạn HD từ 30-60 ngày' },
  { id: 3, value: 'R4', label: 'Công trình triển khai sau 10 ngày' },
  { id: 4, value: 'R5', label: 'Khác' }
]

export const enumSourceType = [
  { id: 0, value: 'CALL', label: 'Gọi' },
  { id: 1, value: 'MAIL', label: 'Mail' },
  { id: 2, value: 'INTERNAL', label: 'Nội bộ' },
  { id: 3, value: 'OTHER', label: 'Khác' }
]

export const enumTimeType = [
  { id: 0, value: 'ADMINISTRATIVE', label: 'Hành chính' },
  { id: 1, value: 'MORNING', label: 'Sáng' },
  { id: 2, value: 'AFTERNOON', label: 'Chiều' },
  { id: 3, value: 'EVENING', label: 'Tối' }
]

export const enumStatusCallCenter = [
  { value: Status.SUCCESS.description, label: 'Hoàn thành' },
  // { value: Status.CLOSE.description, label: 'Đóng case' },
  { value: Status.PROCESSING.description, label: 'Đang xử lý' },
  { value: Status.CANCEL.description, label: 'Hủy' }
]

export const enumCustomServiceStatusSelectOptions = [
  { value: Status.COMPLETED.description, label: 'Hoàn thành' },
  { value: Status.PROCESSING.description, label: 'Chờ xử lý' },
  { value: Status.CANCELED.description, label: 'Hủy' }
]

export const enumTime = [
  { id: 0, value: 'Hành chính', label: 'Hành chính' },
  { id: 1, value: 'Sáng', label: 'Sáng' },
  { id: 2, value: 'Chiều', label: 'Chiều' },
  { id: 2, value: 'Tối', label: 'Tối' }
]

export const enumReason = [
  { id: 0, value: 'R1', label: 'R1' },
  { id: 1, value: 'R2', label: 'R2' },
  { id: 2, value: 'R3', label: 'R3' },
  { id: 3, value: 'R4', label: 'R4' }
]
