import { useState } from 'react'
import { Flex, message, Space } from 'antd'
import ListPage from '../../../../components/list-page'
import DepartmentDialog from '../components/DepartmentDialog'
import DepartmentListPersonnel from './DepartmentListPersonnel'
import { TeamOutlined } from '@ant-design/icons'
import BaseService from '../../../../api/base-service'
import { useParams } from 'react-router-dom'
import { ActivisionColumn } from '../../../../components/list-page/ActivisionColumn'
import { useMutation } from '@tanstack/react-query'
import Status from '../../../../constant/constantStatus'

const DepartmentBriefService = new BaseService('/core/departments/brief')
const CompanyDepartmentService = new BaseService('/core/companies/department')
const DepartmentService = new BaseService('/core/companies')

const CompanyDetailDepartment = () => {
  const { id } = useParams()
  const [listPersonnel, setListPersonnel] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [isModalPersonnelOpen, setIsModalIsPersonnelOpen] = useState(false)
  const [existingDepartmentCodes, setExistingDepartmentCodes] = useState([])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleModalPersonnel = (personnel) => {
    setListPersonnel(personnel)
    setIsModalIsPersonnelOpen(true)
  }

  const handleClosePersonnel = () => {
    setIsModalIsPersonnelOpen(false)
  }

  const columns = [
    {
      title: 'Mã phòng ban',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Nhân sự',
      dataIndex: 'personnel',
      key: 'personnel',
      render: (_, record) => (
        <Flex onClick={() => handleModalPersonnel(record.staffBriefDtos)} gap={12} align="center">
          <TeamOutlined />
          <div
            style={{
              textDecoration: 'underline',
              color: '#1890FF',
              fontWeight: 400,
              fontSize: 14,
              cursor: 'pointer'
            }}
          >
            {record?.staffBriefDtos.length || 0}
          </div>
        </Flex>
      )
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <ActivisionColumn status={record.status} />
        </Flex>
      ),
      align: 'center',
      width: 250
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          {record.status === Status.ACTIVE.description ? (
            <a
              onClick={() => {
                if (record.staffBriefDtos.length !== 0) {
                  message.warning('Chỉ có thể khóa khi không còn nhân viên trong phòng ban.')
                  return
                } else {
                  return handleLock.mutate(record.id)
                }
              }}
            >
              Khóa
            </a>
          ) : (
            <a onClick={() => handleUnLock.mutate(record.id)}>Mở khóa</a>
          )}
        </Space>
      ),
      align: 'center',
      fixed: 'right'
    }
  ]

  const handleLock = useMutation({
    mutationFn: (id) => DepartmentService.patch(id, 'lock-department-company'),
    onSuccess: () => {
      message.success('Mở thành công')
      setRefresh((prev) => !prev)
    }
  })

  const handleUnLock = useMutation({
    mutationFn: (id) => DepartmentService.patch(id, 'unlock-department-company'),
    onSuccess: () => {
      message.success('Khóa thành công')
      setRefresh((prev) => !prev)
    }
  })

  const fetchData = async () => {
    const res = await CompanyDepartmentService.getById(id)
    const data = res.data
    setListPersonnel(data)
    const departmentCodes = data.map((dep) => dep.code)
    setExistingDepartmentCodes(departmentCodes)
    return { data }
  }

  return (
    <>
      <ListPage
        refresh={refresh}
        request={fetchData}
        key={'company-department'}
        columns={columns}
        onCreate={showModal}
      />
      {isModalOpen && (
        <DepartmentDialog
          title="Chọn phòng ban"
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleOk={handleOk}
          apiEndpoint={DepartmentBriefService}
          existingCodes={existingDepartmentCodes}
          setRefresh={setRefresh}
        />
      )}
      {isModalPersonnelOpen && (
        <DepartmentListPersonnel
          listPersonnel={listPersonnel}
          isModalOpen={isModalPersonnelOpen}
          onCancel={handleClosePersonnel}
        />
      )}
    </>
  )
}

export default CompanyDetailDepartment
