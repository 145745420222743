import { Tabs } from 'antd'
import Home from '../../Home'
import ProjectComplaints from './ProjectComplaints'

const UserDetail = () => {
  const items = [
    {
      key: 'rojectComplaints',
      label: 'Số lần khiếu nại theo công trình',
      children: <ProjectComplaints />
    },
    {
      key: 'complaintRate',
      label: 'Tần suất khiếu nại',
      children: <Home />
    },
    {
      key: 'callStats',
      label: 'Thống kê cuộc gọi',
      children: <Home />
    },
    {
      key: 'emailStats',
      label: 'Thống kê mail',
      children: <Home />
    }
  ]

  return (
    <div>
      <Tabs defaultActiveKey="basic-info" items={items} />
    </div>
  )
}

export default UserDetail
