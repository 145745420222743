import { Tabs } from 'antd'
import Home from '../../Home'

const UserDetail = () => {
  const items = [
    {
      key: 'account',
      label: 'Tài khoản',
      children: <Home />
    },
    {
      key: 'role',
      label: 'Phân quyền',
      children: <Home />
    },
    {
      key: 'constructions',
      label: 'Công trình',
      children: <Home />
    }
  ]

  return (
    <div>
      <Tabs defaultActiveKey="basic-info" items={items} />
    </div>
  )
}

export default UserDetail
