import SVGIconsMenu from '../components/layout/iconMenu'
import Home from '../page/Home'
import CompanyDetail from '../page/system/company/CompanyDetail'
import CompanyList from '../page/system/company/CompanyList'
import ConfigPage from '../page/system/config'
import ShiftPage from '../page/system/config/working-shifts'
import DepartmentList from '../page/system/department/DepartmentList'
import RoleDetail from '../page/system/role/Modal/RoleDetail'
import RoleList from '../page/system/role/RoleList'
import DocumentSetting from '../page/system/config/document/DocumentSetting'
import UserDetail from '../page/system/user/UserDetail'
import UserList from '../page/system/user/UserList'
import CheckQuality from '../page/customer-service/check-quality/CheckQuality'
import CheckQualityDetail from '../page/customer-service/check-quality/CheckQualityDetail'
import Report from '../page/customer-service/report/Report'
import Complain from '../page/customer-service/complain/Complain'
import ComplainDetail from '../page/customer-service/complain/ComplainDetail'
import Training from '../page/customer-service/training/Training'
import TrainingDetail from '../page/customer-service/training/TrainingDetail'
import CallCenter from '../page/customer-service/call-center/CallCenter'
import CallCenterDetail from '../page/customer-service/call-center/CallCenterDetail'
import QCCalenderList from '../page/custom-service/qc-calendar/QCCalenderList'
import QCCalenderDetail from '../page/custom-service/qc-calendar/QCCalenderDetail'
import QCCalendarModal from '../page/custom-service/qc-calendar/components/QCCalendarModal'
import MailList from '../page/custom-service/mail/MailList'
import MailDetail from '../page/custom-service/mail/MailDetail'

const routes = [
  {
    path: '/',
    element: <Home />,
    icon: <SVGIconsMenu.menu1 />,
    label: 'Tổng quan'
  },
  {
    path: '/he-thong',
    element: <DepartmentList />,
    icon: <SVGIconsMenu.menu2 />,
    label: 'Hệ thống',
    children: [
      { path: '/phong-ban', element: <DepartmentList />, label: 'Phòng ban' },
      {
        path: '/cong-ty',
        element: <CompanyList />,
        label: 'Công ty',
        editelement: <CompanyDetail />,
        addelement: <CompanyDetail />
      },
      {
        path: '/phan-quyen',
        element: <RoleList />,
        label: 'Phân quyền',
        editelement: <RoleDetail />,
        addelement: <RoleDetail />
      },
      {
        path: '/nguoi-dung',
        element: <UserList />,
        label: 'Người dùng',
        editelement: <UserDetail />,
        addelement: <UserDetail />
      },
      {
        path: '/cau-hinh',
        element: <ConfigPage />,
        label: 'Cấu hình',
        children: [
          { path: '/tuy-chon', element: <ConfigPage />, label: 'Tùy chọn' },
          {
            path: '/ca-lam-viec',
            element: <ShiftPage />,
            label: 'Ca làm việc'
          },
          { path: '/ma-chung-tu', element: <DocumentSetting />, label: 'Mã chứng từ' }
        ]
      }
    ]
  },
  {
    path: '/kinh-doanh',
    element: <Home />,
    icon: <SVGIconsMenu.menu3 />,
    label: 'Kinh doanh'
  },
  {
    path: '/nhan-su',
    element: <Home />,
    icon: <SVGIconsMenu.menu4 />,
    label: 'Nhân sự',
    children: [
      { path: '/cong-trinh', element: <DepartmentList />, label: 'Công trình' },
      {
        path: '/ho-so-nv',
        element: <CompanyList />,
        label: 'Hồ sơ NV'
      },
      { path: '/phep-nam', element: <Home />, label: 'Phép năm' },
      { path: '/nghi-che-do', element: <Home />, label: 'Nghỉ chế độ' },
      { path: '/bang-cham-cong', element: <Home />, label: 'Bảng chấm công' },
      { path: '/nv-ve-vp', element: <Home />, label: 'NV về VP' },
      { path: '/cong-van', element: <Home />, label: 'Công văn' },
      { path: '/kpis', element: <Home />, label: 'KPIs' },
      { path: '/tuyen-dung', element: <Home />, label: 'Tuyển dụng' },
      { path: '/dao-tao', element: <Home />, label: 'Đào tạo' },
      { path: '/bao-cao', element: <Home />, label: 'Báo cáo' },
      {
        path: '/dong-phuc',
        element: <Home />,
        label: 'Đồng phục',
        children: [
          { path: '/dat-hang', element: <DepartmentList />, label: 'Đặt hàng' },
          {
            path: '/phieu-nhap',
            element: <CompanyList />,
            label: 'Phiếu nhập'
          },
          { path: '/phieu-xuat', element: <Home />, label: 'Phiếu xuất' },
          { path: '/ton-kiem-ke', element: <Home />, label: 'Tồn - Kiểm kê' },
          { path: '/ma-dong-phuc', element: <Home />, label: 'Mã Đồng Phục' },
          { path: '/ket-chuyen', element: <Home />, label: 'Kết chuyển' },
          { path: '/bao_cao', element: <Home />, label: 'Báo cáo' }
        ]
      }
    ]
  },
  {
    path: '/dieu-hanh',
    element: <Home />,
    icon: <SVGIconsMenu.menu5 />,
    label: 'Điều hành'
  },
  {
    path: '/tai-san',
    element: <Home />,
    icon: <SVGIconsMenu.menu6 />,
    label: 'Tài sản'
  },
  {
    path: '/ke-toan',
    element: <Home />,
    icon: <SVGIconsMenu.menu7 />,
    label: 'Kế toán'
  },
  {
    path: '/tai-chinh',
    element: <Home />,
    icon: <SVGIconsMenu.menu8 />,
    label: 'Tài chính'
  },
  {
    path: '/ky-thuat',
    element: <Home />,
    icon: <SVGIconsMenu.menu9 />,
    label: 'Kỹ thuật'
  },
  {
    path: '/dich-vu-khach-hang',
    element: <Home />,
    icon: <SVGIconsMenu.menu10 />,
    label: 'Customer Service',
    children: [
      {
        path: '/tong-dai',
        element: <CallCenter />,
        label: 'Tổng đài',
        editelement: <CallCenterDetail />,
        addelement: <CallCenterDetail />
      },
      { path: '/mail', element: <MailList />, label: 'Mail', addelement: <MailDetail />, editelement: <MailDetail /> },
      {
        path: '/khieu-nai',
        element: <Complain />,
        label: 'Khiếu nại',
        editelement: <ComplainDetail />,
        addelement: <ComplainDetail />
      },
      {
        path: '/lich-qc',
        element: <QCCalenderList />,
        label: 'Lịch QC',
        addelement: <QCCalendarModal />,
        editelement: <QCCalenderDetail />
      },
      {
        path: '/dao-tao',
        element: <Training />,
        label: 'Đào tạo',
        editelement: <TrainingDetail />,
        addelement: <TrainingDetail />
      },
      {
        path: '/phieu-kiem-tra',
        element: <CheckQuality />,
        label: 'Phiếu kiểm tra',
        editelement: <CheckQualityDetail />,
        addelement: <CheckQualityDetail />
      },
      { path: '/bao-cao', element: <Report />, label: 'Báo cáo' },
      { path: '/cai-dat', element: <Home />, label: 'Cài đặt' }
    ]
  },
  {
    path: '/hau-can-mua-hang',
    element: <Home />,
    icon: <SVGIconsMenu.menu11 />,
    label: 'Logistics & Purchase'
  }
]
export default routes
