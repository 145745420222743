import { UploadOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Flex, Form, Input, Row, Select, Spin, Tabs, Typography, Upload } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const { Text } = Typography

const CheckQualityDetail = () => {
  const [form] = Form.useForm()
  const { id } = useParams()
  const navigate = useNavigate()

  const handleClose = () => {
    navigate('/dich-vu-khach-hang/phieu-kiem-tra')
  }

  useEffect(() => {
    form.setFieldValue('id', id)
  }, [id])

  return (
    <>
      <div>
        <Spin spinning={false}>
          <Form layout="vertical" form={form} name="check-quality">
            <Row gutter={[16, 0]}>
              <Col span={3}>
                <Text>Thông tin chung</Text>
              </Col>
              <Col span={21}>
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item label="ID" name="id">
                      <Input placeholder="Nhập ID" disabled={id} />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Ngày" name="date">
                      <DatePicker placeholder="Chọn ngày" allowClear style={{ width: '100%' }} disabled={id} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Nguồn" name="srouce">
                      <Select allowClear placeholder="Chọn nguồn" options={[]} disabled={id} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Khách hàng" name="customer">
                      <Input placeholder="Nhập tên khách hàng" disabled={id} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Công ty" name="company">
                      <Select allowClear placeholder="Chọn công ty" options={[]} disabled={id} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Công trình" name="construction">
                      <Select allowClear options={[]} placeholder="Nhập công trình" disabled={id} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Giám sát khu vực" name="GSKV">
                      <Select allowClear placeholder="Chọn GSKV" options={[]} disabled={id} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
            <Row
              gutter={[16, 0]}
              style={{
                marginBottom: 24
              }}
            >
              <Col span={3}>
                <Text>Thông tin kết quả</Text>
              </Col>
              <Col span={21}>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Form.Item label="Ý kiến khách hàng" name="customerReviews">
                      <TextArea rows={2} placeholder="Nhập ý kiến khách hàng" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Phản hồi của bộ phận điều hành" name="executiveFeedback">
                      <TextArea rows={2} placeholder="Nhập nội dung" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Row gutter={[24, 0]}>
                      <Col span={24}>
                        <Form.Item name="CSFeedback" label="Phản hồi của CS">
                          <TextArea rows={2} placeholder="Nhập phản hồi của CS" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Row gutter={[24, 0]}>
                      <Col span={12}>
                        <Form.Item name="beforeComplaint" label="Trước khiếu nại">
                          <Upload
                            action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                            listType="picture"
                            defaultFileList={[]}
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="afterComplaint" label="Sau khiếu nại">
                          <Upload
                            action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                            listType="picture"
                            defaultFileList={[]}
                            onDownload
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
            <Row
              gutter={[16, 0]}
              style={{
                marginBottom: 24
              }}
            >
              <Col span={3}>
                <Text>Liên kết</Text>
              </Col>
              <Col span={21}>
                <Form.Item>
                  <Flex align="center" gap={20}>
                    <div>Khiếu nại:</div>
                    <div
                      style={{
                        backgroundColor: '#EFF3FA',
                        padding: '6px 10px',
                        borderRadius: 4,
                        color: '#3D62BF',
                        fontWeight: 500,
                        textDecoration: 'underline',
                        textAlign: 'center'
                      }}
                    >
                      CS.CP24.0014
                    </div>
                  </Flex>
                </Form.Item>
                <Form.Item name="dateQC" initialValue="CS.CP24.0014" hidden>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />

            <Flex justify="flex-end" gap={15}>
              <Button onClick={handleClose}>Đóng case</Button>
              <Button onClick={handleClose}>Đóng</Button>
              <Button onClick={handleClose}>Hủy</Button>
              <Button type="primary" htmlType="submit">
                {id ? 'Cập nhật' : 'Lưu'}
              </Button>
            </Flex>
          </Form>
        </Spin>
      </div>
    </>
  )
}

export default CheckQualityDetail
