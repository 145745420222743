import ListPage from '../../../components/list-page'
import { ActivisionColumn } from '../../../components/list-page/ActivisionColumn'
import { Button, Flex } from 'antd'
import SVGIcons from '../../../assets/icons/svg-icons'

const HistoryCall = () => {
  const fetchData = () => {
    const data = [
      {
        id: 1,
        phoneNumber: '09090909090',
        type: 'CALLING',
        dateCall: '15/06/2024 11:41',
        time: '00:01:22',
        status: 'CALLING'
      },
      {
        id: 2,
        phoneNumber: '09090909090',
        type: 'MISSING',
        dateCall: '15/06/2024 11:41',
        time: '00:01:22',
        status: 'MISSING'
      },
      {
        id: 3,
        phoneNumber: '09090909090',
        type: 'MISSING',
        dateCall: '15/06/2024 11:41',
        time: '00:01:22',
        status: 'MISSING'
      }
    ]
    return { data }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      mainSearch: true,
      filterStyle: { width: 30, minWidth: 120 }
    },
    {
      title: 'Ngày gọi',
      dataIndex: 'dateCall',
      key: 'dateCall',
      width: 150,
      mainSearch: true,
      inputType: 'dateRange',
      filterStyle: { minWidth: 100 }
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 140,
      mainSearch: true,
      filterStyle: { minWidth: 100 }
    },
    {
      title: 'Thời lượng',
      dataIndex: 'time',
      key: 'time',
      width: 120,
      align: 'center',
      filterStyle: { minWidth: 100 }
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      width: 120,
      align: 'center',
      render: (_, record) => (record?.type === 'CALLING' ? <SVGIcons.PhoneIn /> : <SVGIcons.PhoneOut />),
      inputType: 'select',
      filterStyle: { minWidth: 100 },
      mainSearch: true,
      options: [
        { id: 0, value: 'GOIDI', label: 'Gọi đi' },
        { id: 1, value: 'GOIDEN', label: 'Gọi đến' },
        { id: 2, value: 'TATCA', label: 'Tất cả' }
      ]
    },

    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_, record) => (
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <ActivisionColumn status={record?.status} />
        </Flex>
      ),
      width: 150,
      filterStyle: { minWidth: 120 },
      mainSearch: true,
      inputType: 'select',
      options: [
        { id: 0, value: 'CALLING', label: 'Nghe máy' },
        { id: 1, value: 'MISSING', label: 'Gọi nhỡ' }
      ]
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, record) => <Button>Chọn</Button>
    }
  ]
  return (
    <div>
      <ListPage
        filterStyle={{
          width: 200
        }}
        searchPrimaryKey={false}
        showSettingContent={false}
        showRefreshButton={false}
        request={fetchData}
        key={'switchBoard'}
        columns={columns}
      />
    </div>
  )
}

export default HistoryCall
