import React, { useEffect, useState } from 'react'
import { Col, Row, Tabs, Form, Input, Divider, Space, Button, Spin, message } from 'antd'
import Title from 'antd/es/typography/Title'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import BaseService from '../../../../api/base-service'
import TablePermission from '../components/TablePermission'
import TabBarExtraContent from '../../../../components/ui/TabBarExtraContent'

const RoleDetail = () => {
  const ModuleService = new BaseService('/account/module/all')
  const RoleService = new BaseService('/account/api/roles')
  const [form] = Form.useForm()
  const [tablesState, setTablesState] = useState({})
  const [dataTabs, setDataTabs] = useState([])
  const { id } = useParams()
  let navigate = useNavigate()

  const ModuleQuery = useQuery({
    queryKey: ['/account/module/all'],
    queryFn: async () => await ModuleService.get()
  })

  const RoleQuery = useQuery({
    queryKey: ['/account/api/roles', id],
    queryFn: async () => {
      if (id) {
        return await RoleService.getById(id)
      }
      return
    }
  })

  const { mutate } = useMutation({
    mutationFn: async (body) => {
      if (id) return await RoleService.update(id, body)
      return await RoleService.create(body)
    },
    onMutate: () => {},
    onSuccess: (res) => {
      if (res.code === 2000) {
        message.success('Form submitted successfully!')
        form.resetFields()
        handleClose()
      } else {
        throw new Error(`Update failed with status ${res.status}`)
      }
    },
    onError: (error) => {
      message.error(`Form submission failed: ${error.message}`)
    },
    onSettled: () => {}
  })

  useEffect(() => {
    let dataInput = ModuleQuery.data?.data

    if (id) {
      dataInput = RoleQuery.data?.data
    }
    if (dataInput) {
      processData(dataInput)
    }
  }, [RoleQuery.data, ModuleQuery.data])

  const processData = (data) => {
    if (data) {
      let inputData = data
      if (id) {
        const res = {
          code: data?.code ?? '',
          label: data?.label ?? '',
          note: data?.note ?? ''
        }
        form.setFieldsValue(res)
        inputData = data.permissions
      }

      if (Array.isArray(inputData) && inputData.length > 0) {
        const newTabs = inputData.map((item, index) => {
          const dataTab = item.componentPermissions.map((el) => ({
            ...el,
            children: el.childrenComponents?.length > 0 ? el.childrenComponents : undefined,
            childrenComponents: undefined
          }))

          return {
            ...item,
            key: item.code,
            label: item.label,
            children: <TablePermission data={dataTab} index={index} updateParentState={updateParentState} />
          }
        })

        setDataTabs(newTabs)
      } else {
        setDataTabs([])
      }
    }
  }

  const updateParentState = (index, data) => {
    setTablesState((prevState) => ({
      ...prevState,
      [index]: data
    }))
  }

  const extractCheckedActions = (data) => {
    const result = []

    const processComponent = (component) => {
      component.actions.forEach((action) => {
        if (action.isChecked) {
          result.push({ actionId: action.id, componentId: component.componentId })
        }
      })

      if (component.anotherActions) {
        component.anotherActions.forEach((action) => {
          if (action.isChecked) {
            result.push({ actionId: action.id, componentId: component.componentId })
          }
        })
      }

      if (component.children) {
        component.children.forEach((child) => {
          processComponent(child)
        })
      }
    }

    data.forEach((module) => {
      const { componentPermissions } = module

      componentPermissions.forEach((permission) => {
        const itemModule = permission.itemModule || permission
        processComponent(itemModule)
      })
    })

    return result
  }

  const onFinishFailed = () => {}

  const onFinish = (values) => {
    const dataTable = Object.values(tablesState).flatMap((innerArr) => innerArr)
    const dataResult = dataTabs.map((module) => {
      const modulePermissions = module.componentPermissions.map((itemModule) => {
        const findItem = dataTable?.find((item) => item.componentId === itemModule.componentId)
        if (findItem) {
          return { ...findItem }
        }
        return { itemModule }
      })
      return {
        ...module,
        componentPermissions: modulePermissions
      }
    })

    const payload = {
      ...values,
      permissions: extractCheckedActions(dataResult),
      templateId: !id ? 0 : undefined
    }

    mutate(payload)
  }

  const handleClose = () => {
    navigate('/he-thong/phan-quyen')
  }
  const handleReset = () => {
    let dataDefault = ModuleQuery.data?.data
    if (id) {
      dataDefault = {
        ...RoleQuery.data?.data,
        permissions: ModuleQuery.data?.data
      }
    }

    if (dataDefault) {
      processData(dataDefault)
    }
  }

  return (
    <Spin spinning={!!ModuleQuery.isLoading || !!RoleQuery.isLoading}>
      <Form form={form} layout="vertical" onFinishFailed={onFinishFailed} onFinish={onFinish} autoComplete="off">
        <Row>
          <Col span={5}>
            <Title level={5} style={{ margin: 0 }}>
              Thông tin chung
            </Title>
          </Col>
          <Col span={19}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Mã vai trò" name="code" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Tên vai trò" name="label" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Ghi chú" name="note">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Tabs
          tabBarExtraContent={{
            left: <TabBarExtraContent text="Nhóm chức năng" key={'company-role'} />
          }}
          tabPosition="left"
          defaultActiveKey="1"
          items={dataTabs}
        />
        <Form.Item style={{ marginTop: '16px', textAlign: 'right' }}>
          <Space>
            <Button htmlType="button" onClick={handleReset}>
              Reset
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default RoleDetail
