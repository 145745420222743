import { useEffect, useState } from 'react'
import { Table, Checkbox, Flex } from 'antd'

const TablePermissions = ({ data, index, updateParentState }) => {
  const [tableData, setTableData] = useState(data)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    updateParentState(index, tableData)
  }, [index, tableData, updateParentState])

  useEffect(() => {
    const updatedData = updateParentViewStatus(tableData)
    if (JSON.stringify(updatedData) !== JSON.stringify(tableData)) {
      setTableData(updatedData)
    }
    const selectedKeys = []
    const checkAllActions = (data) => {
      data.forEach((item) => {
        const allActionsChecked = (item.actions || []).every((action) => action.isChecked)
        if (allActionsChecked) {
          selectedKeys.push(item.componentId)
        }
        if (item.children) {
          checkAllActions(item.children)
        }
      })
    }
    checkAllActions(tableData)
    setSelectedRowKeys(selectedKeys)
  }, [tableData])

  useEffect(() => {
    setTableData(data)
  }, [data])

  const handleCheckboxChange = (key, actionCode, checked) => {
    const updateData = (data) => {
      return data.map((item) => {
        if (item.componentId === key) {
          const updatedActions = item.actions?.map((action) => {
            if (action.code === actionCode || (checked && action.code === 'VIEW')) {
              return { ...action, isChecked: checked }
            }
            return action
          })

          const updatedAnotherActions = item.anotherActions?.map((action) => {
            if (action.code === actionCode || (checked && action.code === 'VIEW')) {
              return { ...action, isChecked: checked }
            }
            return action
          })

          return { ...item, actions: updatedActions, anotherActions: updatedAnotherActions }
        }
        if (item.children) {
          return { ...item, children: updateData(item.children) }
        }
        return item
      })
    }

    setTableData(updateData(tableData))
  }

  const handleRowSelection = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
    const updateSelection = (data, selectedKeys) => {
      return data.map((item) => {
        const isSelected = selectedKeys.includes(item.componentId)
        const updatedActions = item.actions?.map((action) => ({ ...action, isChecked: isSelected }))
        const updatedAnotherActions = item.anotherActions?.map((action) => ({ ...action, isChecked: isSelected }))
        if (item.children) {
          const updatedChildren = updateSelection(item.children, selectedKeys)
          return {
            ...item,
            actions: updatedActions,
            anotherActions: updatedAnotherActions,
            children: updatedChildren
          }
        }
        return { ...item, actions: updatedActions, anotherActions: updatedAnotherActions }
      })
    }
    setTableData(updateSelection(tableData, selectedRowKeys))
  }

  const updateParentViewStatus = (data) => {
    return data.map((item) => {
      if (item.children) {
        const anyChildHasViewChecked = item.children.some((child) => {
          return child.actions?.some((action) => action.code === 'VIEW' && action.isChecked)
        })

        const updatedActions = item.actions?.map((action) => {
          if (action.code === 'VIEW') {
            return { ...action, isChecked: anyChildHasViewChecked || action.isChecked }
          }
          return action
        })

        const updatedItem = { ...item, actions: updatedActions }
        const updatedChildren = updateParentViewStatus(item.children)
        if (updatedChildren !== item.children) {
          updatedItem.children = updatedChildren
        }
        return updatedItem
      }
      return item
    })
  }

  const columns = [
    {
      title: 'Tính năng',
      dataIndex: 'label'
    },
    {
      title: 'Xem',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (actions, record) => {
        const itemData = actions?.find((item) => item.code === 'VIEW')
        return (
          <Checkbox
            checked={itemData?.isChecked ?? false}
            onChange={(e) => handleCheckboxChange(record.componentId, 'VIEW', e.target.checked)}
          />
        )
      }
    },
    {
      title: 'Thêm',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (actions, record) => {
        const itemData = actions?.find((item) => item.code === 'CREATE')
        return (
          <Checkbox
            checked={itemData?.isChecked ?? false}
            onChange={(e) => handleCheckboxChange(record.componentId, 'CREATE', e.target.checked)}
          />
        )
      }
    },
    {
      title: 'Sửa',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (actions, record) => {
        const itemData = actions?.find((item) => item.code === 'EDIT')
        return (
          <Checkbox
            checked={itemData?.isChecked ?? false}
            onChange={(e) => handleCheckboxChange(record.componentId, 'EDIT', e.target.checked)}
          />
        )
      }
    },
    {
      title: 'Xuất file',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (actions, record) => {
        const itemData = actions?.find((item) => item.code === 'EXPORT_FILE')
        return (
          <Checkbox
            checked={itemData?.isChecked ?? false}
            onChange={(e) => handleCheckboxChange(record.componentId, 'EXPORT_FILE', e.target.checked)}
          />
        )
      }
    },
    {
      title: 'Khóa/Mở khóa',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (actions, record) => {
        const itemData = actions?.find((item) => item.code === 'TOGGLE_LOCK')
        return (
          <Checkbox
            checked={itemData?.isChecked ?? false}
            onChange={(e) => handleCheckboxChange(record.componentId, 'TOGGLE_LOCK', e.target.checked)}
          />
        )
      }
    },
    {
      align: 'center',
      title: 'Khác',
      dataIndex: 'anotherActions',
      render: (anotherActions, record) => {
        return (
          <Flex align="start">
            {anotherActions.map((item, index) => (
              <Checkbox
                key={index}
                checked={item.isChecked || false}
                onChange={(e) => handleCheckboxChange(record.componentId, item.code, e.target.checked)}
              >
                {item.label}
              </Checkbox>
            ))}
          </Flex>
        )
      }
    }
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      handleRowSelection(selectedRowKeys)
    },
    onSelect: () => {},
    onSelectAll: () => {}
  }

  return (
    <Table
      rowKey="componentId"
      columns={columns}
      rowSelection={rowSelection}
      dataSource={tableData}
      scroll={{ x: 600 }}
    />
  )
}

export default TablePermissions
