import { Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import Title from 'antd/es/typography/Title'
import CompanyDetailBasicInfo from './basicInfo/CompanyDetailBasicInfo'
import CompanyDetailDepartment from './department/CompanyDetailDepartment'
import CompanyDetailSetting from './setting/CompanyDetailSetting'
import DocumentSining from './documentSigning/DocumentSining'

const CompanyDetail = () => {
  const pathName = useParams()

  const items = [
    {
      key: 'basic-info',
      label: 'Thông tin chung',
      children: <CompanyDetailBasicInfo />
    },
    {
      key: 'department',
      label: 'Phòng ban',
      children: <CompanyDetailDepartment />
    },
    {
      key: 'setting',
      label: 'Cài đặt',
      children: <CompanyDetailSetting />
    },
    {
      key: 'document-signing',
      label: 'Mẫy ký chứng từ',
      children: <DocumentSining />
    }
  ]

  return (
    <div>
      <Title level={4} style={{ margin: 0 }}>
        {pathName?.id ? 'Cập nhật thông tin' : 'Thêm mới'}
      </Title>
      <Tabs
        defaultActiveKey="basic-info"
        items={pathName?.id ? items : items.splice(0, 1)}
        unmountInactiveTabs={true}
      />
    </div>
  )
}

export default CompanyDetail
