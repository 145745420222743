import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  LockFilled,
  QuestionCircleOutlined,
  SyncOutlined
} from '@ant-design/icons'
import { Tag, Space } from 'antd'
import Status from '../../constant/constantStatus'
import Staff from '../../constant/constantStaffCode'
import ReasonValue from '../../constant/constantReason'

export const ActivisionColumn = ({ status }) => {
  switch (status) {
    case Status.ACTIVE.description:
      return (
        <Tag bordered={false} color="success" icon={<CheckCircleOutlined />}>
          Hoạt động
        </Tag>
      )
    case Status.DEACTIVE.description:
      return (
        <Tag style={{ margin: 0 }} color="error" icon={<LockFilled />}>
          Khóa
        </Tag>
      )
    case Status.PENDING_PASSWORD_CHANGE.description:
      return (
        <Tag style={{ margin: 0 }} color="warning" icon={<ExclamationCircleOutlined />}>
          Chờ kích hoạt
        </Tag>
      )
    case Status.SUCCESS.description:
      return (
        <Tag style={{ margin: 0 }} color="success" icon={<CheckCircleOutlined />}>
          Hoàn thành
        </Tag>
      )
    case Status.HANDLE.description:
      return (
        <Tag style={{ margin: 0 }} color="purple">
          Xử lý
        </Tag>
      )
    case Status.RECEIVE.description:
      return (
        <Tag style={{ margin: 0 }} color="processing">
          Tiếp nhận
        </Tag>
      )
    case Status.CLOSE.description:
      return (
        <Tag style={{ margin: 0 }} color="error">
          Đóng case
        </Tag>
      )
    case Status.CALLING.description:
      return (
        <Tag style={{ margin: 0 }} color="success" bordered={false}>
          Nghe máy
        </Tag>
      )
    case Status.MISSING.description:
      return (
        <Tag style={{ margin: 0 }} color="warning" bordered={false}>
          Gọi nhỡ
        </Tag>
      )
    case Status.CANCEL.description:
      return (
        <Tag style={{ margin: 0 }} color="purple" bordered={false}>
          Hủy
        </Tag>
      )
    case Status.COMPLETED.description:
      return (
        <Tag bordered={false} color="success" icon={<CheckCircleOutlined />}>
          Hoàn thành
        </Tag>
      )
    case Status.CANCELED.description:
      return (
        <Tag bordered={false} color="error" icon={<CloseCircleOutlined />}>
          Hủy
        </Tag>
      )
    case Status.PROCESSING.description:
      return (
        <Tag bordered={false} color="processing" icon={<SyncOutlined />}>
          Đang xử lý
        </Tag>
      )
    default:
      return (
        <Tag style={{ margin: 0 }} color="default" icon={<QuestionCircleOutlined />}>
          Không xác định
        </Tag>
      )
  }
}

export const StaffCodeColumn = ({ staff }) => {
  switch (staff) {
    case Staff.CUSTOMER.description:
      return <Space>Khách hàng</Space>
    case Staff.STAFF.description:
      return <Space>Nhân viên</Space>
    case Staff.OTHER.description:
      return <Space>Khác</Space>
    default:
      return <Space>Không xác định</Space>
  }
}

export const ReasonTypeValue = ({ reason }) => {
  switch (reason) {
    case ReasonValue.COMPLAINT.description:
      return <Space>Khiếu nại</Space>
    case ReasonValue.INQUIRY.description:
      return <Space>Hỏi thông tin</Space>
    case ReasonValue.FEEDBACK.description:
      return <Space>Phản hồi</Space>
    case ReasonValue.SUPPORT.description:
      return <Space>Yêu cầu hỗ trợ</Space>
    case ReasonValue.SERVICE.description:
      return <Space>Yêu cầu dịch vụ</Space>
    case ReasonValue.OTHER.description:
      return <Space>Khác</Space>
    default:
      return <Space>Không xác định</Space>
  }
}

export default ActivisionColumn
