import {
  AutoComplete,
  Button,
  Card,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Progress,
  Row,
  Select,
  Spin,
  Tabs,
  Typography
} from 'antd'
import SVGIcons from '../../../assets/icons/svg-icons'
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { useRef, useState, useEffect } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { ActivisionColumn } from '../../../components/list-page/ActivisionColumn'
import { enumCustomer, enumReasonType } from '../../../constant/enumSelectOptions'
import { useNavigate, useParams } from 'react-router-dom'
import HistoryCall from './HistoryCall'
import ReactAudioPlayer from 'react-audio-player'
import { formatTime } from '../common/FormatTime'
import BaseService from '../../../api/base-service'
import { URL_CALL_CENTERS, URL_COMPANY_BRIEF } from '../../../constant/constantURL'
import { useQuery } from '@tanstack/react-query'
import useConstructionsBriefs from '../../../hooks/system/useConstructionsBrief'
import useStaffBrief from '../../../hooks/system/useStaffBrief'
const { Text } = Typography

const callCentersService = new BaseService(URL_CALL_CENTERS)
const companiesBrief = new BaseService(URL_COMPANY_BRIEF)

const CallCenterDetail = () => {
  const [form] = Form.useForm()
  const audioRef = useRef()
  const [isPlay, setIsPlay] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [reason, setReason] = useState(enumReasonType)
  const [customer, setCustomer] = useState(enumCustomer)
  const [staffs, setStaffs] = useState([])
  const [solutions, setSolutions] = useState([{ id: Date.now(), disabled: false }])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [constructions, setConstructions] = useState([])
  const { id } = useParams()
  const navigate = useNavigate()
  const constructionResult = useConstructionsBriefs()
  const staffResult = useStaffBrief()

  const { data } = useQuery({
    queryKey: ['call-center'],
    id,
    queryFn: async () => await callCentersService.getById(id),
    enabled: !!id
  })

  const handleAddNew = () => {
    const updatedSolutions = solutions.map((solution) => ({ ...solution, disabled: true }))
    setSolutions([...updatedSolutions, { id: Date.now(), disabled: false }])
  }

  const handleClose = () => {
    navigate('/dich-vu-khach-hang/tong-dai')
  }

  const handlePlayPause = () => {
    const audioElement = audioRef.current.audioEl.current

    if (isPlay) {
      audioElement.pause()
    } else {
      audioElement.play()
    }
    setIsPlay(!isPlay)
  }

  useEffect(() => {
    const audioElement = audioRef.current?.audioEl.current

    if (audioElement) {
      const handleLoadedMetadata = () => {
        setDuration(audioElement.duration)
      }

      const handleTimeUpdate = () => {
        setCurrentTime(audioElement.currentTime)
      }

      audioElement.addEventListener('loadedmetadata', handleLoadedMetadata)
      audioElement.addEventListener('timeupdate', handleTimeUpdate)

      return () => {
        audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
        audioElement.removeEventListener('timeupdate', handleTimeUpdate)
      }
    }
  }, [audioRef])

  useEffect(() => {
    form.setFieldValue('id', id)
  }, [id])

  const onSubmit = (values) => {
    const formData = { ...values }
    console.log(formData)
  }

  useEffect(() => {
    if (constructionResult.data?.data) {
      setConstructions(
        constructionResult.data.data.map((value) => {
          return { value: value?.id, label: value?.name }
        })
      )
    }
  }, [constructionResult.data])

  console.log(constructions)

  useEffect(() => {
    if (staffResult?.data?.data) {
      setStaffs(
        staffResult?.data?.data?.map((staff) => {
          return {
            value: staff?.id,
            // label: staff?.name,
            label: staff?.code,
            name: `Nguyễn Văn ${staff?.code}`
          }
        })
      )
    }
  }, [staffResult.data])

  const handleStaffChange = (value, option) => {
    form.setFieldsValue({
      staffId: value,
      fullName: option?.name ? option.name : undefined
    })
  }

  return (
    <>
      <div>
        <Spin spinning={false}>
          <Form layout="vertical" form={form} onFinish={onSubmit} name="switch-board">
            <Row gutter={[16, 0]}>
              <Col span={3}>
                <Text>Thông tin chung</Text>
              </Col>
              <Col span={21}>
                <Row style={{ marginBottom: 24 }}>
                  <Button onClick={() => setIsModalOpen(true)}>
                    <SVGIcons.HistoryClock />
                    <Text>Lịch sử cuộc gọi</Text>
                  </Button>
                </Row>
                <Row gutter={[16, 0]}>
                  {id && (
                    <Col span={6}>
                      <Form.Item label="ID" name="id">
                        <Input placeholder="Nhập ID" disabled={id} />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={6}>
                    <Form.Item label="Họ tên" name="fullName">
                      <Input placeholder="Nhập họ và tên" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Mã NV" name="staffId">
                      <Select allowClear placeholder="Nhập mã NV" options={staffs} onChange={handleStaffChange} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Công ty" name="companyId">
                      <Select placeholder="Nhập công ty" options={[]} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Thời gian"
                      name="time"
                      required={true}
                      rules={[{ required: true, message: 'Vui lòng nhập thời gian' }]}
                    >
                      <DatePicker
                        showTime
                        placeholder="Nhập/chọn thời gian"
                        format={'DD MM YYYY HH:mm:ss'}
                        style={{
                          width: '100%'
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Nhóm" name="groupType">
                      <Select allowClear placeholder="Chọn nhóm" options={customer} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="SĐT"
                      name="phoneNumber"
                      required={true}
                      rules={[{ whitespace: true, required: true, message: 'Vui lòng nhập SĐT' }]}
                    >
                      <Input placeholder="Nhập SĐT" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Công trình" name="constructionId">
                      <Select allowClear options={constructions} placeholder="Nhập công trình" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Phân loại" name="reasonType">
                      <Select allowClear placeholder="Chọn phân loại" options={reason} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="GSKV" name="supervisorId">
                      <Select allowClear placeholder="Chọn GSKV" options={[]} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
            <Row
              gutter={[16, 0]}
              style={{
                marginBottom: 24
              }}
            >
              <Col span={3}>
                <Text>Ghi âm</Text>
              </Col>
              {id && (
                <Col span={21}>
                  <Form.Item name="infoPhone">
                    <Flex gap={12}>
                      <SVGIcons.PhoneIn />
                      <Text>18/06/2024 14:52</Text>
                    </Flex>
                    <Flex
                      gap={24}
                      justify="space-between"
                      align="center"
                      style={{
                        marginBottom: 24
                      }}
                    >
                      {isPlay ? (
                        <PauseCircleOutlined
                          style={{
                            fontSize: 24
                          }}
                          onClick={handlePlayPause}
                        />
                      ) : (
                        <PlayCircleOutlined
                          style={{
                            fontSize: 24
                          }}
                          onClick={handlePlayPause}
                        />
                      )}
                      <div>{formatTime(currentTime)}</div>
                      <ReactAudioPlayer ref={audioRef} src="./1.mp3" controls={false} />
                      <Progress percent={(currentTime / duration) * 100} showInfo={false} />
                      <div>{formatTime(duration)}</div>
                      <SVGIcons.Trash />
                    </Flex>
                  </Form.Item>
                  {/* <div>
                    <Flex gap={12}>
                      <SVGIcons.PhoneOut />
                      <Text>18/06/2024 14:52</Text>
                    </Flex>
                    <Flex gap={24} justify="space-between" align="center">
                      {isPlay ? (
                        <PauseCircleOutlined
                          style={{
                            fontSize: 24
                          }}
                          onClick={() => setIsPlay(!isPlay)}
                        />
                      ) : (
                        <PlayCircleOutlined
                          style={{
                            fontSize: 24
                          }}
                          onClick={() => setIsPlay(!isPlay)}
                        />
                      )}
                      <div>{formatTime(currentTime)}</div>
                      <Progress percent={(currentTime / duration) * 100} showInfo={false} />
                      <div>{formatTime(duration)}</div>
                      <SVGIcons.Trash />
                    </Flex>
                  </div> */}
                </Col>
              )}
            </Row>
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
            <Row
              gutter={[16, 0]}
              style={{
                marginBottom: 24
              }}
            >
              <Col span={3}>
                <Text>Thông tin chi tiết</Text>
              </Col>
              <Col span={21}>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Form.Item label="Tiêu đề" name="title">
                      <Input placeholder="Nhập tiêu đề" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Nội dung" name="content">
                      <Input placeholder="Nhập nội dung" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <div
                      style={{
                        paddingBottom: 8
                      }}
                    >
                      Hướng giải quyết và phản hồi
                    </div>
                    <Card>
                      <Row gutter={[24, 0]}>
                        {solutions.map((solution, index) => (
                          <Col
                            key={solution.id}
                            span={24}
                            style={{
                              marginBottom: 20
                            }}
                          >
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '24px' }}>
                              <div style={{ flex: 1 }}>
                                <Flex
                                  justify="space-between"
                                  style={{
                                    paddingBottom: 8
                                  }}
                                >
                                  <Text>Giải quyết {index + 1}</Text>
                                  <Text>{id && `NV${solution.id} - 11/06/2024 12:45`}</Text>
                                </Flex>
                                <Form.Item name="solution">
                                  <TextArea rows={4} placeholder="Nhập hướng giải quyết" disabled={solution.disabled} />
                                </Form.Item>
                              </div>
                              <div style={{ flex: 1 }}>
                                <Flex
                                  justify="space-between"
                                  style={{
                                    paddingBottom: 8
                                  }}
                                >
                                  <Text>Ý kiến phản hồi </Text>
                                  <Text>{id && `NV${solution.id} - 11/06/2024 12:45`}</Text>
                                </Flex>
                                <Form.Item name="feedback">
                                  <TextArea rows={4} placeholder="Nhập ý kiến phản hồi" disabled={solution.disabled} />
                                </Form.Item>
                              </div>
                            </div>
                          </Col>
                        ))}
                        <Col span={8}>
                          <Flex gap={10}>
                            <Button onClick={handleAddNew}>Thêm hướng giải quyết tiếp theo</Button>
                            <Button>Thêm cuộc gọi</Button>
                          </Flex>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            {id && (
              <>
                <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
                <Row
                  gutter={[16, 0]}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Col span={3}>
                    <div>Tiếp nhận & xử lý</div>
                  </Col>
                  <Col span={21}>
                    <Flex gap={16}>
                      <Card
                        style={{
                          width: 260
                        }}
                      >
                        <Flex
                          justify="space-between"
                          style={{
                            marginBottom: 4
                          }}
                        >
                          <Text color="#344054">Lê Thị Như Ý</Text>
                          <ActivisionColumn status="RECEIVE" />
                        </Flex>
                        <Flex justify="space-between" align="center" gap={16}>
                          <div>F2000001</div>
                          <SVGIcons.Dot />
                          <div
                            style={{
                              whiteSpace: 'nowrap'
                            }}
                          >
                            14/06/2024 11:33
                          </div>
                        </Flex>
                      </Card>
                      <Card
                        style={{
                          width: 260
                        }}
                      >
                        <Flex
                          justify="space-between"
                          style={{
                            marginBottom: 4
                          }}
                        >
                          <Text color="#344054">---</Text>
                          <ActivisionColumn status="CLOSE" />
                        </Flex>
                        <Flex justify="space-between" align="center" gap={16}>
                          <div>---</div>
                          <SVGIcons.Dot />
                          <div>---</div>
                        </Flex>
                      </Card>
                      <Card
                        style={{
                          width: 260
                        }}
                      >
                        <Flex
                          justify="space-between"
                          style={{
                            marginBottom: 4
                          }}
                        >
                          <Text color="#344054">---</Text>
                          <ActivisionColumn status="CANCEL" />
                        </Flex>
                        <Flex justify="space-between" align="center" gap={16}>
                          <div>---</div>
                          <SVGIcons.Dot />
                          <div>---</div>
                        </Flex>
                      </Card>
                    </Flex>
                  </Col>
                </Row>
              </>
            )}
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
            <Flex justify="flex-end" gap={15}>
              {id && <Button onClick={handleClose}>Đóng case</Button>}
              <Button onClick={handleClose}>Hủy</Button>
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
            </Flex>
          </Form>
        </Spin>
      </div>
      <Modal
        title="Liên kết cuộc gọi"
        visible={isModalOpen}
        width={1200}
        footer={false}
        onCancel={() => setIsModalOpen(false)}
      >
        <HistoryCall />
      </Modal>
    </>
  )
}

export default CallCenterDetail
