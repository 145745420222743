import { useEffect, useRef, useState } from 'react'
import { Col, Flex, Menu, Popconfirm, Row } from 'antd'
import DropdownMenu from './../../assets/imgs/svg/dropdownMenu.svg'
import routes from '../../config/configRouter'
import { Link } from 'react-router-dom'
import SVGIconsMenu from './iconMenu'

const Navbar = () => {
  const [current, setCurrent] = useState({
    key: '',
    children: [],
    title: ''
  })
  const [keyActive, setKeyActive] = useState('')
  const [activePop, setActivePop] = useState(false)
  const [routesDefault, setRoutesDefault] = useState([])

  const fetchDefaultMenu = () => {
    const newKey = window.location.pathname.split('/')
    const newRoutes = routes.map((item) => ({
      ...item,
      title: item?.label,
      key: item?.path,
      label: item?.path ? (
        <Link to={item?.path + `${item?.children ? item.children?.[0].path : ''}`}>{item.label}</Link>
      ) : (
        item.label
      ),
      children: item?.children?.map((item1) => ({
        ...item1,
        title: item1?.label,
        key: item1?.path,
        label: item1?.path ? (
          <Link
            to={item?.path + item1?.path + `${item1?.children ? item1.children?.[0].path : ''}`}
            onClick={() => {
              if (item1?.children && item1.children?.[0].path) {
                setKeyActive(item1.children?.[0].path)
              }
            }}
          >
            <Flex align="center" gap={12}>
              {item1.label}
              {item1?.children && <SVGIconsMenu.ArrowDown />}
            </Flex>
          </Link>
        ) : (
          item1.label
        ),
        children: item1?.children?.map((item2) => ({
          ...item2,
          title: item2?.label,
          key: item2?.path,
          label: item2?.path ? <Link to={item?.path + item1?.path + item2?.path}>{item2.label}</Link> : item2.label
        }))
      }))
    }))
    const currentItem = newRoutes.filter((item) => item.path === `/${newKey[1]}`)?.[0]
    setRoutesDefault(newRoutes)
    setKeyActive(newKey[3] && !['them-moi', 'chi-tiet'].includes(newKey[3]) ? `/${newKey[3]}` : `/${newKey[2]}`)
    setCurrent({ ...currentItem, key: newKey[3] ? `/${newKey[3]}` : `/${newKey[2]}` })
  }

  useEffect(() => {
    fetchDefaultMenu()
  }, [routes])

  const onClick = (e) => {
    setKeyActive(e.key)
  }
  const handleActivePop = (e) => {
    setActivePop(!activePop)
  }
  const renderMenu = () => {
    // handle active menu level 1
    const arrPathName = window.location.pathname.split('/')
    const pathName = arrPathName?.[1] ? arrPathName?.[1] : arrPathName?.[0]

    return (
      <Row gutter={16} className="popup-menu">
        {routesDefault?.map((item, index) => {
          return (
            <Col
              key={index}
              className={`gutter-row ${item.path.replaceAll('/', '') === pathName ? 'menu-active' : ''} `}
              span={8}
              onClick={() => {
                handleActivePop()
                setKeyActive(item.children?.[0].path)
                setCurrent({ ...item, key: item.children?.[0].path })
              }}
              style={{ cursor: 'pointer' }}
            >
              <Link to={item?.path + `${item?.children ? item.children?.[0].path : ''}`}>
                <div>{item.icon}</div>
                <div>{item.title}</div>
              </Link>
            </Col>
          )
        })}
      </Row>
    )
  }
  const isDescendant = (parent, child) => {
    let node = child.parentNode
    while (node != null) {
      if (node === parent) {
        return true
      }
      node = node.parentNode
    }
    return false
  }
  const targetRef = useRef(null)
  const popconfirmRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        targetRef.current &&
        !targetRef.current.contains(event.target) &&
        popconfirmRef.current &&
        !isDescendant(popconfirmRef.current, event.target)
      ) {
        setActivePop(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
  return (
    <Flex gap={17} align="center" className="nav-wrap">
      <Flex gap={10}>
        <Popconfirm
          placement="bottomRight"
          title=<div className="nav-title">Menu</div>
          description={renderMenu}
          okButtonProps={{ style: { display: 'none' } }}
          okType=""
          showCancel={false}
          icon={null}
          open={activePop}
          ref={popconfirmRef}
        >
          <img ref={targetRef} onClick={handleActivePop} src={DropdownMenu} alt="Logo" />
        </Popconfirm>
        <h3 style={{ margin: 0 }}>{current?.title}</h3>
      </Flex>
      <Menu
        onClick={onClick}
        selectedKeys={[keyActive]}
        mode="horizontal"
        items={current?.children}
        style={{ flex: 1, height: 40 }}
      />
    </Flex>
  )
}

export default Navbar
