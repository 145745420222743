import { message, Modal, Select } from 'antd'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useRef, useState } from 'react'
import BaseService from '../../../../api/base-service'
import { useParams } from 'react-router-dom'

const DepartmentService = new BaseService('/core/companies/add-department-to')

const DepartmentDialog = ({ setRefresh, existingCodes, title, isModalOpen, handleOk, handleCancel, apiEndpoint }) => {
  const { id } = useParams()
  const [selectedValues, setSelectedValues] = useState([])
  const selectedIdRef = useRef([])
  const [loading, setLoading] = useState(false)

  const { data } = useQuery({
    queryKey: [apiEndpoint.endPoint],
    queryFn: async () => {
      const response = await apiEndpoint.get()
      return response.data
    },
    enabled: isModalOpen
  })

  const handleChange = (value) => {
    setSelectedValues(value)
    const selectedOptions = value.map((option) => option.value)
    selectedIdRef.current = selectedOptions
  }

  const { mutate } = useMutation({
    mutationFn: async () => {
      if (selectedIdRef.current.length === 0) {
        return
      }
      const data = await DepartmentService.post(id, selectedIdRef.current.toString())
      return data
    },
    onSuccess: () => {
      handleOk()
      message.success('Thêm phòng ban mới thành công')
      setRefresh((prev) => !prev)
      setLoading(true)
    },
    onError: () => {
      message.error('Lưu thông tin thất bại!')
    }
  })

  return (
    <Modal loading={loading} title={title} visible={isModalOpen} onOk={() => mutate()} onCancel={handleCancel}>
      <Select
        mode="tags"
        style={{ width: '100%' }}
        onChange={handleChange}
        tokenSeparators={[',']}
        labelInValue
        value={selectedValues}
      >
        {data &&
          data.map((option) => (
            <Select.Option key={option.id} value={option.id} disabled={existingCodes.includes(option.code)}>
              {option.name}
            </Select.Option>
          ))}
      </Select>
    </Modal>
  )
}

export default DepartmentDialog
