import { useEffect, useState } from 'react'
import BaseService from '../api/base-service'
import { useNavigate } from 'react-router-dom'

const verifyUserService = new BaseService('/account/auth/verifyUser')
const refreshTokenService = new BaseService('/account/auth/token/refresh')
const useVerifyUser = () => {
  const [verifiedUser, setVerifiedUser] = useState(false)
  const refreshToken = localStorage.getItem('refreshToken')
  const navigate = useNavigate()
  const user = localStorage.getItem('user')
  useEffect(() => {
    const userId = user ? JSON.parse(user)?.id : undefined
    if (!userId || !refreshToken) navigate('/dang-nhap')
    verifyUserService
      .get()
      .then((res) => {
        if (res.code === 2000) {
          setVerifiedUser(true)
        }
      })
      .catch((error) => {
        refreshTokenService
          .create({
            refreshToken: JSON.parse(refreshToken),
            userId: userId
          })
          .then((res1) => {
            localStorage.setItem('accessToken', JSON.stringify(res1.accessToken))
            setVerifiedUser(true)
          })
          .catch(() => {
            navigate('/dang-nhap')
          })
      })
  }, [])
  return { verifiedUser }
}

export default useVerifyUser
