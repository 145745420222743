import { Flex, message, Typography } from 'antd'
import { useRef, useState } from 'react'
import ListPage from '../../../components/list-page'
import DepartmentListModal, { DepartmentService } from './DepartmentListComponent'
import { ActivisionColumn } from '../../../components/list-page/ActivisionColumn'
import Status from '../../../constant/constantStatus'
import { useMutation } from '@tanstack/react-query'

const DepartmentList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [id, setId] = useState(null)
  const childRef = useRef(null)

  const lockMutation = useMutation({
    mutationFn: async (data) => {
      return data.status === 'ACTIVE'
        ? await DepartmentService.patch(data.id, 'lock-department')
        : await DepartmentService.patch(data.id, 'unlock-department')
    },
    onSuccess: (result) => {
      if (result.code === 2000) {
        handleRefresh()
      } else {
        message.error('Lưu thất bại')
      }
    }
  })

  const handleDepartmentLock = (department) => {
    lockMutation.mutate(department)
  }

  const handleRefresh = () => {
    childRef.current.fetch()
    setIsModalOpen(false)
  }

  const handleDepartmentAdd = () => {
    setId(null)
    setIsModalOpen(true)
  }

  const handleDepartmentEdit = (department) => {
    setId(department)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const departmentColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      hidden: true
    },
    {
      title: 'Mã phòng ban',
      dataIndex: 'code',
      key: 'code',
      width: 150
    },
    {
      title: 'Tên phòng ban',
      dataIndex: 'name',
      key: 'name',
      width: 200
    },
    {
      title: 'Ca làm việc',
      dataIndex: 'workingShiftBriefDto',
      key: 'workingShiftBriefDto',
      width: 120,
      render: (_, record) => <Typography.Text>{record.workingShiftBriefDto?.name}</Typography.Text>
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true
    },
    {
      title: 'Lần sửa cuối',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      width: 165,
      showSetting: false
    },
    {
      title: 'Người sửa cuối',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      width: 135,
      showSetting: false
    },
    {
      title: 'Trạng thái',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (_, record) => (
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <ActivisionColumn status={record.status} />
        </Flex>
      )
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (_, record) => (
        <Flex justify="center" gap={4}>
          <a onClick={() => handleDepartmentEdit(record.id)}>Edit</a>/
          <a onClick={() => handleDepartmentLock(record)}>
            {record.status === Status.ACTIVE.description ? 'Khóa' : 'Mở khóa'}
          </a>
        </Flex>
      )
    }
  ]

  return (
    <div>
      <ListPage
        request={async (params) => DepartmentService.get(params)}
        name={'department'}
        columns={departmentColumns}
        onCreate={handleDepartmentAdd}
        searchPrimaryKey="codeOrName"
        ref={childRef}
      />
      {isModalOpen && (
        <DepartmentListModal
          id={id}
          handleRefresh={handleRefresh}
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  )
}

export default DepartmentList
