import { Button, Col, DatePicker, Flex, Form, Input, Row, Select, Spin, Tabs, Typography, Upload } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { enumReason, enumTime } from '../../../constant/enumSelectOptions'
import TextArea from 'antd/es/input/TextArea'
import { UploadOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
const { Text } = Typography

const TrainingDetail = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const handleClose = () => {
    navigate('/dich-vu-khach-hang/dao-tao')
  }

  return (
    <>
      <div>
        <Spin spinning={false}>
          <Form layout="vertical">
            <Row gutter={[16, 0]}>
              <Col span={2}>
                <Text>Thông tin đào tạo</Text>
              </Col>
              <Col span={22}>
                <Row gutter={[16, 0]}>
                  {id && (
                    <Col span={8}>
                      <Form.Item label="ID" name="id">
                        <Input placeholder="Nhập ID" disabled={id} value={id} />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={8}>
                    <Form.Item
                      label="Ngày"
                      name="date"
                      required={true}
                      rules={[{ required: true, message: 'Vui lòng không bỏ trống' }]}
                    >
                      <DatePicker
                        format={'DD MM YYYY'}
                        style={{
                          width: '100%'
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Thời gian" name="time">
                      <Select allowClear placeholder="Chọn thời gian" options={enumTime} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Khách hàng" name="customer">
                      <Input placeholder="Nhập khách hàng" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Email" name="email">
                      <Input placeholder="Nhập email" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="SĐT" name="phoneNumber">
                      <Input placeholder="Nhập SĐT" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Công trình"
                      name="construction"
                      required={true}
                      rules={[
                        { required: true, message: 'Vui lòng không bỏ trống' },
                        { whitespace: true, message: 'Vui lòng không bỏ trống' }
                      ]}
                    >
                      <Input placeholder="Nhập công trình" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Công ty" name="company">
                      <Input placeholder="Nhập công ty" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Giám sát khu vực" name="GSKV">
                      <Select allowClear placeholder="Chọn GSKV" options={[]} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Lý do" name="reason">
                      <Select allowClear placeholder="Chọn lý do" options={enumReason} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Ngày đào tạo gần nhất" name="lastTrainingDate">
                      <DatePicker
                        format={'DD MM YYYY'}
                        style={{
                          width: '100%'
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Ghi chú" name="note">
                      <TextArea placeholder="Nhập ghi chú" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
            <Row
              gutter={[16, 0]}
              style={{
                marginBottom: 24
              }}
            >
              <Col span={2}>
                <Text>Thông tin kết quả</Text>
              </Col>
              <Col span={22}>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Form.Item label="Kết quả đào tạo" name="trainingResults">
                      <TextArea placeholder="Nhập kết quả đào tạo" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="executiveFeedback">
                      <Flex
                        justify="space-between"
                        style={{
                          marginBottom: 8
                        }}
                      >
                        <Text>Phản hồi của bộ phận điều hành</Text>
                        <Text>NV019242 - 11/06/2024 12:45</Text>
                      </Flex>
                      <TextArea placeholder="Nhập phản hồi của bộ phận điều hành" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="CSFeedback">
                      <Flex
                        justify="space-between"
                        style={{
                          marginBottom: 8
                        }}
                      >
                        <Text>Phản hồi của CS</Text>
                        <Text>NV019242 - 11/06/2024 12:45</Text>
                      </Flex>
                      <TextArea placeholder="Nhập phản hồi của CS " />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Trước đào tạo" name="beforeTraining">
                      <Upload>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Sau đào tạo" name="afterTraining">
                      <Upload>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Flex justify="flex-end" gap={15}>
              <Button onClick={handleClose}>Hủy lịch</Button>
              <Button onClick={handleClose}>Hoàn thành</Button>
              <Button onClick={handleClose}>Hủy</Button>
              <Button type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Flex>
          </Form>
        </Spin>
      </div>
    </>
  )
}

export default TrainingDetail
