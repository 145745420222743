import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

const { Input, DatePicker, Select, AutoComplete } = require('antd')

// Extend dayjs with the UTC plugin
dayjs.extend(utc)

const FormItemFilter = ({ inputType, align, hidden, endOfDay, filterStyle, ...props }) => {
  const newProps = {
    size: 'large',
    style: { minWidth: 266, width: '100%', ...filterStyle },
    allowClear: true,
    placeholder: props.title,
    ...props
  }

  switch (inputType) {
    case 'text':
      return <Input {...newProps} />
    case 'select':
      return <Select {...newProps} />
    case 'autoComplete':
      return <AutoComplete {...newProps} />
    case 'dateRange':
      return <DatePicker.RangePicker {...newProps} />
    case 'dateHour':
      return (
        <DatePicker
          showTime
          format={{
            format: 'DD-MM-YYYY ',
            type: 'mask'
          }}
          {...newProps}
          value={props.value ? dayjs.utc(props.value).startOf('day') : undefined}
          onChange={(date) => {
            let newDate = dayjs.utc(date).add(7, 'hour')
            if (endOfDay) newDate = newDate.endOf('day')
            if (props.onChange) props.onChange(date ? newDate.toISOString() : undefined)
          }}
        />
      )
    case 'date':
      return (
        <DatePicker
          format={{
            format: 'DD-MM-YYYY ',
            type: 'mask'
          }}
          {...newProps}
          value={props.value ? dayjs.utc(props.value).startOf('day') : undefined}
          onChange={(date) => {
            let newDate = dayjs.utc(date).add(7, 'hour')
            if (endOfDay) newDate = newDate.endOf('day')
            if (props.onChange) props.onChange(date ? newDate.toISOString() : undefined)
          }}
        />
      )
    case 'multiSelect':
      return (
        <Select
          {...newProps}
          mode="multiple"
          value={props.value ? props.value.split(',') : []}
          onChange={(values) => (props.onChange ? props.onChange(values.join(',')) : undefined)}
        />
      )
    default:
      return <Input {...newProps} />
  }
}
export default FormItemFilter
