import { Button, Checkbox, Flex, Form, Input, InputNumber, message, Modal, Select, Space, Typography } from 'antd'
import BaseService from '../../../../api/base-service'
import { URL_DOCUMENT_SETTING } from '../../../../constant/constantURL'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

export const DocumentSettingService = new BaseService(URL_DOCUMENT_SETTING)

export const generateDisplay = (values) => {
  const date = new Date()
  const month = date.getMonth() + 1 > 10 || '0' + (date.getMonth() + 1)
  const year = date.getFullYear().toString().slice(2)
  let digit = ''
  for (let index = 0; index < values.numberOfDigit - 1; index++) {
    digit += '0'
  }
  const display =
    values.prefix +
    (values.hasYear ? year : '') +
    (values.hasMonth ? month : '') +
    values.separator +
    digit +
    (values.id || '1')
  return display
}

const DocumentSettingModal = ({ id, isOpenModal, handleRefresh, handleCloseModal }) => {
  const [form] = Form.useForm()

  const result = useQuery({
    queryKey: ['document-setting', id],
    queryFn: () => DocumentSettingService.getById(id)
  })

  const handleUpdateDisplay = (changeField, allFields) => {
    const values = {
      id: id,
      prefix: allFields.find((value) => value.name[0] === 'prefix').value,
      numberOfDigit: allFields.find((value) => value.name[0] === 'numberOfDigit').value,
      hasMonth: allFields.find((value) => value.name[0] === 'hasMonth').value,
      hasYear: allFields.find((value) => value.name[0] === 'hasYear').value,
      separator: allFields.find((value) => value.name[0] === 'separator').value
    }
    if (changeField[0].name[0] === 'hasMonth' && values.hasMonth === true) {
      values.hasYear = true
      form.setFieldValue('hasYear', true)
    } else if (changeField[0].name[0] === 'hasYear' && values.hasYear === false) {
      values.hasMonth = false
      form.setFieldValue('hasMonth', false)
    }

    form.setFieldValue('display', generateDisplay(values))
  }

  const mutation = useMutation({
    mutationFn: (values) => DocumentSettingService.update(id, values),
    onSuccess: (result) => {
      if (result.code === 2000) {
        handleRefresh()
        handleCloseModal()
      } else {
        message.error(`Lưu thất bại (Lỗi: ${result.message}`)
      }
    },
    onError: (error) => {
      message.error(`GỬi yêu thất bại (Lỗi: ${error.message}`)
    }
  })

  const handleFinish = (values) => {
    mutation.mutate({
      prefix: values.prefix,
      numberOfDigit: values.numberOfDigit,
      hasMonth: values.hasMonth,
      hasYear: values.hasYear,
      separator: values.separator,
      example: values.display
    })
  }

  useEffect(() => {
    if (result.data) {
      form.setFieldsValue(result.data.data)
      form.setFieldValue('display', generateDisplay(result.data.data))
      form.validateFields()
    }
  }, [result.data, form])

  if (result.isLoading) {
    return <></>
  }

  if (result.isError) {
    return <></>
  }

  return (
    <Modal title="Cấu hình" open={isOpenModal} footer={null} onCancel={handleCloseModal} centered>
      <Form
        form={form}
        requiredMark={false}
        onFieldsChange={handleUpdateDisplay}
        layout="vertical"
        onFinish={handleFinish}
      >
        <Form.Item hidden={true} name="id">
          <Input name="id"></Input>
        </Form.Item>
        <Form.Item
          name="prefix"
          label="Tiền tố"
          required={true}
          rules={[
            { max: 10, message: 'Tiền tố chỉ được phép có không quá 10 ký tự' },
            {
              required: true,
              message: 'Vui lòng nhập tiền tố'
            }
          ]}
        >
          <Input name="prefix" placeholder="Tiền tố"></Input>
        </Form.Item>
        <Form.Item
          name="numberOfDigit"
          label="Ký tự phần số"
          required={true}
          rules={[
            {
              validator: (_, value) => {
                return Number(value) >= 3
                  ? Promise.resolve()
                  : Promise.reject(new Error('Số ký tự phần số phải từ 3 trở lên'))
              }
            }
          ]}
        >
          <InputNumber type="number" min={3} style={{ width: '100%' }} placeholder="Ký tự phần số" />
        </Form.Item>
        <Space
          direction="vertical"
          style={{
            width: '97.5%'
          }}
        >
          <Typography.Text>Thời gian hiển thị</Typography.Text>
          <Space
            style={{
              width: '100%',
              border: '1px black solid ',
              borderRadius: '7px',
              borderColor: '#9095A1',
              marginBottom: 22,
              paddingLeft: 10
            }}
          >
            <Form.Item style={{ marginBottom: 0 }} name="hasMonth" valuePropName="checked">
              <Checkbox
                onChange={(event) => (event.target.value === true ? form.setFieldValue('hasYear', true) : null)}
              >
                Tháng
              </Checkbox>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} name="hasYear" valuePropName="checked">
              <Checkbox
                onChange={(event) => (event.target.value === false ? null : form.setFieldValue('hasMonth', false))}
              >
                Năm
              </Checkbox>
            </Form.Item>
          </Space>
        </Space>
        <Form.Item name="separator" label="Phân cách">
          <Select
            defaultValue=""
            options={[
              {
                label: 'Không',
                value: ''
              },
              {
                label: '.',
                value: '.'
              },
              {
                label: '/',
                value: '/'
              },
              {
                label: '-',
                value: '-'
              },
              {
                label: '_',
                value: '_'
              }
            ]}
          />
        </Form.Item>
        <Form.Item name="display" label="Hiển thị">
          <Input disabled={true} readOnly={true} placeholder="Hiển thị"></Input>
        </Form.Item>
        <Form.Item>
          <Flex gap={15} justify="end">
            <Button onClick={handleCloseModal}>Hủy</Button>
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DocumentSettingModal
