function generateTimeOptions() {
  const options = []
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      if (hour === 12 && minute > 0) break
      let hourStr = hour.toString().padStart(2, '0')
      let minuteStr = minute.toString().padStart(2, '0')
      let timeStr = `${hourStr}:${minuteStr}`
      options.push({ value: timeStr, label: timeStr })
    }
  }
  return options
}

export { generateTimeOptions }
