import { Button, Card, Checkbox, Col, DatePicker, Flex, Form, Input, Row, Select, Spin, Tabs, Typography } from 'antd'
import SVGIcons from '../../../assets/icons/svg-icons'
import TextArea from 'antd/es/input/TextArea'
import ActivisionColumn from '../../../components/list-page/ActivisionColumn'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ModalAddNew from './ModalAddNew'

const { Text } = Typography

const ComplainDetail = () => {
  const [form] = Form.useForm()
  const { id } = useParams()
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleClose = () => {
    navigate('/dich-vu-khach-hang/khieu-nai')
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onSubmit = (values) => {
    const formData = { ...values }
    formData.reCheck = isChecked ? formData.reCheck : null
    console.log(formData)
  }

  return (
    <>
      <div>
        <Spin spinning={false}>
          <Form layout="vertical" form={form} onFinish={onSubmit}>
            <Row gutter={[16, 0]}>
              <Col span={3}>
                <Text>Thông tin chung</Text>
              </Col>
              <Col span={21}>
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item label="ID" name="id">
                      <Input placeholder="Nhập ID" disabled={id} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Thời gian" name="date">
                      <DatePicker
                        style={{
                          width: '100%'
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Nguồn" name="source">
                      <Select placeholder="Chọn công ty" options={[]} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Khách hàng" name="customer">
                      <Input placeholder="Nhập tên khách hàng" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Email" name="email">
                      <Input placeholder="Nhập email" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="SĐT" name="phoneNumber">
                      <Input placeholder="Nhập SĐT" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Công trình" name="construction">
                      <Select
                        allowClear
                        options={[{ id: 0, value: 'CONGTRINH1', label: 'Công trình 1' }]}
                        placeholder="Nhập công trình"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Công ty" name="company">
                      <Select allowClear placeholder="Chọn công ty" options={[]} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Flex align="center">
                      <Checkbox
                        checked={isChecked}
                        onClick={() => setIsChecked(!isChecked)}
                        style={{ whiteSpace: 'nowrap', marginTop: 5 }}
                      >
                        Kiểm tra lại
                      </Checkbox>
                      <Form.Item name="reCheck" label=" " style={{ flex: 1 }}>
                        <DatePicker
                          showTime
                          format={'DD MM YYYY hh:mm'}
                          style={{ width: '100%' }}
                          disabled={!isChecked}
                        />
                      </Form.Item>
                    </Flex>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
            <Row
              gutter={[16, 0]}
              style={{
                marginBottom: 24
              }}
            >
              <Col span={3}>
                <Text>Thông tin chi tiết</Text>
              </Col>
              <Col span={21}>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Form.Item name="title" label="Tiêu đề">
                      <Input placeholder="Nhập tiêu đề" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Nội dung" name="content">
                      <TextArea rows={1} placeholder="Nhập nội dung" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Row gutter={[24, 0]}>
                      <Col span={24}>
                        <Flex
                          justify="space-between"
                          style={{
                            paddingBottom: 8
                          }}
                        >
                          <Text>Giải quyết </Text>
                          <Text>NV1123 - 11/06/2024 12:45</Text>
                        </Flex>
                        <Form.Item name="solution">
                          <TextArea rows={4} placeholder="Nhập hướng giải quyết" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Row gutter={[24, 0]}>
                      <Col span={24}>
                        <Flex
                          justify="space-between"
                          style={{
                            paddingBottom: 8
                          }}
                        >
                          <Text>Phản hồi</Text>
                          <Text>NV1123 - 11/06/2024 12:45</Text>
                        </Flex>
                        <Form.Item name="response">
                          <TextArea rows={4} placeholder="Nhập hướng phản hồi" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
            <Row
              gutter={[16, 0]}
              style={{
                marginBottom: 24
              }}
            >
              <Col span={3}>
                <Text>Liên kết</Text>
              </Col>
              {id && (
                <Col span={21}>
                  <Flex vertical>
                    <Col span={5}>
                      <Form.Item>
                        <Flex align="center" justify="space-between" gap={20}>
                          <div>Lịch QC:</div>
                          <div
                            style={{
                              backgroundColor: '#FFF1F3',
                              padding: '6px 10px',
                              borderRadius: 4,
                              color: '#3D62BF',
                              fontWeight: 500,
                              textDecoration: 'underline',
                              width: '150px',
                              textAlign: 'center'
                            }}
                          >
                            CS.CP24.0014
                          </div>
                        </Flex>
                      </Form.Item>
                      <Form.Item name="dateQC" initialValue="CS.CP24.0014" hidden>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item>
                        <Flex align="center" justify="space-between" gap={20}>
                          <div>Kết quả KTCT:</div>
                          <div
                            style={{
                              backgroundColor: '#ECFDF3',
                              padding: '6px 10px',
                              borderRadius: 4,
                              color: '#3D62BF',
                              fontWeight: 500,
                              textDecoration: 'underline',
                              width: '150px',
                              textAlign: 'center'
                            }}
                          >
                            CS.CP24.0014
                          </div>
                        </Flex>
                      </Form.Item>
                      <Form.Item name="resultKTCT" initialValue="CS.CP24.0014" hidden>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Flex>
                </Col>
              )}
            </Row>
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
            <Row
              gutter={[16, 0]}
              style={{
                marginBottom: 24
              }}
            >
              <Col span={3}>
                <div>Tiếp nhận & xử lý</div>
              </Col>
              <Col span={21}>
                <Flex gap={16}>
                  <Card
                    style={{
                      width: 260
                    }}
                  >
                    <Flex
                      justify="space-between"
                      style={{
                        marginBottom: 4
                      }}
                    >
                      <Text color="#344054">Lê Thị Như Ý</Text>
                      <ActivisionColumn status="RECEIVE" />
                    </Flex>
                    <Flex justify="space-between" align="center" gap={16}>
                      <div>F2000001</div>
                      <SVGIcons.Dot />
                      <div
                        style={{
                          whiteSpace: 'nowrap'
                        }}
                      >
                        14/06/2024 11:33
                      </div>
                    </Flex>
                  </Card>
                  <Card
                    style={{
                      width: 260
                    }}
                  >
                    <Flex
                      justify="space-between"
                      style={{
                        marginBottom: 4
                      }}
                    >
                      <Text color="#344054">---</Text>
                      <ActivisionColumn status="CLOSE" />
                    </Flex>
                    <Flex justify="space-between" align="center" gap={16}>
                      <div>---</div>
                      <SVGIcons.Dot />
                      <div>---</div>
                    </Flex>
                  </Card>
                  <Card
                    style={{
                      width: 260
                    }}
                  >
                    <Flex
                      justify="space-between"
                      style={{
                        marginBottom: 4
                      }}
                    >
                      <Text color="#344054">---</Text>
                      <ActivisionColumn status="HANDLE" />
                    </Flex>
                    <Flex justify="space-between" align="center" gap={16}>
                      <div>---</div>
                      <SVGIcons.Dot />
                      <div>---</div>
                    </Flex>
                  </Card>
                </Flex>
              </Col>
            </Row>
            <Tabs defaultActiveKey="basic-info" unmountInactiveTabs={true} />
            <Flex justify="flex-end" gap={15}>
              {id ? (
                <>
                  <Button onClick={handleClose}>Đóng case</Button>
                  <Button onClick={handleClose}>Hủy phiếu</Button>
                  <Button onClick={showModal}>Thêm KTCL</Button>
                  <Button onClick={handleClose}>Đóng</Button>
                </>
              ) : (
                <Button onClick={handleClose}>Hủy</Button>
              )}
              <Button type="primary" htmlType="submit">
                {id ? 'Cập nhật' : 'Lưu'}
              </Button>
            </Flex>
          </Form>
        </Spin>
      </div>
      {isModalOpen && <ModalAddNew isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />}
    </>
  )
}

export default ComplainDetail
