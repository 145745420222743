import { Typography } from 'antd'
import Time from '../../constant/constantTime'

const TimeColumn = ({ time }) => {
  switch (time) {
    case Time.morning.description:
      return <Typography.Text>Buổi sáng</Typography.Text>
    case Time.afternoon.description:
      return <Typography.Text>Buổi sáng</Typography.Text>
    case Time.businessHours.description:
      return <Typography.Text>Buổi sáng</Typography.Text>
    default:
      return <Typography.Text>{time}</Typography.Text>
  }
}

export default TimeColumn
