import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Form, Modal, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'

const MailComplainModal = ({ id, isModalOpen, handleClose, handleRefetch }) => {
  const [form] = Form.useForm()
  const mutation = useMutation({
    mutationFn: (data) => {},
    onSuccess: (result) => {
      if (result.code == 2000) {
        message.success('Chuyển khiếu nại thành công')
      } else {
        message.error(`Chuyển khiếu nại thất bại (Lỗi ${result.message})`)
      }
    },
    onError: (error) => {
      message.error(`Gửi yêu cầu chuyển khiếu nại thất bại (Lỗi ${error.message})`)
    }
  })

  const handleTransfer = (values) => {
    mutation.mutate()
    handleRefetch()
  }

  return (
    <Modal centered open={isModalOpen} footer={null} title="Chuyển email sang Khiếu nại" onCancel={handleClose}>
      <Form form={form} layout="vertical" onFinish={handleTransfer} style={{ paddingTop: 10, paddingBottom: 15 }}>
        <Form.Item
          required
          name="content"
          label="Hướng giải quyết"
          rules={[
            { required: true, message: 'Vui lòng nhập hướng giải quyết.' },
            { whitespace: true, message: 'Vui lòng không chỉ nhập khoảng trắng.' }
          ]}
        >
          <TextArea
            maxLength={2000}
            rows={3}
            placeholder={
              'Nhập, tối đa 2000 ký tự\nNếu nhập, đưa vào thông tin Hướng giải quyết của complain, đồng thời ghi nhận thời gian giải quyết.'
            }
          />
        </Form.Item>
        <Flex gap={10} justify="right">
          <Button type="text" onClick={handleClose}>
            Đóng
          </Button>
          <Button type="primary" htmlType="submit">
            Chuyển
          </Button>
        </Flex>
      </Form>
    </Modal>
  )
}

export default MailComplainModal
