import { Button, Flex, Form, Input, message, Modal, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useEffect, useState } from 'react'
import BaseService from '../../../api/base-service'
import { useMutation, useQueries, useQuery } from '@tanstack/react-query'

export const DepartmentService = new BaseService('/core/departments')
export const WorkingShiftService = new BaseService('/core/working-shifts')

const DepartmentListModal = ({ isModalOpen, handleRefresh, id, handleCloseModal }) => {
  const [form] = Form.useForm()
  const [workingShift, setWorkingShift] = useState([])

  const departmentResult = useQuery({
    queryKey: ['department', id],
    queryFn: () => DepartmentService.getById(id)
  })

  const shiftResult = useQuery({
    queryKey: ['working-shift', id],
    queryFn: () => WorkingShiftService.get()
  })

  const mutation = useMutation({
    mutationFn: (data) =>
      data.id ? DepartmentService.update(data.id, data.data) : DepartmentService.create(data.data),
    onSuccess: (result) => {
      if (result.code === 2000) {
        handleRefresh()
      } else {
        message.error('Lưu thông tin thất bại!')
      }
    },
    onError: () => {
      message.error('Lưu thông tin thất bại!')
    }
  })

  const onFinish = (values) => {
    mutation.mutate({
      id: values.id,
      data: {
        code: values.code,
        name: values.name,
        workingShiftId: values.shift,
        note: values.note
      }
    })
  }
  const onFinishFailed = () => {}

  useEffect(() => {
    if (id === null) {
      form.resetFields()
    }
  }, [id, form])

  useEffect(() => {
    if (departmentResult.data) {
      form.setFieldsValue(departmentResult?.data?.data)
      form.setFieldValue('shift', departmentResult?.data?.data.workingShiftBriefDto?.id)
    }
  }, [departmentResult.data, form])

  useEffect(() => {
    if (shiftResult.data) {
      setWorkingShift(shiftResult?.data?.data)
    }
  }, [shiftResult.data])

  return (
    <Modal title="Phòng ban" open={isModalOpen} footer={null} onCancel={handleCloseModal}>
      <Form form={form} name="Department" onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical">
        <Form.Item hidden={true} name="id" label="Mã phòng ban">
          <Input name="id" />
        </Form.Item>
        <Form.Item
          name="code"
          label="Mã phòng ban"
          required={false}
          rules={[
            { required: true, message: 'Vui lòng nhập mã phòng ban.' },
            { whitespace: true, message: 'Không được có khoảng trống.' },
            { pattern: new RegExp('^[a-zA-Z0-9]'), message: 'Không được có ký tự đặc biệt.' }
          ]}
        >
          <Input placeholder="Mã phòng ban" name="code" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên phòng ban"
          required={false}
          rules={[
            { required: true, message: 'Vui lòng nhập tên phòng ban' },
            { whitespace: true, message: 'Không được có khoảng trống.' }
          ]}
        >
          <Input placeholder="Tên phòng ban" name="name" />
        </Form.Item>
        <Form.Item name="shift" label="Ca làm việc">
          <Select
            placeholder="Ca làm việc"
            name="shift"
            options={workingShift.map((shift) => {
              return { value: shift.id, label: shift.name }
            })}
          />
        </Form.Item>
        <Form.Item name="note" label="Ghi chú">
          <TextArea placeholder="Ghi chú" name="note" />
        </Form.Item>
        <Form.Item>
          <Flex gap={10} justify="end">
            <Button htmlType="button" onClick={handleCloseModal}>
              Hủy
            </Button>
            <Button type="primary" htmlType="submit">
              Ok
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DepartmentListModal
