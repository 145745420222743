import { Button, Checkbox, Flex, Form, Input, Switch, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/imgs/svg/logo.svg'
import BaseService from '../api/base-service'
import backgroundThemeFirst from '../assets/imgs/background/background-first.png'
import backgroundThemeSecond from '../assets/imgs/background/background-second.png'
import backgroundThemeThird from '../assets/imgs/background/background-third.png'
import { useMutation } from '@tanstack/react-query'

const images = [backgroundThemeFirst, backgroundThemeSecond, backgroundThemeThird]
const LoginService = new BaseService('/account/auth/login')

const Login = () => {
  const user = localStorage.getItem('user')
  const navigate = useNavigate()
  if (user) navigate('/')
  const [form] = Form.useForm()
  const [currentBg, setCurrentBg] = useState(0)
  const [isSwitch, setIsSwitch] = useState(true)
  const [loading, setLoading] = useState(false)

  const mutation = useMutation({
    mutationFn: (data) => {
      return LoginService.create({ ...data })
    },
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: (res) => {
      notification.success({ message: 'Đăng nhập thành công' })
      localStorage.setItem('accessToken', JSON.stringify(res.accessToken))
      localStorage.setItem('refreshToken', JSON.stringify(res.refreshToken))
      localStorage.setItem('user', JSON.stringify(res.user))
      navigate('/')
    },
    onError: (res) => {
      notification.error({ message: res.response?.data.message })
    },
    onSettled: () => {
      setLoading(false)
    }
  })
  const handleSwitchChange = () => setIsSwitch(!isSwitch)

  useEffect(() => {
    const changeBackground = setInterval(() => {
      setCurrentBg((prev) => (prev + 1) % images.length)
    }, 10000)
    return () => clearInterval(changeBackground)
  }, [])

  const onSubmit = (values) => {
    const formData = { ...values }
    mutation.mutate(formData)
  }

  return (
    <Flex justify="center" align="center" className="login">
      <div className="login__background-container">
        {images.map((image, index) => (
          <div
            key={index}
            className={`login__background ${currentBg === index ? 'login__background--visible' : ''}`}
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}
      </div>
      <div className="switch-language">
        <Switch
          checkedChildren="VIE"
          unCheckedChildren="ENG"
          defaultChecked
          size="default"
          onChange={handleSwitchChange}
          className={`switch-language__switch ${isSwitch ? 'switch-language__switch--vn' : 'switch-language__switch--gb'}`}
        />
      </div>
      <Flex justify="center" align="center" className="login__container">
        <div className="login__logo">
          <img src={logo} alt="Logo" />
        </div>
        <Form form={form} layout="vertical" className="login__form" onFinish={onSubmit}>
          <div className="login__form-container">
            <Flex vertical gap={16}>
              <Form.Item
                label="Tên Đăng Nhập"
                className="login__label"
                name="username"
                rules={[{ required: 'Vui lòng nhập tên đăng nhập!' }]}
              >
                <Input placeholder="Tên đăng nhập" />
              </Form.Item>
              <Form.Item
                label="Mật Khẩu"
                className="login__label"
                name="password"
                rules={[{ required: 'Vui lòng nhập mật khẩu!' }]}
              >
                <Input.Password placeholder="Mật khẩu" />
              </Form.Item>
            </Flex>
            <Checkbox className="login__checkbox">Ghi nhớ</Checkbox>
          </div>
          <Button disabled={loading} type="primary" className="login__btn" htmlType="submit">
            Đăng nhập
          </Button>
        </Form>
      </Flex>
    </Flex>
  )
}

export default Login
