import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Flex, Form, Input, Modal, Typography, message } from 'antd'
import { useEffect, useState } from 'react'
import { URL_ACCOUNT } from '../../../constant/constantURL'
import BaseService from '../../../api/base-service'
import { useMutation, useQuery } from '@tanstack/react-query'
import Status from '../../../constant/constantStatus'

const UserAccountService = new BaseService(URL_ACCOUNT)

const UserChangePasswordModal = ({ id, isModalOpen, isSuperAdmin, handleCloseModal, handleRefetch }) => {
  const [form] = Form.useForm()
  const [username, setUsername] = useState('')
  const [isActive, setIsActive] = useState(false)

  const accountResult = useQuery({
    queryKey: [URL_ACCOUNT, id],
    queryFn: () => UserAccountService.getById(id)
  })

  const mutation = useMutation({
    mutationFn: (data) =>
      isSuperAdmin
        ? UserAccountService.update(id, data, 'update-password/admin')
        : isActive
          ? UserAccountService.update(id, data, 'update-password/active')
          : UserAccountService.update(id, data, 'update-password/staff'),
    onSuccess: (result) => {
      if (result.code === 2000) {
        message.success('Cập nhật trạng thái thành công!')
        handleRefetch()
        handleCloseModal()
      } else {
        message.error(`Cập nhật trạng thái thất bại (Lỗi: ${result.message})`)
      }
    },
    onError: (error) => {
      message.error(`Gửi yêu cầu cập nhật trạng thái thất bại (Lỗi: ${error.message})`)
    }
  })

  const handleFinish = (values) => {
    const payload = { ...values }
    mutation.mutate(payload)
  }

  useEffect(() => {
    if (accountResult.data) {
      setUsername(accountResult.data?.data?.username)
      setIsActive(accountResult.data?.data?.status === Status.ACTIVE.description)
    }
  }, [accountResult.data])

  return (
    <Modal centered title={'Đổi mật khẩu'} footer={null} open={isModalOpen} onCancel={handleCloseModal}>
      <Typography.Text>Tài khoản: {username}</Typography.Text>
      <Form form={form} name="change-password" layout="vertical" onFinish={handleFinish} style={{ paddingTop: 20 }}>
        {!isSuperAdmin && (
          <Form.Item
            required
            name="oldPassword"
            label="Mật khẩu hiện tại"
            rules={[
              { required: true, message: 'Vui lòng nhập mật khẩu.' },
              { whitespace: true, message: 'Vui lòng không chỉ nhập khoảng trắng' }
            ]}
          >
            <Input.Password
              visibilityToggle={true}
              iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
        )}
        <Form.Item
          required
          name="newPassword"
          label="Mật khẩu mới"
          rules={[
            { required: true, message: 'Vui lòng nhập mật khẩu.' },
            { whitespace: true, message: 'Vui lòng không chỉ nhập khoảng trắng' },
            { min: 4, message: 'Mật khẩu không được ít hơn 4 ký tự.' },
            { max: 20, message: 'Mật khẩu không được nhiều hơn 20 ký tự.' }
          ]}
        >
          <Input.Password iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)} />
        </Form.Item>
        <Form.Item
          required
          name="reNewPassword"
          label="Xác nhận mật khẩu mới"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Vui lòng nhập mật khẩu.' },
            { whitespace: true, message: 'Vui lòng không chỉ nhập khoảng trắng' },
            { min: 4, message: 'Mật khẩu không được ít hơn 4 ký tự.' },
            { max: 20, message: 'Mật khẩu không được nhiều hơn 20 ký tự.' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Mật khẩu này không trùng với mật khẩu mới'))
              }
            })
          ]}
        >
          <Input.Password
            visibilityToggle={true}
            iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item>
          <Flex gap={10} justify="end">
            <Button htmlType="button" onClick={handleCloseModal}>
              Hủy
            </Button>
            <Button type="primary" htmlType="submit">
              Ok
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UserChangePasswordModal
