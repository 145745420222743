import { useState } from 'react'

const useStateLocalStorage = (defaultState, keyStorage) => {
  const keyStorageByLocation = `${window.location.pathname?.replace('/', '')}-${keyStorage}`
  const defaultStateStorage = JSON.parse(localStorage.getItem(keyStorageByLocation) || 'null')
  const [state, setState] = useState(defaultStateStorage || defaultState)
  const onSetState = (value) => {
    localStorage.setItem(keyStorageByLocation, JSON.stringify(value || null))
    setState(value)
  }
  return [state, onSetState]
}

export default useStateLocalStorage
