import ListPage from '../../../components/list-page'

const ProjectComplaints = () => {
  const columns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: 20
    },
    {
      title: 'Công trình',
      dataIndex: 'construcion',
      key: 'construcion',
      width: 250
    },
    {
      title: 'Số lần khiếu nại',
      dataIndex: 'complaintRate',
      key: 'complaintRate',
      ellipsis: true,
      width: 150
    },
    {
      title: 'Công ty',
      dataIndex: 'company',
      key: 'company',
      showSetting: false,
      mainSearch: true,
      inputType: 'select',
      options: []
    },
    {
      title: 'Từ tháng',
      dataIndex: 'fromMonth',
      key: 'fromMonth',
      showSetting: false,
      search: true,
      inputType: 'date'
    },
    {
      title: 'Đến tháng',
      dataIndex: 'toMonth',
      key: 'toMonth',
      showSetting: false,
      search: true,
      inputType: 'date'
    }
  ]

  const fetchData = async () => {
    const data = [
      {
        id: 1,
        construcion: 'CT_Leme',
        complaintRate: 5
      },
      {
        id: 2,
        construcion: 'CT_Skype',
        complaintRate: 2
      },
      {
        id: 3,
        construcion: 'John Brown',
        complaintRate: 5
      },
      {
        id: 4,
        construcion: 'John Brown',
        complaintRate: 1
      }
    ]
    return { data }
  }
  return (
    <div>
      <ListPage
        exportSelect
        searchPrimaryKey="codeOrLabel"
        request={fetchData}
        key={'project-complaints'}
        columns={columns}
      />
    </div>
  )
}

export default ProjectComplaints
