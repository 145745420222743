import { Link, useNavigate } from 'react-router-dom'
import ListPage from '../../../components/list-page'
import { Flex, Space, message } from 'antd'
import BaseService from '../../../api/base-service'
import { ActivisionColumn } from '../../../components/list-page/ActivisionColumn'
import { useRef } from 'react'
import { useMutation } from '@tanstack/react-query'

const RoleList = () => {
  const RolesService = new BaseService('/account/api/roles')
  const navigate = useNavigate()
  const childRef = useRef(null)

  const { mutate } = useMutation({
    mutationFn: async (record) => {
      const newStatus = record.status === 'ACTIVE' ? 'DEACTIVE' : 'ACTIVE'
      if (newStatus) {
        return await RolesService.update(`${record?.id}/${newStatus}`)
      }
    },
    onSuccess: (result) => {
      if (result.code === 2000) {
        handleRefresh()
      } else {
        message.error('Lưu thất bại')
      }
    },
    onError: (error) => {
      message.error(`Gửi yêu cầu lưu thất bại (Lỗi: ${error.message}})`)
    }
  })
  const columns = [
    {
      title: 'Mã vai trò',
      dataIndex: 'code',
      key: 'code',
      width: 220
    },
    {
      title: 'Tên vai trò',
      dataIndex: 'label',
      key: 'label',
      width: 250
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true
    },
    {
      title: 'Người sửa cuối',
      dataIndex: 'userUpdated',
      key: 'userUpdated',
      ellipsis: true
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (_, record) => (
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <ActivisionColumn status={record.status} />
        </Flex>
      )
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`chi-tiet/${record.id}`}>Edit</Link>
          <a
            onClick={() => {
              mutate(record)
            }}
          >
            {record.status === 'ACTIVE' ? 'Khóa' : 'Mở khóa'}
          </a>
        </Space>
      )
    }
  ]

  const handleAddModal = () => {
    navigate('them-moi')
  }

  const fetchData = async (value) => {
    try {
      const res = await RolesService.get({ ...value })
      return res
    } catch (error) {
      return []
    }
  }

  const handleRefresh = () => {
    childRef.current.fetch()
  }

  return (
    <>
      <ListPage
        searchPrimaryKey="codeOrLabel"
        request={(value) => {
          return fetchData(value)
        }}
        onCreate={handleAddModal}
        key={'role'}
        columns={columns}
        ref={childRef}
      />
    </>
  )
}

export default RoleList
