import { Flex } from 'antd'
import Title from 'antd/es/typography/Title'

const TabBarExtraContent = ({ text }) => {
  return (
    <Flex justify="start" style={{ width: 300 }}>
      <Title level={5} style={{ margin: '0 0 16px 0' }}>
        {text}
      </Title>
    </Flex>
  )
}

export default TabBarExtraContent
