import { Space } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import ListPage from '../../../components/list-page'
import BaseService from '../../../api/base-service'

const CompaniesService = new BaseService('/core/companies')
const CompanyList = () => {
  let navigate = useNavigate()
  const columns = [
    {
      title: 'Mã Công Ty',
      dataIndex: 'code',
      key: 'code',
      width: 220
    },
    {
      title: 'Tên Công Ty',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Kinh độ của công ty',
      dataIndex: 'longitude',
      key: 'longitude',
      showSetting: false
    },
    {
      title: 'Vĩ độ của công ty',
      dataIndex: 'latitude',
      key: 'latitude',
      showSetting: false
    },
    {
      title: 'Call center',
      dataIndex: 'callCenter',
      key: 'callCenter',
      showSetting: false
    },
    {
      title: 'Website của công ty',
      dataIndex: 'website',
      key: 'website'
    },
    {
      title: 'Tên ngân hàng của công ty',
      dataIndex: 'bankName',
      key: 'bankName',
      showSetting: false
    },
    {
      title: 'ID công ty mẹ',
      dataIndex: 'parentCompanyId',
      key: 'parentCompanyId'
    },
    {
      title: 'Sô tài khoản ngân hàng của công ty',
      dataIndex: 'accountBankNumber',
      key: 'accountBankNumber',
      showSetting: false
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`chi-tiet/${record.id}`}>Edit</Link>
          <a>Delete</a>
        </Space>
      ),
      fixed: 'right',
      width: 100
    }
  ]

  const handleCreate = () => {
    navigate('them-moi')
  }

  return (
    <ListPage request={async () => CompaniesService.get()} key={'company'} columns={columns} onCreate={handleCreate} />
  )
}

export default CompanyList
