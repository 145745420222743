import { Button, Col, ConfigProvider, Flex, Form, message, Row, Tabs } from 'antd'
import SettingContractInfo from './SettingContractInfo'
import TabBarExtraContent from '../../../../components/ui/TabBarExtraContent'
import { Link, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { CompanySystemTemplateService } from './SettingService'
import { useState } from 'react'
import dayjs from 'dayjs'

const CompanyDetailSetting = () => {
  const [form] = Form.useForm()
  const { id } = useParams()
  const [isUpdated, setUpdated] = useState(0)
  const mutation = useMutation({
    mutationFn: (values) => {
      values['representativeIdCardDate'] = dayjs(values['representativeIdCardDate']).format('MM/DD/YYYY')
      return CompanySystemTemplateService.update(id, values)
    },
    onSuccess: (result) => {
      if (result.code === 2000) {
        message.success('Lưu thành công!', 2)
        setUpdated((isUpdated) => isUpdated + 1)
      } else {
        message.error('Lưu thất bại', 2)
      }
    },
    onError: (error) => {
      message.error(`Gửi yêu cầu lưu thất bại (Lỗi: ${error.message}})`, 2)
    }
  })

  const items = [
    {
      key: 'contract-info',
      label: 'Thông tin hợp đồng',
      children: <SettingContractInfo form={form} id={id} isUpdated={isUpdated} />
    },
    {
      key: 'department',
      label: 'Khác',
      children: <></>
    }
  ]

  const handleFinish = (values) => {
    mutation.mutate(values)
  }

  return (
    <div>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Row gutter={[24, 0]}>
          <Col span={20}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    verticalItemPadding: '0px 0px'
                  }
                }
              }}
            >
              <Tabs
                tabPosition={'left'}
                items={items}
                unmountInactiveTabs={true}
                tabBarExtraContent={{
                  left: <TabBarExtraContent text={'Thông tin'} />
                }}
              />
            </ConfigProvider>
          </Col>
        </Row>

        <Row>
          <Col span={21}></Col>
          <Col span={3}>
            <Flex gap={10} justify="right">
              <Form.Item style={{ width: '50%' }}>
                <Link to={'/he-thong/cong-ty'}>
                  <Button block>Hủy</Button>
                </Link>
              </Form.Item>
              <Form.Item style={{ width: '50%' }}>
                <Button block loading={mutation.isPending} type="primary" htmlType="submit">
                  Lưu
                </Button>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default CompanyDetailSetting
