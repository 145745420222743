import { Button, Col, DatePicker, Flex, Form, Input, message, Modal, Row, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import useStaffBrief from '../../../../hooks/system/useStaffBrief'
import useConstructionsBriefs from '../../../../hooks/system/useConstructionsBrief'
import useCompaniesBrief from '../../../../hooks/system/useCompaniesBrief'
import { useEffect, useState } from 'react'
import BaseService from '../../../../api/base-service'
import { URL_QUALITY_CONTROL_SCHEDULE } from '../../../../constant/constantURL'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'

const qcService = new BaseService(URL_QUALITY_CONTROL_SCHEDULE)

const QCCalendarModal = ({ isModalOpen = true, handleClose, handleRefetch }) => {
  const navigate = useNavigate()
  const [companies, setCompanies] = useState([])
  const [staffs, setStaffs] = useState([])
  const [constructions, setConstructions] = useState([])
  const companyResult = useCompaniesBrief()
  const constructionResult = useConstructionsBriefs()
  const staffResult = useStaffBrief()

  const mutation = useMutation({
    mutationFn: (values) => qcService.create(values),
    onSuccess: (result) => {
      if (result.code === 2000) {
        message.success('Thêm thành công!')
        handleClose()
        handleRefetch()
      } else {
        message.error(`Thêm thất bại (Lỗi: ${result.message})`)
      }
    },
    onError: (error) => {
      message.error(`Gửi yêu cầu thêm thất bại (Lỗi: ${error.message})`)
    }
  })

  const handleFinish = (values) => {
    const payload = { ...values, recentTrainingDate: dayjs(new Date()) }
    mutation.mutate(payload)
  }

  const handleMove = () => {
    navigate('/dich-vu-khach-hang/lich-qc')
  }
  console.log(dayjs(new Date())?.format('DD-MM-YYYY hh:mm:ss'))

  useEffect(() => {
    if (companyResult.data?.data) {
      setCompanies(
        companyResult.data.data.map((value) => {
          return { value: value?.id, label: value?.name }
        })
      )
    }
  }, [companyResult.data])

  useEffect(() => {
    if (staffResult.data?.data) {
      setStaffs(
        staffResult.data.data.map((value) => {
          return { value: value?.code, label: value?.name }
        })
      )
    }
  }, [staffResult.data])

  useEffect(() => {
    if (constructionResult.data?.data) {
      setConstructions(
        constructionResult.data.data.map((value) => {
          return { value: value?.code, label: value?.name }
        })
      )
    }
  }, [constructionResult.data])

  return (
    <Modal centered title={'Thêm lịch QC'} open={isModalOpen} footer={null} onCancel={handleClose ?? handleMove}>
      <Form layout="vertical" onFinish={handleFinish}>
        <Row gutter={[15, 0]}>
          <Col span={12}>
            <Form.Item
              required
              name={'qualityControlDate'}
              label={'Ngày'}
              rules={[{ required: true, message: 'Vui lòng chọn ngày.' }]}
            >
              <DatePicker placeholder="Chọn ngày" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'timeOfQcDay'} label={'Thời gian'}>
              <Select
                allowClear
                placeholder="Chọn"
                options={[
                  { value: 'MORNING', label: 'Buổi sáng' },
                  { value: 'AFTERNOON', label: 'Buổi chiều' },
                  { value: 'BUSiNESS_HOURS', label: 'Giờ hành chính' }
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'customerInfo'} label={'Thông tin khách hàng'}>
              <Input placeholder="Nhập thông tin khách hàng" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'email'} label={'Email'}>
              <Input placeholder="Nhập địa chỉ email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'phoneNumber'} label={'SDT'}>
              <Input placeholder="nhập số điện thoại" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              name={'constructionCode'}
              label={'Công trình'}
              rules={[{ required: true, message: 'Vui lòng nhập tên công trình.' }]}
            >
              <Select placeholder="Chọn công trình" options={constructions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'Công ty'}>
              <Select placeholder="Chọn công ty" options={companies} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'reason'} label={'Lý do'}>
              <Input placeholder="Chọn lý do" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'staffQualityControlCode'} label={'Nhân viên QC'}>
              <Select placeholder="Chọn nhân viên" options={staffs} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'areaSupervisorCode'} label={'Giám sát khu vực'}>
              <Select placeholder="Chọn giám sát" options={staffs} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'complain'} label={'Phiếu khiếu nại'}>
              <Select placeholder="Chọn phiếu" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'recentTrainingDate'} label={'Ngày QC gần nhất'}>
              <DatePicker disabled placeholder="Chọn ngày" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={'note'} label={'Ghi chú'}>
              <Input.TextArea autoSize rows={2} placeholder="Ghi chú" />
            </Form.Item>
          </Col>
        </Row>
        <Flex justify="right">
          <Button type="text" onClick={handleClose ?? handleMove}>
            Hủy
          </Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </Flex>
      </Form>
    </Modal>
  )
}

export default QCCalendarModal
