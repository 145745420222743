import { DatePicker, Form, Modal, Select, Row, Col, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { enumReason, enumTime } from '../../../constant/enumSelectOptions'

const ModalAddTraining = ({ isModalOpen, handleCancel, handleOk }) => {
  return (
    <Modal title="Thêm lịch đào tạo" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="datepicker" label="Ngày">
              <DatePicker
                format={'DD MM YYYY'}
                style={{
                  width: '100%'
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="time" label="Thời gian">
              <Select options={enumTime} placeholder="Chọn thời gian" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="customerInfo" label="Thông tin khách hàng">
              <Input placeholder="Nhập thông tin khách hàng"></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <Input placeholder="Nhập email"></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="phoneNumber" label="SĐT">
              <Input placeholder="Nhập số điện thoại"></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="trainingStaff" label="NV đào tạo">
              <Select placeholder="Chọn nhân viên" options={[]} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="construction" label="Công trình">
              <Select placeholder="Chọn công trình" options={[]} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="company" label="Công ty">
              <Select placeholder="Chọn công ty" options={[]} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="GSKV" label="Giám sát khu vực">
              <Select placeholder="Chọn giám sát" options={[]} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="reason" label="Lý do">
              <Select placeholder="Chọn lý do" options={enumReason} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="lastTrainingDate" label="Ngày đào tạo gần nhất">
              <DatePicker
                format={'DD MM YYYY'}
                style={{
                  width: '100%'
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="note" label="Ghi chú">
              <TextArea rows={2} placeholder="Nhập ghi chú" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ModalAddTraining
