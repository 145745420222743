import { useQuery } from '@tanstack/react-query'
import { URL_ROLES } from '../../constant/constantURL'
import BaseService from '../../api/base-service'

const RolesService = new BaseService(URL_ROLES)

const fetchRoles = async () => {
  return await RolesService.get()
}

const useFetchRoles = () => {
  return useQuery({
    queryKey: ['account/api/roles'],
    queryFn: fetchRoles
  })
}

export default useFetchRoles
