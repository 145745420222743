import { useQuery } from '@tanstack/react-query'
import { Tabs } from 'antd'
import { configOptionCategoryService } from '../config-service'
import OptionsTable from './OptionsTable'

const OptionsContent = ({ id }) => {
  const { data } = useQuery({
    queryKey: ['config-option-category', id],
    queryFn: () => configOptionCategoryService.getById(id).then((res) => res.data)
  })

  const mappingTabs = data?.itemDataDtoList.map((item) => ({
    key: item.id,
    label: item.name,
    children: <OptionsTable data={item.configDataList} columnName={item.columnName} id={item.id} />
  }))

  return (
    <div>
      <Tabs tabPosition={'left'} items={mappingTabs} unmountInactiveTabs={true} tabBarStyle={{ width: 300 }} addIcon />
    </div>
  )
}

export default OptionsContent
