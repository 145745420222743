import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import routes from './config/configRouter'
import Layout from './components/layout/Layout'
import PageNotFound from './page/PageNotFound'
import Login from './page/Login'
import React from 'react'

const renderRoutes = (routes, basePath = '') => {
  return routes.map((route, index) => {
    const fullPath = `${basePath}${route.path}`
    return (
      <React.Fragment key={index}>
        <Route path={fullPath} element={route.element} />
        {route.editelement && <Route path={`${fullPath}/chi-tiet/:id`} element={route.editelement} />}
        {route.addelement && <Route path={`${fullPath}/them-moi`} element={route.addelement} />}
        {route.children && renderRoutes(route.children, fullPath)}
      </React.Fragment>
    )
  })
}

const RouterComponent = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Layout />}>
        {renderRoutes(routes)}
      </Route>
      <Route path="/dang-nhap" element={<Login />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </Router>
)

export default RouterComponent
