import { Button, Col, Form, Input, InputNumber, Row, Select, Upload, message, Spin } from 'antd'
import { useEffect, useState } from 'react'
import UploadButton from '../../../../components/ui/UploadButton'
import BaseService from '../../../../api/base-service'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import useCompaniesBrief from '../../../../hooks/system/useCompaniesBrief'

const CompanyDetailBasicInfo = () => {
  const companyResult = useCompaniesBrief()

  let navigate = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [imageUrl, setImageUrl] = useState(null)
  const CompaniesService = new BaseService('/core/companies/general')
  const [companieOptions, setCompaniesOptions] = useState([])

  const { mutate } = useMutation({
    mutationFn: async (body) => {
      if (id) return await CompaniesService.update(id, body)
      return await CompaniesService.create(body)
    },
    onMutate: () => {},
    onSuccess: (res) => {
      if (res.code === 2000) {
        message.success('Form submitted successfully!')
        form.resetFields()
        handleClose()
      } else {
        throw new Error(`Update failed with status ${res.status}`)
      }
    },
    onError: (error) => {
      message.error(`Form submission failed: ${error.message}`)
    },
    onSettled: () => {}
  })

  const { isPending, isLoading, data } = useQuery({
    queryKey: ['companies/general', id],
    queryFn: async () => {
      if (id) {
        return await CompaniesService.getById(id)
      }
      return
    }
  })

  useEffect(() => {
    if (companyResult.data?.data) {
      setCompaniesOptions(
        companyResult.data.data.map((value) => {
          return { value: value?.id, label: value?.name }
        })
      )
    }
  }, [companyResult.data])

  useEffect(() => {
    if (data) {
      const response = {
        ...data.data,
        parentCompanyId: data.data?.parentCompany?.id
      }
      form.setFieldsValue(response)
    }
  }, [data, form])

  const beforeUpload = (file) => {
    return true
  }

  const handleChange = (info) => {
    if (info.file.status === 'done') {
      setImageUrl(info.file.response.url)
    }
  }

  const handleSubmit = (values) => {
    const formData = { ...values }
    mutate(formData)
  }

  const handleSubmitFailed = () => {
    message.error('Please correct the errors in the form!')
  }

  const handleClose = () => {
    navigate('/he-thong/cong-ty')
  }

  if (isPending) return 'Loading...'

  return (
    <Spin spinning={isLoading}>
      <Form form={form} layout="vertical" onFinish={handleSubmit} onFinishFailed={handleSubmitFailed}>
        <Row gutter={[64, 0]}>
          <Col span={8}>
            <Row>
              <Col span={8}>Thông tin cơ bản</Col>
              <Col span={16}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Upload
                    name="logo"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                      <UploadButton loading={isLoading} />
                    )}
                  </Upload>
                  <Form.Item
                    label="Mã công ty"
                    name="code"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Mã công ty là bắt buộc'
                      },
                      {
                        pattern: /^[a-zA-Z0-9]+$/,
                        message: 'Vui lòng nhập mã không chứa ký tự đặc biệt và khoảng cách'
                      }
                    ]}
                  >
                    <Input placeholder="Nhập mã công ty" />
                  </Form.Item>
                  <Form.Item label="Tên công ty" name="name" required rules={[{ required: true }]}>
                    <Input placeholder="input placeholder" />
                  </Form.Item>
                  <Form.Item label="Công ty mẹ" name="parentCompanyId">
                    <Select allowClear options={companieOptions} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={16} style={{ borderLeft: '1px #EFEFF1 solid' }}>
            <Row gutter={[24, 0]}>
              <Col span={4}>Thông tin khác</Col>
              <Col span={10}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Item label="Website" name="website">
                    <Input placeholder="input placeholder" />
                  </Form.Item>
                  <Form.Item label="Kinh độ" name="longitude">
                    <InputNumber
                      style={{
                        width: '100%'
                      }}
                      min="-180"
                      max="180"
                    />
                  </Form.Item>
                  <Form.Item label="Ngân hàng" name="bankName">
                    <Input placeholder="input placeholder" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Item label="Call center" name="callCenter">
                    <Input placeholder="input placeholder" />
                  </Form.Item>
                  <Form.Item label="Vĩ độ" name="latitude">
                    <InputNumber
                      style={{
                        width: '100%'
                      }}
                      min="-180"
                      max="180"
                    />
                  </Form.Item>
                  <Form.Item label="Số tài khoản" name="accountBankNumber">
                    <Input placeholder="input placeholder" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={4}></Col>
              <Col span={20}>
                <Form.Item label="Địa chỉ" name="address">
                  <Input placeholder="input placeholder" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>Ghi chú</Col>
              <Col span={20}>
                <Form.Item label="Ghi chú" name="note">
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify={'end'}>
          <div style={{ display: 'flex', gap: 16 }}>
            <Form.Item>
              <Button onClick={handleClose}>Close</Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Row>
      </Form>
    </Spin>
  )
}

export default CompanyDetailBasicInfo
