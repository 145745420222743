import { useQuery } from '@tanstack/react-query'
import { URL_COMPANIES_BRIEF } from '../../constant/constantURL'
import BaseService from '../../api/base-service'

const CompaniesService = new BaseService(URL_COMPANIES_BRIEF)

const fetchCompaniesBrief = async () => {
  return await CompaniesService.get()
}

const useCompaniesBrief = () => {
  return useQuery({
    queryKey: ['companiesBrief'],
    queryFn: fetchCompaniesBrief
  })
}

export default useCompaniesBrief
