import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Typography
} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MailComplainModal from './components/MailComplainModal'
import useConstructionsBriefs from '../../../hooks/system/useConstructionsBrief'

const FakeData = ['KN001', 'KN009', 'KN0013']
const FakeConstruction = [
  { name: 'Nhà thờ', gskvId: 'NV001', gskvName: 'Nam' },
  { name: 'Nhà chùa', gskvId: 'NV002', gskvName: 'Non' },
  { name: 'Nhà văn hóa', gskvId: 'NV003', gskvName: 'Nhả' }
]

const MailDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [constructionOptions, setConstructionOptions] = useState([])
  const [companyOptions, setCompanyOptions] = useState([])
  const constructionResult = useConstructionsBriefs()

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleRefetch = () => {
    setIsModalOpen(false)
  }

  const handleOpen = (id) => {
    setIsModalOpen(true)
  }

  const handleClose = () => {
    navigate('/dich-vu-khach-hang/mail')
  }

  const handleComplain = (id) => {
    navigate(`/dich-vu-khach-hang/khieu-nai/chi-tiet/${id}`)
  }

  useEffect(() => {
    if (constructionResult.data?.data) {
      setConstructionOptions(
        constructionResult.data.data.map((construction) => {
          return { value: construction?.id, label: construction?.name }
        })
      )
    }
  }, [constructionResult.data])

  return (
    <>
      <Form form={form} layout="vertical">
        <Row>
          <Col span={6}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Thông tin chung
            </Typography.Title>
          </Col>
          <Col span={18}>
            <Row gutter={[50, 0]}>
              <Col span={6}>
                <Form.Item required label="Thời gian">
                  <DatePicker disabled={!!id} showTime={true} format={'DD/MM/YYYY hh:mm'} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Nhóm">
                  <Select
                    placeholder="Nhóm"
                    options={[
                      { value: 'NV', label: 'Nhân viên' },
                      { value: 'KH', label: 'Khách hàng' },
                      { value: 'K', label: 'Khác' }
                    ]}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Email nhận">
                  <Input disabled={!!id} placeholder="Email nhận" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Công ty">
                  <Select placeholder="Công ty" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item required label="Mail gửi">
                  <Input disabled={!!id} placeholder="Mail gửi" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Người gửi">
                  <Input placeholder="Người gửi" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Công trình">
                  <Select placeholder="Công trình" options={constructionOptions} onSelect={() => {}} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="GSKV">
                  <Input placeholder="Họ tên - Mã NV" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={6}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Thông tin chi tiết
            </Typography.Title>
          </Col>
          <Col span={18}>
            <Row gutter={[50, 0]}>
              <Col span={24}>
                <Form.Item label="Tiêu đề">
                  <Input disabled={!!id} placeholder="Tiêu đề" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Nội dung">
                  <Row
                    gutter={[35, 0]}
                    style={{ border: 'solid 1px lightGray', borderRadius: 10, paddingTop: 10, margin: 0 }}
                  >
                    <Col span={12}>
                      <Form.Item label="Nội dung của email">
                        <TextArea disabled={!!id} rows={1} placeholder="Nội dung của email" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Tóm tắt khiếu nại">
                        <TextArea rows={1} placeholder="Tóm tắt khiếu nại" maxLength={2000} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={6}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Liên kết
            </Typography.Title>
          </Col>
          <Col span={18}>
            <Space>
              <Typography.Text>Khiếu nại: </Typography.Text>
              {FakeData.map((value, index) => (
                <a onClick={() => handleComplain(value)} key={index}>
                  {value}
                </a>
              ))}
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={6}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Tiếp nhận & xử lý
            </Typography.Title>
          </Col>
          <Col span={18}>
            <Space>
              {FakeData.map((value, index) => (
                <Card key={index} styles={{ body: { padding: 8 } }}>
                  <Flex gap={15} justify="space-between">
                    <Typography.Text>{index == 0 ? 'System' : value}</Typography.Text>
                    <Tag bordered={false} color={index % 2 == 0 ? 'processing' : 'error'} style={{ margin: 0 }}>
                      {index % 2 == 0 ? 'Tiếp nhận' : 'Chuyển khiếu nại'}
                    </Tag>
                  </Flex>
                  <Flex gap={15} justify="space-between">
                    <Typography.Text style={{ fontWeight: 'lighter' }}>{value}</Typography.Text>
                    <Badge status="default" />
                    <Typography.Text style={{ fontWeight: 'lighter' }}>{Date.now()}</Typography.Text>
                  </Flex>
                </Card>
              ))}
            </Space>
          </Col>
        </Row>
        <Divider />
        <Flex gap={10} justify="right">
          <Button onClick={() => handleOpen(id)}>Chuyển khiếu nại</Button>
          <Button onClick={handleClose}>Hủy</Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </Flex>
      </Form>
      {isModalOpen && (
        <MailComplainModal
          id={id}
          isModalOpen={isModalOpen}
          handleClose={handleCloseModal}
          handleRefetch={handleRefetch}
        />
      )}
    </>
  )
}

export default MailDetail
