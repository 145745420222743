import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons'
import { AgGridReact } from 'ag-grid-react'
import { Button, Row, notification } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BaseService from '../../../../api/base-service'
import { useMutation, useQuery } from '@tanstack/react-query'

const documentSiningService = new BaseService('/core/companies/approve-process')

const DocumentSiningTable = ({ documentId, staffBrief }) => {
  const { id } = useParams()
  const { data } = useQuery({
    queryKey: ['documents', documentId, id],
    queryFn: () => documentSiningService.getById(id, { documentId }).then((res) => res.data)
  })

  const { mutate } = useMutation({
    mutationFn: (data) => documentSiningService.update(id, data),
    onSuccess: (res) => {
      notification.success({ message: res.message })
    },
    onError: (res) => {
      const dataResponse = res.response.data
      notification.error({ message: dataResponse?.message || dataResponse?.error })
    }
  })
  const signersList = staffBrief.map((item) => ({ value: item.id, label: item.code }))
  const [rowData, setRowData] = useState([])

  // Update rowData when data is loaded
  useEffect(() => {
    if (data) {
      const initialRowData = data?.approveProcessUpdateDetailRequests?.map((item, index) => ({
        key: index,
        order: item.orderPriority,
        position: item.position,
        staffId: item.staffBriefDto?.id,
        isRequired: item.isRequired
      }))
      setRowData(initialRowData)
    }
  }, [data])

  const [columnDefs] = useState([
    // { rowDrag: true, initialWidth: 40 },
    {
      headerName: 'Thứ tự',
      field: 'order',
      type: 'numericColumn',
      editable: true,
      width: 150,
      cellStyle: { textAlign: 'center' }
    },
    { headerName: 'Chức vụ', field: 'position', editable: true },
    {
      headerName: 'Người ký',
      field: 'staffId',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: signersList.map((signer) => signer.value)
      },
      valueFormatter: (params) => {
        const signer = signersList.find((signer) => signer.value === params.value)
        return signer ? signer.label : params.value
      }
    },
    {
      headerName: 'Bắt buộc',
      field: 'isRequired',
      cellRenderer: 'agCheckboxCellRenderer',
      editable: true,
      cellEditor: 'agCheckboxCellEditor',
      cellRendererParams: {
        checked: (params) => params.value,
        onChange: (params) => handleCheckboxChange(params)
      }
    },
    {
      field: 'action',
      initialWidth: 100,
      cellRenderer: (params) => (
        <DeleteTwoTone twoToneColor="red" onClick={() => handleDeleteRow(params.data.key)} style={{ fontSize: 16 }} />
      )
    }
  ])

  const handleDeleteRow = (key) => {
    setRowData((preRowData) => preRowData.filter((row) => row.key !== key))
  }

  const handleAddRow = () => {
    const newRow = {
      order: rowData.length + 1,
      key: rowData.length + 1,
      position: ''
    }
    setRowData([...rowData, newRow])
  }
  const onCellValueChanged = (params) => {
    const updatedData = [...rowData]
    const index = updatedData.findIndex((row) => row.key === params.data.key)
    if (index !== -1) {
      updatedData[index] = params.data
      setRowData(updatedData)
    }
  }
  const handleCheckboxChange = (params) => {
    const updatedData = [...rowData]
    const index = updatedData.findIndex((row) => row.key === params.data.key)
    if (index !== -1) {
      updatedData[index].status = !updatedData[index].status
      setRowData(updatedData)
    }
  }
  const onRowDragEnd = useCallback((event) => {
    // Get the nodes after dragging has ended
    const updatedRows = []
    event.api.forEachNode((node) => updatedRows.push(node.data))

    // Update the rowData state with the new order
    setRowData(updatedRows)
  }, [])

  const saveData = () => {
    console.log('Saving data:', rowData)
    mutate({
      documentId: id,
      approveProcessUpdateDetailRequests: rowData.map(({ key, order, status, ...item }, index) => ({
        ...item,
        orderPriority: order
      }))
    })
  }
  return (
    <div>
      <div className="ag-theme-quartz" style={{ height: 350 }}>
        <AgGridReact
          rowData={rowData}
          onCellValueChanged={onCellValueChanged}
          onRowDragEnd={onRowDragEnd}
          columnDefs={columnDefs}
          rowDragManaged={true}
          animateRows={true}
          rowHeight={40}
          autoSizeStrategy={{ type: 'fitGridWidth' }}
        />
      </div>
      <Button icon={<PlusOutlined />} style={{ width: '100%', marginTop: 20 }} type="dashed" onClick={handleAddRow}>
        Thêm
      </Button>
      <Row justify={'end'} style={{ marginTop: 20 }}>
        <Button type="primary" onClick={saveData}>
          Lưu
        </Button>
      </Row>
    </div>
  )
}

export default DocumentSiningTable
