import { PlusOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { AgGridReact } from 'ag-grid-react'
import { Button, Space, notification } from 'antd'
import { useCallback, useRef, useState } from 'react'
import { configDataService } from '../config-service'

const OptionsTable = ({ data, columnName, id }) => {
  const { mutate } = useMutation({
    mutationFn: (data) => configDataService.create(data),
    onSuccess: (res) => {
      notification.success({ message: res.message })
    },
    onError: (res) => {
      const dataResponse = res.response.data
      notification.error({ message: dataResponse?.message || dataResponse?.error })
    }
  })
  const [rowData, setRowData] = useState([
    ...data.map((item, index) => ({ ...item, status: item.status === 'ACTIVE' ? true : false, key: index }))
  ])
  const gridRef = useRef()

  const [columnDefs] = useState([
    { rowDrag: true, initialWidth: 40 },
    {
      headerName: columnName,
      field: 'value',
      editable: true,
      cellRenderer: (params) => params.value || <span style={{ color: '#ccc' }}>Click to edit!</span>
    },
    {
      headerName: 'Ghi chú',
      field: 'note',
      editable: true,
      cellRenderer: (params) => params.value || <span style={{ color: '#ccc' }}>Click to edit!</span>
    },
    {
      headerName: 'khóa',
      field: 'status',
      cellRenderer: 'agCheckboxCellRenderer',
      editable: true,
      cellEditor: 'agCheckboxCellEditor',
      cellRendererParams: {
        onChange: (params) => {
          const updatedData = rowData.map((row) =>
            row.key === params.node.data.key ? { ...row, status: params.value } : row
          )
          setRowData(updatedData)
        }
      }
    }
  ])

  const onCellValueChanged = useCallback(
    (event) => {
      const updatedData = rowData.map((row) => (row.key === event.data.key ? event.data : row))
      setRowData(updatedData)
    },
    [rowData]
  )

  const addRow = () => {
    const newRow = {
      key: rowData.length,
      value: '',
      note: ''
    }

    // Stop editing any cell before adding a new row
    gridRef.current.api.stopEditing()

    setRowData([...rowData, newRow])
  }

  const saveData = () => {
    console.log('Saving data:', rowData)
    mutate({
      configItemId: id,
      configDataRequestList: rowData.map(({ key, ...item }, index) => ({
        ...item,
        priority: rowData.length - index,
        status: item.status ? 'ACTIVE' : 'DEACTIVE'
      }))
    })
  }
  const onRowDragEnd = useCallback((event) => {
    // Get the nodes after dragging has ended
    const updatedRows = []
    event.api.forEachNode((node) => updatedRows.push(node.data))

    // Update the rowData state with the new order
    setRowData(updatedRows)
  }, [])
  return (
    <div>
      <div className="ag-theme-quartz" style={{ height: 400 }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          onCellValueChanged={onCellValueChanged}
          columnDefs={columnDefs}
          rowDragManaged={true}
          onRowDragEnd={onRowDragEnd}
          animateRows={true}
          rowHeight={40}
          autoSizeStrategy={{ type: 'fitGridWidth' }}
        />
      </div>
      <Button icon={<PlusOutlined />} style={{ width: '100%', marginTop: 20 }} type="dashed" onClick={addRow}>
        Thêm
      </Button>
      <Space style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" onClick={saveData}>
          Save
        </Button>
      </Space>
    </div>
  )
}

export default OptionsTable
