import { useEffect, useRef, useState } from 'react'
import ListPage from '../../../components/list-page'
import { ActivisionColumn, ReasonTypeValue, StaffCodeColumn } from '../../../components/list-page/ActivisionColumn'
import { Flex, Progress, Space, Typography } from 'antd'
import Status from '../../../constant/constantStatus'
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { enumCustomer, enumStatusCallCenter } from '../../../constant/enumSelectOptions'
import ReactAudioPlayer from 'react-audio-player'
import { URL_CALL_CENTERS, URL_COMPANY_BRIEF } from '../../../constant/constantURL'
import BaseService from '../../../api/base-service'
import useConstructionsBriefs from '../../../hooks/system/useConstructionsBrief'
import { useQuery } from '@tanstack/react-query'

const callCentersService = new BaseService(URL_CALL_CENTERS)
const companiesBrief = new BaseService(URL_COMPANY_BRIEF)

const CallCenter = () => {
  const audioRef = useRef()
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [constructions, setConstructions] = useState([])
  const [isPlay, setIsPlay] = useState(false)
  const constructionResult = useConstructionsBriefs()
  const navigate = useNavigate()

  const companies = useQuery({
    queryKey: ['companies'],
    queryFn: async () => {
      const response = await companiesBrief.get()
      const result = response?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      return result
    }
  })

  const columns = [
    {
      title: 'Ngày tiếp nhận',
      dataIndex: 'receivedTime',
      key: 'receivedTime',
      search: true,
      inputType: 'dateHour',
      searchField: 'fromTime',
      showSetting: false
    },
    {
      title: 'Ngày giải quyết',
      dataIndex: 'processTime',
      key: 'processTime',
      search: true,
      showSetting: false,
      inputType: 'dateHour',
      searchField: 'toTime'
    },
    {
      title: 'Thời gian',
      dataIndex: 'time',
      key: 'time',
      showSetting: false
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      inputType: 'select',
      searchField: 'id',
      showSetting: false
    },
    {
      title: 'Thuộc nhóm',
      dataIndex: 'groupType',
      key: 'groupType',
      search: true,
      inputType: 'select',
      searchField: 'groupType',
      options: enumCustomer,
      render: (_, record) => (
        <Flex style={{ width: '100%' }}>
          <StaffCodeColumn staff={record?.groupType} />
        </Flex>
      )
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      search: true
    },
    {
      title: 'Mã NV',
      dataIndex: 'staffCode',
      key: 'staffCode',
      search: true,
      searchField: 'staffId'
    },
    {
      title: 'Họ tên',
      dataIndex: 'fullName',
      key: 'fullName',
      search: true
    },
    {
      title: 'Công trình',
      dataIndex: 'constructionCode',
      key: 'constructionCode',
      search: true,
      render: (_, record) => (
        <Typography.Text>{record?.isAllConstruction ? 'Toàn quyền' : record?.constructionCode}</Typography.Text>
      ),
      inputType: 'select',
      searchField: 'constructionId',
      options: [{ value: 0, label: 'Toàn quyền' }, ...constructions]
    },
    {
      title: 'Phân loại',
      dataIndex: 'reasonType ',
      key: 'reasonType ',
      render: (_, record) => (
        <Flex>
          <ReasonTypeValue reason={record?.reasonType} />
        </Flex>
      )
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Người tiếp nhận',
      dataIndex: 'receivedStaffCode',
      key: 'receivedStaffCode',
      showSetting: false
    },
    {
      title: 'Công ty',
      dataIndex: 'companyCode',
      key: 'companyCode',
      search: true,
      inputType: 'select',
      searchField: 'companyId',
      showSetting: false,
      options: companies?.data
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content'
    },
    {
      title: 'Hướng giải quyết',
      dataIndex: 'solution',
      key: 'solution'
    },
    {
      title: 'Ghi âm',
      dataIndex: 'record',
      key: 'record',
      render: (_, record) =>
        record?.record ? (
          <Space>
            <div>{record.record}</div>
            <Flex justify="space-between" align="center">
              {isPlay ? (
                <PauseCircleOutlined
                  style={{
                    fontSize: 24
                  }}
                  onClick={handlePlayPause}
                />
              ) : (
                <PlayCircleOutlined
                  style={{
                    fontSize: 24
                  }}
                  onClick={handlePlayPause}
                />
              )}
              <ReactAudioPlayer ref={audioRef} src="" controls={false} />
              <Progress percent={(currentTime / duration) * 100} showInfo={false} />
            </Flex>
          </Space>
        ) : (
          <div></div>
        )
    },
    {
      title: 'Quá hạn',
      dataIndex: 'overdue',
      key: 'overdue'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      search: true,
      render: (_, record) => (
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <ActivisionColumn status={record?.status} />
        </Flex>
      ),
      inputType: 'select',
      searchField: 'status',
      options: enumStatusCallCenter
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
          <a>
            {record?.status === Status.PROCESSING.description ? (
              <div onClick={() => handleOpenModalEdit(record.id)}>Edit</div>
            ) : (
              <div>View</div>
            )}
          </a>
        </Space>
      )
    }
  ]

  useEffect(() => {
    if (constructionResult.data?.data) {
      setConstructions(
        constructionResult.data.data.map((value) => {
          return { value: value?.id, label: value?.name }
        })
      )
    }
  }, [constructionResult.data])

  const handleOpenModalAdd = () => {
    navigate('them-moi')
  }

  const handleOpenModalEdit = (recordId) => {
    navigate(`chi-tiet/${recordId}`)
  }

  const handlePlayPause = () => {
    const audioElement = audioRef.current.audioEl.current

    if (isPlay) {
      audioElement.pause()
    } else {
      audioElement.play()
    }
    setIsPlay(!isPlay)
  }

  useEffect(() => {
    const audioElement = audioRef.current?.audioEl.current

    if (audioElement) {
      const handleLoadedMetadata = () => {
        setDuration(audioElement.duration)
      }

      const handleTimeUpdate = () => {
        setCurrentTime(audioElement.currentTime)
      }

      audioElement.addEventListener('loadedmetadata', handleLoadedMetadata)
      audioElement.addEventListener('timeupdate', handleTimeUpdate)

      return () => {
        audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
        audioElement.removeEventListener('timeupdate', handleTimeUpdate)
      }
    }
  }, [audioRef])

  return (
    <>
      <ListPage
        exportFile
        request={async (params) => callCentersService.get(params)}
        key={'switchBoard'}
        columns={columns}
        onCreate={handleOpenModalAdd}
        searchPrimaryKey="titleOrContent"
      />
    </>
  )
}

export default CallCenter
