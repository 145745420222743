export const Status = Object.freeze({
  DEACTIVE: Symbol('DEACTIVE'),
  ACTIVE: Symbol('ACTIVE'),
  PENDING_PASSWORD_CHANGE: Symbol('PENDING_PASSWORD_CHANGE'),
  SUCCESS: Symbol('SUCCESS'),
  PROCESSING: Symbol('PROCESSING'),
  HANDLE: Symbol('HANDLE'),
  RECEIVE: Symbol('RECEIVE'),
  CLOSE: Symbol('CLOSE'),
  CALLING: Symbol('CALLING'),
  MISSING: Symbol('MISSING'),
  CANCEL: Symbol('CANCEL'),
  COMPLETED: Symbol('COMPLETED'),
  CANCELED: Symbol('CANCELED')
})

export default Status
