import { Col, Flex, Row, Tabs } from 'antd'
import Title from 'antd/es/typography/Title'
import DocumentSiningTable from './DocumentSiningTable'
import BaseService from '../../../../api/base-service'
import { useQuery } from '@tanstack/react-query'
const documentSiningService = new BaseService('/core/documents')
const staffService = new BaseService('/core/staffs/brief')

const DocumentSining = () => {
  const { data = [] } = useQuery({
    queryKey: ['documents'],
    queryFn: () => documentSiningService.get().then((res) => res.data)
  })
  const { data: staffBrief = [] } = useQuery({
    queryKey: ['staff-brief'],
    queryFn: () => staffService.get().then((res) => res.data)
  })
  const mappingTabs = data.map((item) => ({
    key: item.id,
    label: item.name,
    children: <DocumentSiningTable documentId={item.id} staffBrief={staffBrief} />
  }))

  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Flex align="start" justify="space-between">
            <Title level={5} style={{ marginTop: 8 }}>
              Danh sách chứng từ
            </Title>
          </Flex>
        </Col>
        <Col span={24}>
          <Tabs tabPosition={'left'} items={mappingTabs} unmountInactiveTabs={true} tabBarStyle={{ width: 300 }} />
        </Col>
      </Row>
    </div>
  )
}

export default DocumentSining
