import httpRequest from './http-request'

class BaseService {
  constructor(endPoint) {
    this.endPoint = endPoint
  }

  async get(params = {}) {
    return httpRequest.get(this.endPoint, { params }).then((res) => res.data)
  }

  async getById(id, params = {}) {
    return httpRequest.get(`${this.endPoint}/${id}`, { params }).then((res) => res.data)
  }

  async create(data) {
    return httpRequest.post(this.endPoint, data).then((res) => res.data)
  }

  async update(id, data = {}, afterId) {
    try {
      const res = await httpRequest.put(`${this.endPoint}${id ? `/${id}` : ''}${afterId ? `/${afterId}` : ''}`, data)
      return res?.data
    } catch (error) {
      return { error }
    }
  }

  async remove(id) {
    try {
      const res = await httpRequest.delete(`${this.endPoint}/${id}`).then((res) => res.data)
      return res?.data
    } catch (error) {
      return { error }
    }
  }

  async patch(id, afterId) {
    try {
      const res = await httpRequest
        .patch(`${this.endPoint}/${id}${afterId ? `/${afterId}` : ''}`)
        .then((res) => res.data)
      return res
    } catch (error) {
      return { error }
    }
  }

  async post(id, data) {
    try {
      const res = await httpRequest.post(`${this.endPoint}/${id}`, data).then((res) => res.data)
      return res?.data
    } catch (error) {
      return { error }
    }
  }
}

export default BaseService
