import { Col, DatePicker, Flex, Form, Input, Row, Select, Typography } from 'antd'
import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { CompanySystemTemplateService } from './SettingService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { LoadingOutlined } from '@ant-design/icons'

const SettingContractInfo = ({ id, form, isUpdated }) => {
  const { isPending, isError, data, error, refetch } = useQuery({
    queryKey: ['system-template', id],
    queryFn: () => CompanySystemTemplateService.getById(id)
  })

  useEffect(() => {
    if (data && data.data) {
      dayjs.extend(customParseFormat)
      data.data['representativeIdCardDate'] = dayjs(data.data['representativeIdCardDate'], 'MM/DD/YYYY')
      form.setFieldsValue(data.data)
    }
  }, [data])

  useEffect(() => {
    if (isUpdated > 0) {
      refetch()
    }
  }, [isUpdated])

  if (isPending) {
    return (
      <Flex style={{ height: '100%', width: '100%' }} align="center" justify="center">
        <LoadingOutlined />
      </Flex>
    )
  }

  if (isError) {
    return (
      <Flex style={{ height: '100%', width: '100%' }} align="center" justify="center">
        <Typography.Text>Lỗi: {error.message}</Typography.Text>
      </Flex>
    )
  }

  return (
    <Flex vertical>
      <Form.Item name="companyName" label="Tên công ty">
        <Input name="companyName" placeholder="Tên công ty" />
      </Form.Item>
      <Form.Item name="companyAddress" label="Địa chỉ">
        <Input name="companyAddress" placeholder="Địa chỉ" />
      </Form.Item>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="companyTaxCode"
            label="Mã số thuế"
            rules={[
              {
                pattern: new RegExp('^\\S*$'),
                message: 'Mã số thuế không được có khoảng trống.'
              }
            ]}
          >
            <Input name="companyTaxCode" placeholder="Mã số thuế" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="phoneNumber"
            label="Điện thoại"
            rules={[
              {
                pattern: new RegExp('^[0-9]'),
                message: 'Số điện thoại chỉ được phép nhập số.'
              },
              {
                validator: (_, value) => {
                  return value.replace(/\s/g, '').length <= 11
                    ? Promise.resolve()
                    : Promise.reject(new Error('Số điện thoại không được dài hơn 11 số'))
                }
              },
              {
                validator: (_, value) => {
                  return value.replace(/\s/g, '').length > 9 || value.replace(/\s/g, '').length === 0
                    ? Promise.resolve()
                    : Promise.reject(new Error('Số điện thoại không được ngắn hơn 9 số'))
                }
              }
            ]}
          >
            <Input name="phoneNumber" placeholder="Điện thoại" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
                message: 'Email đã nhập không đúng định dạng.'
              }
            ]}
          >
            <Input type="email" name="email" placeholder="Email" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item name="representativeName" label="Người đại diện">
            <Input name="representativeName" placeholder="Người đại diện" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item name="representativePosition" label="Chức vụ">
            <Input name="representativePosition" placeholder="Chức vụ" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item name="representativeSex" label="Giới tính">
            <Select
              name="representativeSex"
              placeholder="Giới tính"
              options={[
                {
                  value: 'Nam',
                  label: 'Nam'
                },
                {
                  value: 'Nữ',
                  label: 'Nữ'
                }
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="representativeIdCard"
            label="CMND / CCCD"
            rules={[
              {
                pattern: new RegExp('^[0-9]'),
                message: 'Số CMND/CCCD chỉ được phép nhập số.'
              },
              {
                min: 9,
                message: 'Số CMND/CCCD không được dưới 9 số.'
              },
              {
                max: 12,
                message: 'Số CMND/CCCD không được hơn 12 ký tự.'
              }
            ]}
          >
            <Input name="representativeIdCard" placeholder="CMND / CCCD" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item name="representativeIdCardDate" label="Ngày cấp">
            <DatePicker
              name="representativeIdCardDate"
              format={'DD/MM/YYYY'}
              placeholder="Ngày cấp"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item name="representativeIdCardPlace" label="Cấp tại">
            <Input name="representativeIdCardPlace" placeholder="Cấp tại" />
          </Form.Item>
        </Col>
      </Row>
    </Flex>
  )
}

export default SettingContractInfo
