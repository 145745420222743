//constant URL
export const URL_ACCOUNT = '/core/accounts'
export const URL_ACCOUNT_ME = '/core/accounts/me'
export const URL_DOCUMENT_SETTING = '/core/documents/'
export const URL_ROLES = 'account/api/roles'
export const URL_WORKING_SHIFTS = '/core/working-shifts'
export const URL_ACCOUNT_ROLES = 'core/accounts/companies-and-roles'
export const URL_ACCOUNT_CONSTRUCTIONS = '/core/accounts/constructions'
export const URL_CALL_CENTERS = '/core/callCenters'
export const URL_QUALITY_CONTROL_SCHEDULE = '/core/quality-control/schedule'

//brief
export const URL_ACCOUNT_BRIEF = '/core/accounts/brief'
export const URL_COMPANY_BRIEF = '/core/companies/brief'
export const URL_STAFF_BRIEF = '/core/staffs/brief'
export const URL_COMPANIES_BRIEF = 'core/companies/brief'
export const URL_CONSTRUCTS_BRIEF = 'core/constructions/brief'
export const URL_DEPARTMENTS_BRIEF = 'core/departments/brief'
export const URL_CONSTRUCTIONS_BRIEF = '/core/constructions/brief'
