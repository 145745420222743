import { useQuery } from '@tanstack/react-query'
import { URL_STAFF_BRIEF } from '../../constant/constantURL'
import BaseService from '../../api/base-service'

const service = new BaseService(URL_STAFF_BRIEF)

const fetch = async () => {
  return await service.get()
}

const useStaffBrief = () => {
  return useQuery({
    queryKey: [URL_STAFF_BRIEF],
    queryFn: fetch
  })
}

export default useStaffBrief
