import ListPage from '../../../components/list-page'
import { Flex, Space } from 'antd'
import { Link } from 'react-router-dom'
import ActivisionColumn from '../../../components/list-page/ActivisionColumn'
import { Typography } from 'antd'

const { Text } = Typography

const CheckQuality = () => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 220,
      search: true,
      inputType: 'text',
      searchField: 'id'
    },
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Thông tin khách hàng',
      dataIndex: 'customerInfo',
      key: 'customerInfo'
    },
    {
      title: 'Công trình',
      dataIndex: 'construction',
      key: 'construction',
      search: true,
      inputType: 'select',
      options: [],
      searchField: 'construction'
    },
    {
      title: 'Công ty',
      dataIndex: 'company',
      key: 'company',
      search: true,
      inputType: 'select',
      options: [],
      searchField: 'company',
      showSetting: false
    },
    {
      title: 'GSKV',
      dataIndex: 'GSKV',
      key: 'GSKV'
    },
    {
      title: 'Nguồn',
      dataIndex: 'source',
      key: 'source',
      search: true,
      inputType: 'select',
      options: [],
      searchField: 'source'
    },
    {
      title: 'Link nguồn',
      dataIndex: 'sourceLink',
      key: 'sourceLink',
      render: (_, record) => (
        <>
          <Link style={{ textDecoration: 'underline' }}>{record.sourceLink}</Link>
        </>
      )
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <ActivisionColumn status={record.status} />
        </Flex>
      ),
      align: 'center',
      width: 250,
      search: true,
      inputType: 'select',
      options: [],
      searchField: 'source'
    },
    {
      title: 'Từ ngày',
      dataIndex: 'fromDate',
      key: 'fromDate',
      search: true,
      inputType: 'date',
      searchField: 'fromDate',
      showSetting: false
    },
    {
      title: 'Đến ngày',
      dataIndex: 'toDate',
      key: 'toDate',
      search: true,
      inputType: 'date',
      searchField: 'toDate',
      showSetting: false
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`chi-tiet/${record.id}`}>Edit</Link>
        </Space>
      ),
      fixed: 'right',
      width: 100,
      align: 'center'
    }
  ]

  const fetchData = () => {
    const data = [
      {
        id: 0,
        date: '15/06/2024',
        customerInfo: 'Trịnh Mỹ Kim_NV',
        construction: 'CT_Leme',
        GSKV: 'John Brown',
        source: 'Phiếu kiểm tra',
        sourceLink: '#447272',
        status: 'PROCESSING',
        result: []
      },
      {
        id: 1,
        date: '15/06/2024',
        customerInfo: 'Trịnh Mỹ Kim_NV',
        construction: 'CT_Skype',
        GSKV: 'John Brown',
        source: 'Phiếu kiểm tra',
        sourceLink: '#447272',
        status: 'SUCCESS',
        result: [
          { type: 'trainingResults', value: 'Đạt' },
          { type: 'executiveFeedback', value: 'Đạt' },
          { type: 'CSFeedback', value: 'Đạt' }
        ]
      },
      {
        id: 2,
        date: '15/06/2024',
        customerInfo: 'Trịnh Mỹ Kim_NV',
        construction: 'John Brown',
        GSKV: 'John Brown',
        source: 'Phiếu kiểm tra',
        sourceLink: '#447272',
        status: 'CANCEL',
        result: []
      }
    ]
    return { data }
  }

  const expandedRowRender = (record) =>
    record.result.length > 0 ? (
      <Flex vertical>
        <Text>
          Kết quả đào tạo: <Text strong>{record.result?.[0].value}</Text>
        </Text>
        <Text>
          Phản hồi của bộ phận điều hành: <Text strong>{record.result[1]?.value}</Text>
        </Text>
        <Text>
          Phản hồi của CS: <Text strong>{record.result[2]?.value}</Text>
        </Text>
      </Flex>
    ) : null

  const handleExport = () => {
    console.log('export')
  }

  return (
    <div>
      <ListPage
        expandedRowRender={expandedRowRender}
        exportFile={handleExport}
        searchPrimaryKey="codeOrLabel"
        request={fetchData}
        key={'check-quality'}
        columns={columns}
        rowExpandable={(record) => record.result && record.result.length > 0}
      />
    </div>
  )
}

export default CheckQuality
