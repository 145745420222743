import { UploadOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Divider, Flex, Form, Input, message, Row, Select, Typography, Upload } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useStaffBrief from '../../../hooks/system/useStaffBrief'
import { enumCustomServiceStatusSelectOptions } from '../../../constant/enumSelectOptions'
import { useMutation, useQuery } from '@tanstack/react-query'
import BaseService from '../../../api/base-service'
import { URL_QUALITY_CONTROL_SCHEDULE } from '../../../constant/constantURL'
import dayjs from 'dayjs'

const qcService = new BaseService(URL_QUALITY_CONTROL_SCHEDULE)
const token = JSON.parse(localStorage.getItem('accessToken'))

const QCCalenderDetail = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const constructionValue = Form.useWatch('constructionCode', form)
  const { id } = useParams()
  const [staffOptions, setStaffOptions] = useState([])
  const staffResult = useStaffBrief()

  const qcResult = useQuery({
    queryKey: [URL_QUALITY_CONTROL_SCHEDULE, id],
    queryFn: () => qcService.getById(id)
  })

  const mutation = useMutation({
    mutationFn: (values) => qcService.update(id, values),
    onSuccess: (result) => {
      if (result.code === 2000) {
        message.success(`${!id ? 'Lưu' : 'Cập nhật'} thành công!`)
        !id ? handleClose() : null
        qcService.refetch()
      } else {
        message.error(`${!id ? 'Lưu' : 'Cập nhật'} thất bại (Lỗi: ${result.message})`)
      }
    },
    onError: (error) => {
      message.error(`Gửi yêu cầu ${!id ? 'lưu' : 'cập nhật'} thất bại (Lỗi: ${error.message})`)
    }
  })

  const handleClose = () => {
    navigate('/dich-vu-khach-hang/lich-qc')
  }

  const handleFinish = (values) => {
    const payload = { ...values }
    mutation.mutate(payload)
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  useEffect(() => {
    form.setFieldValue('id', id)
  }, [form, id])

  useEffect(() => {
    if (qcResult.data?.data) {
      form.setFieldsValue(qcResult.data.data)
      form.setFieldValue('qualityControlDate', dayjs(qcResult.data.data.qualityControlDate ?? undefined, 'DD/MM/YYYY'))
      form.setFieldValue('recentTrainingDate', dayjs(qcResult.data.data.recentTrainingDate ?? undefined, 'DD/MM/YYYY'))
      form.validateFields()
    }
  }, [qcResult.data, form])

  // useEffect(() => {
  //   constructionValue == '' || constructionValue === undefined
  //     ? null
  //     : form.setFieldValue('qualityControlDate', dayjs(new Date()))
  // }, [form, constructionValue])

  useEffect(() => {
    if (staffResult.data?.data) {
      setStaffOptions(
        staffResult.data.data.map((item) => {
          return { ...item, value: item?.id, label: item?.code }
        })
      )
    }
  }, [staffResult.data])

  return (
    <Form form={form} name="qc-calendar" layout="vertical">
      <Row>
        <Col span={4}>
          <Typography.Title level={5}>Thông tin QC</Typography.Title>
        </Col>
        <Col span={20}>
          <Row gutter={[15, 0]}>
            <Col span={8}>
              <Form.Item name={'id'} label={'ID'}>
                <Input disabled={!!id} placeholder="ID" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name={'qualityControlDate'}
                label={'Ngày'}
                rules={[{ required: true, message: 'Vui lòng chọn ngày.' }]}
              >
                <DatePicker placeholder="Chọn ngày" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'timeOfQcDay'} label={'Thời gian'}>
                <Select
                  allowClear
                  placeholder="Chọn"
                  options={[
                    { value: 'MORNING', label: 'Buổi sáng' },
                    { value: 'AFTERNOON', label: 'Buổi chiều' },
                    { value: 'BUSINESS_HOURS', label: 'Giờ hành chính' }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'customerInfo'} label={'Thông tin khách hàng'}>
                <Input placeholder="Tên khách hàng" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'email'} label={'Email'}>
                <Input type="email" placeholder="Nhập địa chỉ email" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'phoneNumber'} label={'SDT'}>
                <Input placeholder="Nhập số điện thoại" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name={'constructionCode'}
                label={'Công trình'}
                rules={[
                  { required: true, message: 'Vui lòng nhập tên công trình.' },
                  { whitespace: true, message: 'Vui lòng không chỉ nhập khoảng trắng' }
                ]}
              >
                <Input placeholder="Nhập tên công trình" />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item name={'company'} label={'Công ty'}>
                <Input placeholder="Nhập tên công ty" />
              </Form.Item>
            </Col> */}
            <Col span={8}>
              <Form.Item name={'staffQualityControlCode'} label={'Nhân viên QC'}>
                <Select placeholder="Chọn nhân viên" options={staffOptions} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'areaSupervisorCode'} label={'Giám sát khu vực'}>
                <Select placeholder="Chọn giám sát" options={staffOptions} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'complainCode'} label={'Phiếu khiếu nại'}>
                <Select placeholder="Chọn phiếu" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'reason'} label={'Lý do'}>
                <Select placeholder="Chọn lý do" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'recentTrainingDate'} label={'Ngày QC gần nhất'}>
                <DatePicker disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'scheduleStatus'} label={'Trạng thái'}>
                <Select placeholder="Chọn trạng thái" options={enumCustomServiceStatusSelectOptions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name={'note'} label={'Ghi chú'}>
                <Input.TextArea autoSize={{ minRows: 2 }} placeholder="Ghi chú" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={4}>
          <Typography.Title level={5}>Thông tin kết quả</Typography.Title>
        </Col>
        <Col span={20}>
          <Row>
            <Col span={24}>
              <Form.Item name={'qcResult'} label={'Kết quả QC'}>
                <Input.TextArea autoSize placeholder="Nhập nội dung kết quả" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item hidden name={'staffUpdatedExecutiveFeedback'}></Form.Item>
              <Form.Item hidden name={'executiveFeedbackLastUpdated'}></Form.Item>
              <Form.Item name={'executiveFeedback'}>
                <Flex justify="space-between" style={{ marginBottom: 10 }}>
                  <Typography.Text>Phản hồi của bộ phận điều hành</Typography.Text>
                  {id ? (
                    <Typography.Text>
                      NV {form.getFieldValue('staffUpdatedExecutiveFeedback')} -{' '}
                      {form.getFieldValue('executiveFeedbackLastUpdated')}
                    </Typography.Text>
                  ) : null}
                </Flex>
                <Input.TextArea autoSize placeholder="Nhập nội dung phản hồi" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item hidden name={'staffUpdatedCsFeedBack'}></Form.Item>
              <Form.Item hidden name={'csFeedbackLastUpdated'}></Form.Item>
              <Form.Item name={'csFeedback'}>
                <Flex justify="space-between">
                  <Typography.Text>Phản hồi của CS</Typography.Text>
                  {id ? (
                    <Typography.Text>
                      NV {form.getFieldValue('staffUpdatedCsFeedBack')} - {form.getFieldValue('csFeedbackLastUpdated')}
                    </Typography.Text>
                  ) : null}
                </Flex>
                <Input.TextArea autoSize placeholder="Nhập nội dung phản hồi" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'beforeImages'} label={'Trước QC'}>
                <Upload
                  action={`https://api-care-erp.dev.tsp.com.vn/core/upload/before-qc-result/${id}`}
                  headers={`Bearer ${token}`}
                  method="post"
                  beforeUpload={() => true}
                >
                  <Button icon={<UploadOutlined />}>Chọn hình</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'afterImages'} label={'Sau QC'}>
                <Upload>
                  <Button icon={<UploadOutlined />}>Chọn hình</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Flex gap={10} justify="right" style={{ width: '100%' }}>
          <Button>Hủy lịch</Button>
          <Button>Hoàn thành</Button>
          <Button onClick={handleClose}>Hủy</Button>
          <Button type="primary" htmlType="submit">
            {id ? 'Tạo' : 'Cập nhật'}
          </Button>
        </Flex>
      </Row>
    </Form>
  )
}

export default QCCalenderDetail
