export const ReasonValue = Object.freeze({
  COMPLAINT: Symbol('COMPLAINT'),
  INQUIRY: Symbol('INQUIRY'),
  FEEDBACK: Symbol('FEEDBACK'),
  SUPPORT: Symbol('SUPPORT'),
  SERVICE: Symbol('SERVICE'),
  OTHER: Symbol('OTHER')
})

export default ReasonValue
