import { Dropdown, Flex, Space } from 'antd'
import SVGIcons from '../../assets/icons/svg-icons'
import { DownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const ActionHeader = () => {
  const navigate = useNavigate()
  const items = [
    {
      label: (
        <div
          onClick={() => {
            localStorage.clear()
            navigate('/dang-nhap')
          }}
        >
          Logout
        </div>
      ),
      key: '0'
    }
  ]
  return (
    <Flex gap={12} align="center" style={{ paddingRight: 24 }}>
      <SVGIcons.Clock key="Clock" />
      <SVGIcons.Notification key="Notification" />
      <Dropdown
        menu={{
          items
        }}
        trigger={['click']}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            admin
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </Flex>
  )
}

export default ActionHeader
