import { useEffect, useRef, useState } from 'react'
import ListPage from '../../../components/list-page'
import { Button, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import MailComplainModal from './components/MailComplainModal'
import dayjs from 'dayjs'
import useConstructionsBriefs from '../../../hooks/system/useConstructionsBrief'
import useCompaniesBrief from '../../../hooks/system/useCompaniesBrief'

const FakeData = [
  {
    id: 420,
    time: dayjs(),
    team: 'ERB',
    emailSend: 'nice.peter@epic.rap',
    emailReceived: 'epic.loyld@epic.rap',
    sender: 'Peter Loyld',
    construction: 'Epic Rap Battle',
    company: 'Not Disney',
    title: 'ERB of History',
    complain: null
  },
  {
    id: 13,
    time: Date(),
    team: 'ERB2',
    emailSend: 'jack.swedish@epic.rap',
    emailReceived: 'epic.loyld@epic.rap',
    sender: 'Jack Ghost',
    construction: 'Epic Rap Battle',
    company: 'Still Not Disney',
    title: 'ERB of Culture',
    complain: '#123456'
  }
]

const MailList = () => {
  const navigate = useNavigate()
  const listPageRef = useRef()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [constructionOptions, setConstructionOptions] = useState([])
  const [companyOptions, setCompanyOptions] = useState([])
  const constructionResult = useConstructionsBriefs()
  const companyResult = useCompaniesBrief()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 185,
      inputType: 'text',
      hidden: true
    },
    {
      title: 'Thời gian',
      dataIndex: 'time',
      key: 'time',
      width: 185,
      search: true,
      inputType: 'dateRange',
      render: (_, record) => <Typography.Text>{dayjs(record?.time).format('DD/MM/YYYY hh:mm')}</Typography.Text>
    },
    {
      title: 'Nhóm',
      dataIndex: 'team',
      key: 'team',
      width: 165,
      search: true,
      inputType: 'select',
      defaultValue: null,
      options: [{ value: null, label: 'Tất cả' }]
    },
    {
      title: 'Mail gửi',
      dataIndex: 'emailSend',
      key: 'emailSend',
      width: 300,
      search: true,
      inputType: 'text'
    },
    {
      title: 'Mail nhận',
      dataIndex: 'emailReceived',
      key: 'emailReceived',
      width: 300,
      showSetting: false,
      search: true,
      inputType: 'text'
    },
    {
      title: 'Người gửi',
      dataIndex: 'sender',
      key: 'sender',
      width: 165
    },
    {
      title: 'Công trình',
      dataIndex: 'construction',
      key: 'construction',
      width: 165,
      search: true,
      inputType: 'select',
      options: constructionOptions
    },
    {
      title: 'Công ty',
      dataIndex: 'company',
      key: 'company',
      width: 165,
      search: true,
      inputType: 'select',
      options: companyOptions
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title',
      width: 250,
      search: true,
      inputType: 'text'
    },
    {
      title: 'Nội dung mail',
      dataIndex: 'mailContent',
      key: 'mailContent',
      width: 300,
      ellipsis: true,
      showSetting: false,
      search: true,
      inputType: 'text'
    },
    {
      title: 'Nội dung khiếu nại',
      dataIndex: 'complaintContent',
      key: 'complaintContent',
      width: 300,
      ellipsis: true,
      showSetting: false,
      search: true,
      inputType: 'text'
    },
    {
      title: 'Khiếu nại',
      dataIndex: 'complain',
      key: 'complain',
      width: 100,
      align: 'center',
      search: true,
      inputType: 'select',
      defaultValue: null,
      options: [
        { value: null, label: 'Tất cả' },
        { value: false, label: 'Chưa chuyển' },
        { value: true, label: 'Đã chuyển' }
      ],
      render: (_, record) =>
        !record.complain ? (
          <Button size="small" type="primary" onClick={() => handleOpenModal(record?.id)}>
            Chuyển
          </Button>
        ) : (
          <a onClick={() => handleMoveToComplain(record?.id)}>{record.complain}</a>
        )
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 75,
      align: 'center',
      render: (_, record) => (
        <Button size="small" type="text" onClick={() => handleEdit(record?.id)}>
          Edit
        </Button>
      )
    }
  ]

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const handleRefetch = () => {
    listPageRef.current?.fetch()
    setIsModalOpen(false)
  }

  const handleOpenModal = (id) => {
    setCurrentId(id)
    setIsModalOpen(true)
  }

  const handleCreate = () => {
    navigate('them-moi')
  }

  const handleEdit = (id) => {
    navigate(`chi-tiet/${id}`)
  }

  const handleMoveToComplain = (id) => {
    navigate(`/dich-vu-khach-hang/khieu-nai/${id}`)
  }

  useEffect(() => {
    if (constructionResult.data?.data) {
      setConstructionOptions(
        constructionResult.data.data.map((construction) => {
          return { value: construction?.id, label: construction?.name }
        })
      )
    }
  }, [constructionResult.data])

  useEffect(() => {
    if (companyResult.data?.data) {
      setCompanyOptions(
        companyResult.data.data.map((company) => {
          return { value: company?.id, label: company?.name }
        })
      )
    }
  }, [companyResult.data])

  return (
    <>
      <ListPage
        ref={listPageRef}
        key={'mail-list'}
        columns={columns}
        tableProps={{ dataSource: FakeData }}
        onCreate={handleCreate}
      />
      {isModalOpen && (
        <MailComplainModal
          id={currentId}
          isModalOpen={isModalOpen}
          handleClose={handleClose}
          handleRefetch={handleRefetch}
        />
      )}
    </>
  )
}

export default MailList
