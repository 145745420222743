import { Flex, Space } from 'antd'
import { Link } from 'react-router-dom'
import ListPage from '../../../components/list-page'
import ActivisionColumn from '../../../components/list-page/ActivisionColumn'
import { Typography } from 'antd'
import { useState } from 'react'
import ModalAddTraining from './ModalAddTraining'
const { Text } = Typography

const Training = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      search: true
    },
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Công ty',
      dataIndex: 'company',
      key: 'company',
      hidden: true,
      search: true,
      inputType: 'select',
      searchField: 'company'
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'name',
      key: 'name',
      hidden: true,
      search: true,
      inputType: 'text',
      searchField: 'name'
    },
    {
      title: 'Thời gian',
      dataIndex: 'time',
      key: 'time'
    },
    {
      title: 'Thông tin khách hàng',
      dataIndex: 'customerInfo',
      key: 'customerInfo',
      search: true
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
      showSetting: false
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      showSetting: false
    },
    {
      title: 'Công trình',
      dataIndex: 'construction',
      key: 'construction',
      search: true,
      hidden: true,
      inputType: 'select',
      searchField: 'construction'
    },
    {
      title: 'GSKV',
      dataIndex: 'GSKV',
      key: 'GSKV',
      search: true,
      hidden: true,
      inputType: 'select',
      searchField: 'GSKV'
    },
    {
      title: 'Từ ngày',
      dataIndex: 'fromDay',
      key: 'fromDay',
      search: true,
      hidden: true,
      inputType: 'date',
      searchField: 'fromDay'
    },
    {
      title: 'Đến ngày',
      dataIndex: 'toDay',
      key: 'toDay',
      search: true,
      hidden: true,
      inputType: 'date',
      searchField: 'toDay'
    },
    {
      title: 'NV đào tạo',
      dataIndex: 'trainingStaff',
      key: 'trainingStaff',
      search: true
    },
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason'
    },
    {
      title: 'Ngày đào tạo gần nhất',
      dataIndex: 'lastTrainingDate',
      key: 'lastTrainingDate'
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_, record) => (
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <ActivisionColumn status={record.status} />
        </Flex>
      ),
      search: true,
      inputType: 'select',
      searchField: 'status'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`chi-tiet/${record.id}`}>Edit</Link>
        </Space>
      ),
      fixed: 'right',
      align: 'center'
    }
  ]

  const fetchData = () => {
    const data = [
      {
        id: 1,
        date: '15/06/2024',
        time: 'Sáng',
        customerInfo: 'Trịnh Mỹ Kim_NV',
        phoneNumber: '0909333444',
        email: 'Sasuketamin.Hokage@Gmail.com',
        construction: 'CT_Leme',
        GSKV: 'CT_Legend',
        trainingStaff: 'FM08124',
        reason: 'R1',
        lastTrainingDate: '15/06/2024',
        note: 'John Brown......',
        status: 'PROCESSING',
        result: []
      },
      {
        id: 'B51',
        date: '15/06/2024',
        time: 'Chiều',
        customerInfo: 'Trịnh Mỹ Kim_NV',
        phoneNumber: '0909333444',
        email: 'Sasuketamin.Hokage@Gmail.com',
        construction: 'CT_Leme',
        GSKV: 'CT_Legend',
        trainingStaff: 'FM08124',
        reason: 'R2',
        lastTrainingDate: '15/06/2024',
        note: 'John Brown......',
        status: 'SUCCESS',
        result: [
          { type: 'trainingResults', value: 'Đạt' },
          { type: 'executiveFeedback', value: 'Đạt' },
          { type: 'CSFeedback', value: 'Đạt' }
        ]
      },
      {
        id: 'B53',
        date: '15/06/2024',
        time: 'Hành chính',
        customerInfo: 'Trịnh Mỹ Kim_NV',
        phoneNumber: '0909333444',
        email: 'Sasuketamin.Hokage@Gmail.com',
        construction: 'CT_Leme',
        GSKV: 'CT_Legend',
        trainingStaff: 'FM08124',
        reason: 'R3',
        lastTrainingDate: '15/06/2024',
        note: 'John Brown......',
        status: 'CANCEL',
        result: [
          { type: 'trainingResults', value: 'Chưa đạt' },
          { type: 'executiveFeedback', value: 'Chưa đạt' },
          { type: 'CSFeedback', value: 'Chưa đạt' }
        ]
      }
    ]
    return { data }
  }

  const expandedRowRender = (record) =>
    record.result.length > 0 ? (
      <Flex vertical>
        <Text>
          Kết quả đào tạo: <Text strong>{record.result?.[0].value}</Text>
        </Text>
        <Text>
          Phản hồi của bộ phận điều hành: <Text strong>{record.result[1]?.value}</Text>
        </Text>
        <Text>
          Phản hồi của CS: <Text strong>{record.result[2]?.value}</Text>
        </Text>
      </Flex>
    ) : null

  const handleExport = () => {
    console.log('Đã export')
  }

  return (
    <>
      <ListPage
        expandedRowRender={expandedRowRender}
        exportFile={handleExport}
        request={fetchData}
        key={'training'}
        columns={columns}
        onCreate={showModal}
        rowExpandable={(record) => record.result && record.result.length > 0}
      />
      {isModalOpen && (
        <ModalAddTraining
          handleOk={handleOk}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}

export default Training
