import { LogoutOutlined } from '@ant-design/icons'

import { PageContainer, ProCard, ProLayout } from '@ant-design/pro-components'
import { Dropdown } from 'antd'

import { Outlet, useNavigate } from 'react-router-dom'
import logo from './../../assets/imgs/svg/logo.svg'
import ActionHeader from './ActionHeader'
import Navbar from './Navbar'
import useVerifyUser from '../../hooks/useVerifyUser'

const Layout = () => {
  const navigate = useNavigate()
  const { verifiedUser } = useVerifyUser()
  if (!verifiedUser) return <></>

  return (
    <div
      id="test-pro-layout"
      style={{
        overflow: 'auto'
      }}
    >
      <ProLayout
        logo={logo}
        title={''}
        actionsRender={(props) => {
          if (props.isMobile) return []
          if (typeof window === 'undefined') return []
          return <ActionHeader />
        }}
        headerTitleRender={(logo, title, _) => {
          const defaultDom = (
            <div style={{ paddingLeft: 8 }}>
              {logo}
              {title}
            </div>
          )
          if (typeof window === 'undefined') return defaultDom
          if (document.body.clientWidth < 1400) {
            return defaultDom
          }
          if (_.isMobile) return defaultDom
          return <>{defaultDom}</>
        }}
        fixSiderbar="true"
        layout="mix"
        splitMenus="true"
      >
        <Navbar />
        <PageContainer
          token={{
            paddingInlinePageContainerContent: 24,
            paddingBlockPageContainerContent: 24
          }}
        >
          <ProCard
            style={{
              minHeight: 'calc(100vh - 156px)',
              marginTop: '60px'
            }}
          >
            <Outlet />
          </ProCard>
        </PageContainer>
      </ProLayout>
    </div>
  )
}
export default Layout
