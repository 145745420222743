import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Flex, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import ActivisionColumn from '../../../components/list-page/ActivisionColumn'
import ListPage from '../../../components/list-page'
import QCCalendarModal from './components/QCCalendarModal'
import useConstructionsBriefs from '../../../hooks/system/useConstructionsBrief'
import useCompaniesBrief from '../../../hooks/system/useCompaniesBrief'
import BaseService from '../../../api/base-service'
import { URL_QUALITY_CONTROL_SCHEDULE } from '../../../constant/constantURL'
import TimeColumn from '../../../components/list-page/TimeColumn'

const FakeData = [
  {
    key: 1,
    id: 20,
    phoneNumber: '0366764749',
    email: 'longlongmaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaan@llm.jp',
    status: 'PROCESSING',
    qcResult: 'Good Job'
  },
  {
    key: 20,
    id: 13,
    phoneNumber: '0366764749',
    email: 'longlongmaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaan@llm.jp',
    status: 'COMPLETE',
    qcResult: 'Donkey'
  },
  {
    key: 13,
    id: 69,
    phoneNumber: '0366764749',
    email: 'longlongmaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaan@llm.jp',
    status: 'CANCEL'
  }
]

const qcService = new BaseService(URL_QUALITY_CONTROL_SCHEDULE)

const QCCalenderList = () => {
  let navigate = useNavigate()
  const listPageRef = useRef()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [constructionOptions, setConstructionOptions] = useState([])
  const [companyOptions, setCompanyOptions] = useState([])
  const constructionResult = useConstructionsBriefs()
  const companyResult = useCompaniesBrief()

  const expandPanel = (record) => (
    <Flex vertical direction="vertical" style={{ width: '100%' }}>
      <Space>
        <Typography.Text>Kết quả QC:</Typography.Text>
        <Typography.Text strong>{record.qcResult}</Typography.Text>
      </Space>
      <Space>
        <Typography.Text>Phản hồi của bộ phận điều hành:</Typography.Text>
        <Typography.Text strong>{record.executiveFeedback}</Typography.Text>
      </Space>
      <Space>
        <Typography.Text>Phản hồi của CS:</Typography.Text>
        <Typography.Text strong>{record.csFeedback}</Typography.Text>
      </Space>
    </Flex>
  )

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 65,
      hidden: true,
      search: true
    },
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      render: (_, record) => <Typography.Text>{dayjs(record?.date)?.format('DD/MM/YYYY')}</Typography.Text>
    },
    {
      title: 'Thời gian',
      dataIndex: 'timeOfQcDay',
      key: 'timeOfQcDay',
      width: 150,
      render: (_, record) => <TimeColumn time={record.timeOfQcDay} />
    },
    {
      title: 'Thông tin khách hàng',
      dataIndex: 'customerInfo',
      key: 'customerInfo',
      width: 250
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 120
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 300,
      ellipsis: true
    },
    {
      title: 'Công trình',
      dataIndex: 'constructionCode',
      key: 'constructionCode',
      width: 150,
      search: true,
      inputType: 'select',
      options: constructionOptions
    },
    {
      title: 'Công ty',
      dataIndex: 'company',
      key: 'company',
      width: 150,
      search: true,
      inputType: 'select',
      showSetting: false,
      options: companyOptions
    },
    {
      title: 'GSKV',
      dataIndex: 'areaSupervisorCode',
      key: 'areaSupervisorCode',
      width: 150,
      search: true,
      inputType: 'select'
    },
    {
      title: 'NV QC',
      dataIndex: 'staffQualityControlCode',
      key: 'staffQualityControlCode',
      width: 150,
      search: true,
      inputType: 'select'
    },
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason',
      width: 250
    },
    {
      title: 'Ngày QC gần nhất',
      dataIndex: 'date',
      key: 'date',
      width: 150,
      render: (_, record) => <Typography.Text>{dayjs(record?.date)?.format('DD/MM/YYYY')}</Typography.Text>
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      width: 150
    },
    {
      title: 'Trạng thái',
      align: 'center',
      dataIndex: 'scheduleStatus',
      key: 'scheduleStatus',
      width: 150,
      render: (_, record) => <ActivisionColumn status={record?.scheduleStatus} />,
      search: true,
      inputType: 'select',
      searchField: 'scheduleStatus',
      options: [
        { value: 'COMPLETED', label: 'Hoàn thành' },
        { value: 'CANCELED', label: 'Hủy' },
        { value: 'PROCESSING', label: 'Đang xử lý' }
      ]
    },
    {
      title: 'Từ ngày',
      dataIndex: 'from',
      key: 'from',
      hidden: true,
      search: true,
      inputType: 'date'
    },
    {
      title: 'Đến',
      dataIndex: 'to',
      key: 'to',
      hidden: true,
      search: true,
      inputType: 'date'
    },
    {
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Button type="text" onClick={() => handleEdit(record.id)}>
          Edit
        </Button>
      ),
      fixed: 'right',
      width: 75
    }
  ]

  const handleRefetch = () => {
    listPageRef.current?.fetch()
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCreate = () => {
    handleOpenModal()
  }

  const handleEdit = (id) => {
    navigate(`chi-tiet/${id}`)
  }

  useEffect(() => {
    if (constructionResult.data?.data) {
      setConstructionOptions(
        constructionResult.data.data.map((construction) => {
          return { value: construction?.id, label: construction?.name }
        })
      )
    }
  }, [constructionResult.data])

  useEffect(() => {
    if (companyResult.data?.data) {
      setCompanyOptions(
        companyResult.data.data.map((company) => {
          return { value: company?.id, label: company?.name }
        })
      )
    }
  }, [companyResult.data])

  return (
    <div>
      <ListPage
        ref={listPageRef}
        key={'account'}
        request={async (params) => qcService.get(params)}
        columns={columns}
        onCreate={handleCreate}
        tableProps={{
          expandable: {
            expandedRowRender: expandPanel,
            rowExpandable: (record) => !!record?.qcResult
          }
        }}
      />
      {isModalOpen && (
        <QCCalendarModal isModalOpen={isModalOpen} handleClose={handleCloseModal} handleRefetch={handleRefetch} />
      )}
    </div>
  )
}

export default QCCalenderList
