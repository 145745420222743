import { configureStore } from '@reduxjs/toolkit'

// Reducer function
const rootReducer = (state, action) => {
  switch (action.type) {
  // Define reducer logic here
  default:
    return state
  }
}

// Create store
const store = configureStore({
  reducer: rootReducer
})

export default store
