import { Modal, Table } from 'antd'

const ListPersonnel = ({ isModalOpen, onCancel, listPersonnel }) => {
  const columns = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'name',
      key: 'name'
    }
  ]

  return (
    <Modal title="Danh sách nhân viên" open={isModalOpen} onCancel={onCancel} footer={null}>
      <Table columns={columns} dataSource={listPersonnel} pagination={false} />
    </Modal>
  )
}

export default ListPersonnel
