import { Button, Checkbox, Col, Dropdown, Flex, Form, Input, Pagination, Popover, Row, Select, Table } from 'antd'
import { isEmpty } from 'lodash'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import SVGIcons from '../../assets/icons/svg-icons'
import useStateLocalStorage from '../../hooks/useStateLocalStorage'
import FormItemFilter from './FormItemFilter'

const defaultPaging = {
  page: 0,
  size: 10
}

const ListPage = forwardRef(
  (
    {
      rowExpandable,
      expandedRowRender,
      name,
      searchPrimaryKey = 'codeOrName',
      columns,
      request,
      labeBtnCreate = 'Tạo mới',
      onCreate,
      refresh = false,
      showSettingContent = true,
      showRefreshButton = true,
      renderAction = () => <></>,
      params,
      rightButton = () => <></>,
      onDataChange,
      onFilterChange,
      tableProps,
      defaultData,
      titleReport = () => <></>,
      customParams,
      refreshData,
      showPagination = true,
      exportFile = false
    },
    ref
  ) => {
    const [data, setData] = useState([])
    const [formMain] = Form.useForm()
    const [form] = Form.useForm()
    const formRef = useRef()
    const [loading, setLoading] = useState(false)
    const [formMainData, setFormMainData] = useState()
    const [formFilterData, setFormFilterData] = useState()
    const [openFilter, setOpenFilter] = useState(false)
    const [pagination, setPagination] = useState({ ...defaultPaging })
    const filterColumns = columns.filter((item) => item.search)
    const filterMainColumns = columns.filter((item) => item.mainSearch)
    const activeColumn = columns.filter((item) => item.hidden !== true)
    const [total, setTotal] = useState(0)

    const [setting, setSetting] = useStateLocalStorage(
      activeColumn.filter((item) => item.showSetting !== false).map((item) => item.dataIndex || item.key),
      `${name}-setting`
    )

    useEffect(() => {
      fetchData()
    }, [pagination, refresh, formMainData, params])

    const fetchData = async (filters = {}) => {
      if (!request) return
      let queryParams = {
        ...params,
        ...pagination,
        ...formFilterData,
        ...formMainData,
        ...filters
      }

      if (customParams) queryParams = await customParams(queryParams)
      setLoading(true)
      try {
        const res = await request(queryParams)
        setLoading(false)
        if (res?.data) {
          const result = res.data.map((item) => ({ ...item, key: item.id }))
          setData(result)
          if (onDataChange) onDataChange(result)
          setTotal(res.totalElements)
        }
      } catch (err) {
        setLoading(false)
      }
    }

    const handleSubmitFilter = (values) => {
      if (onFilterChange) onFilterChange({ ...form.getFieldsError(), ...values })
      setFormFilterData(values)
      setPagination({ ...defaultPaging })
      setOpenFilter(false)
    }
    const handleFormMainChange = (changedValues, allValues) => {
      if (onFilterChange) onFilterChange({ ...openFilter, ...allValues })
      setFormMainData(allValues)
    }

    useImperativeHandle(ref, () => ({
      fetch() {
        handleRefresh()
      }
    }))

    const handleRefresh = () => {
      if (refreshData) return refreshData()
      if (!!filterColumns.length && !isEmpty(form.getFieldsValue())) form.submit()
      else fetchData()
    }

    const items = [
      {
        label: (
          <Flex justify="center" align="center" gap={8}>
            <SVGIcons.IconExcel />
            Xuất file Excel
          </Flex>
        ),
        key: '0'
      },
      {
        label: (
          <Flex justify="center" align="center" gap={8}>
            <SVGIcons.IconPDF />
            Xuất file PDF
          </Flex>
        ),
        key: '1'
      }
    ]

    const settingContent = (
      <div style={{ padding: '0 12px' }}>
        <div
          style={{
            paddingBottom: 8,
            borderBottom: 'solid 1px #C7D2E3',
            fontWeight: 400,
            color: '#0F1E34'
          }}
        >
          {setting.length} / {activeColumn.length} được hiển thị
        </div>
        <div style={{ padding: '16px 0' }}>
          <Checkbox.Group
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16
            }}
            value={setting}
            onChange={(values) => {
              setSetting(values)
            }}
            options={activeColumn.map((item) => ({
              value: item.dataIndex || item.key,
              label: <div style={{ fontWeight: 400, color: '#475467' }}>{item.configTitle || item.title}</div>
            }))}
          />
        </div>
        <Flex
          justify="center"
          style={{
            paddingTop: 10,
            borderTop: 'solid 1px #C7D2E3'
          }}
        >
          <Button
            type="text"
            style={{ color: '#4479C5' }}
            onClick={() => {
              setSetting(activeColumn.map((item) => item.dataIndex))
            }}
          >
            Đặt lại
          </Button>
        </Flex>
      </div>
    )

    const filterContent = (
      <>
        <Form
          form={form}
          ref={formRef}
          layout="vertical"
          autoComplete="off"
          size="middle"
          name={name}
          initialValues={{ ...params }}
          onFinish={handleSubmitFilter}
          style={{ maxWidth: 600, padding: 12 }}
        >
          <Row gutter={[16, 16]}>
            {filterColumns.map((item, index) => (
              <Col span={12} key={index}>
                <Form.Item name={item.searchField || item.dataIndex || item.key} label={item.title}>
                  <FormItemFilter {...item} />
                </Form.Item>
              </Col>
            ))}
          </Row>

          <Flex justify="end" style={{ marginTop: 16 }}>
            <Flex gap={24}>
              <Button
                size="middle"
                onClick={() => {
                  setOpenFilter(false)
                  form.resetFields()
                  form.submit()
                }}
              >
                Đặt Lại
              </Button>
              <Button type="primary" size="middle" htmlType="submit">
                Áp Dụng Bộ Lọc
              </Button>
            </Flex>
          </Flex>
        </Form>
      </>
    )

    return (
      <>
        <Flex justify="space-between">
          <Flex>
            <Form
              form={formMain}
              name={`${name}-main`}
              initialValues={{ ...params }}
              onValuesChange={handleFormMainChange}
            >
              <Flex gap={16} flex={{ flexDirection: 'row' }}>
                {searchPrimaryKey ? (
                  <Form.Item name={searchPrimaryKey}>
                    <Input
                      prefix={<SVGIcons.SearchInput />}
                      style={{ minWidth: 266 }}
                      className="search-input"
                      placeholder="Tìm kiếm nhanh"
                      allowClear
                    />
                  </Form.Item>
                ) : (
                  <div></div>
                )}
                {filterMainColumns.map((item, index) => (
                  <Form.Item key={index} name={item.searchField || item.dataIndex || item.key}>
                    <FormItemFilter {...item} size="medium" />
                  </Form.Item>
                ))}

                {titleReport()}
              </Flex>
            </Form>
          </Flex>
          <Flex gap={16}>
            {showRefreshButton && <Button icon={<SVGIcons.RefreshButton />} onClick={handleRefresh} />}
            {!!filterColumns.length && (
              <Popover
                content={filterContent}
                trigger="click"
                open={openFilter}
                placement="bottomLeft"
                onOpenChange={(open) => setOpenFilter(open)}
                style={{ width: 400 }}
              >
                <Button>
                  <Flex align="center" gap={8}>
                    <SVGIcons.FilterButton />
                    Bộ lọc
                  </Flex>{' '}
                </Button>
              </Popover>
            )}
            {exportFile && (
              <Popover trigger="click" placement="bottomLeft" style={{ width: 400 }}>
                <Button>
                  <Flex align="center" gap={8}>
                    <SVGIcons.ExportFile />
                    Xuất file
                  </Flex>
                </Button>
              </Popover>
            )}
            {showSettingContent && (
              <Popover content={settingContent} trigger="click">
                <Button>
                  <Flex align="center" gap={8}>
                    <SVGIcons.SettingButton />
                    Cột
                  </Flex>
                </Button>
              </Popover>
            )}
            {rightButton()}
            {onCreate && (
              <Button onClick={onCreate} type="primary">
                <Flex align="center" gap={8}>
                  <SVGIcons.AddButton />
                  {labeBtnCreate}
                </Flex>
              </Button>
            )}
          </Flex>
        </Flex>

        <Table
          className="custom-table"
          dataSource={defaultData || data}
          columns={columns
            .filter((item) => setting.includes(item.dataIndex || item.key))
            .map((item) => ({ ...item, ellipsis: true }))}
          pagination={false}
          scroll={{ x: 1000, y: '70vh' }}
          loading={loading}
          size="small"
          bordered
          {...tableProps}
          expandedRowRender={expandedRowRender}
          rowExpandable={rowExpandable}
        />
        <Flex justify="space-between" style={{ marginTop: 20, marginBottom: 16 }}>
          <div>{renderAction()}</div>
          {showPagination && (
            <Pagination
              className="table-pagination"
              total={total || 1}
              showLessItems
              locale={{
                items_per_page: 'Dòng'
              }}
              simple
              showSizeChanger
              selectComponentClass={Select}
              pageSize={pagination.size}
              itemRender={(page, type, el) => {
                if (type === 'next')
                  return (
                    <Button
                      icon={<SVGIcons.ChevronRightPagination />}
                      type="primary"
                      size="middle"
                      style={{ color: 'white' }}
                    />
                  )
                if (type === 'prev')
                  return (
                    <Button
                      icon={<SVGIcons.ChevronLeftPagination />}
                      size="middle"
                      type="primary"
                      style={{ color: 'white' }}
                    />
                  )
                return el
              }}
              onChange={(page, pageSize) => {
                setPagination({ page: page - 1, size: pageSize })
              }}
            />
          )}
        </Flex>
      </>
    )
  }
)

export default ListPage
