import { useQuery } from '@tanstack/react-query'
import { URL_ACCOUNT_ME } from '../../constant/constantURL'
import BaseService from '../../api/base-service'

const service = new BaseService(URL_ACCOUNT_ME)

const fetch = async () => {
  return await service.get()
}

const useAccountsMe = () => {
  return useQuery({
    queryKey: [URL_ACCOUNT_ME],
    queryFn: fetch
  })
}

export default useAccountsMe
