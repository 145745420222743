import { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Flex, Form, Input, InputNumber, message, Modal, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { generateTimeOptions } from '../../../../constant/constantOptionsTime'
import BaseService from '../../../../api/base-service'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AgGridReact } from 'ag-grid-react'
import { URL_WORKING_SHIFTS } from '../../../../constant/constantURL'
import { handleKeyPress } from '../../comon/functionComon'

const WorkingShiftsService = new BaseService(URL_WORKING_SHIFTS)
const ModalWorkingShifts = ({ id, handleRefresh, isModalOpen, handleCancel }) => {
  const initialData = [
    {
      header: 'Bắt đầu',
      id: 1,
      MONDAY: null,
      TUESDAY: null,
      WEDNESDAY: null,
      THURSDAY: null,
      FRIDAY: null,
      SATURDAY: null,
      SUNDAY: null
    },
    {
      header: 'Kết thúc',
      id: 2,
      MONDAY: null,
      TUESDAY: null,
      WEDNESDAY: null,
      THURSDAY: null,
      FRIDAY: null,
      SATURDAY: null,
      SUNDAY: null
    },
    {
      header: 'Giờ công',
      id: 3,
      MONDAY: null,
      TUESDAY: null,
      WEDNESDAY: null,
      THURSDAY: null,
      FRIDAY: null,
      SATURDAY: null,
      SUNDAY: null
    }
  ]
  const [totalHours, setTotalHours] = useState(0)
  const [rowData, setRowData] = useState(initialData)
  const options = generateTimeOptions()
  const [form] = Form.useForm()
  const gridRef = useRef(null)

  const { data, refetch } = useQuery({
    queryKey: [URL_WORKING_SHIFTS, id],
    queryFn: async () => {
      if (id) {
        const res = await WorkingShiftsService.getById(id)
        return res.data
      }
    },
    enabled: id !== null
  })

  const renderColumnDefs = (headerName, field) => {
    return {
      headerName: headerName,
      field: field,
      sortable: false,
      cellEditor: 'agNumberCellEditor',
      doubleClickEdit: false,
      cellRenderer: (params) => {
        if (params.data.header === 'Giờ công')
          return (
            <InputNumber
              min={0}
              onKeyPress={handleKeyPress}
              type="number"
              value={params.node.data[field]}
              onChange={(value) => {
                const newValue = {
                  ...params.node.data,
                  [field]: value
                }
                calculateTotalWorkHours(newValue)
                params.node.updateData(newValue)
              }}
              placeholder="Nhập giờ"
            />
          )
        return (
          <Select
            allowClear
            placeholder="Chọn giờ"
            showSearch
            style={{ width: '100%' }}
            value={params.node.data[field]}
            onChange={(value) => {
              params.node.updateData({
                ...params.node.data,
                [field]: value
              })
            }}
            options={options}
          />
        )
      }
    }
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Thời gian',
      field: 'header',
      cellStyle: { textAlign: 'left', padding: '0 16px' },
      sortable: false
    },
    { ...renderColumnDefs('Thứ 2', 'MONDAY') },
    { ...renderColumnDefs('Thứ 3', 'TUESDAY') },
    { ...renderColumnDefs('Thứ 4', 'WEDNESDAY') },
    { ...renderColumnDefs('Thứ 5', 'THURSDAY') },
    { ...renderColumnDefs('Thứ 6', 'FRIDAY') },
    { ...renderColumnDefs('Thứ 7', 'SATURDAY') },
    { ...renderColumnDefs('Chủ Nhật', 'SUNDAY') }
  ])

  const { mutate } = useMutation({
    mutationKey: !id ? ['working-shifts-add'] : ['working-shifts-edit'],
    mutationFn: async (data) => {
      return !id ? await WorkingShiftsService.create(data) : await WorkingShiftsService.update(id, data)
    },
    onSuccess: (results) => {
      if (results.code === 2000) {
        message.success(!id ? 'Thêm ca làm thành công' : 'Lưu ca làm thành công')
        handleCancel()
        handleRefresh()
      } else {
        message.error('Thao tác thất bại')
      }
    },
    onError: (error) => {
      message.error(`Gửi yêu cầu lưu thất bại (Lỗi: ${error.message}})`)
    }
  })

  const calculateTotalWorkHours = (values = {}) => {
    const totalHours = Object.entries(values)
      .slice(2)
      .reduce((total, item) => total + +item[1], 0)

    setTotalHours(totalHours)
  }

  const fillDataEdit = (arr) => {
    refetch()
    const updatedRowData = [...initialData]
    arr.forEach((item) => {
      updatedRowData[0][item.dayOfWeek] = item.startTime
      updatedRowData[1][item.dayOfWeek] = item.endTime
      updatedRowData[2][item.dayOfWeek] = item.workHours
    })

    setRowData(updatedRowData)
  }

  const handleClean = () => {
    form.resetFields()
    setRowData(initialData)
    setTotalHours(0)
  }

  useEffect(() => {
    if (isModalOpen && id && data) {
      form.setFieldsValue({
        name: data.name,
        notes: data.notes,
        isNightShift: data.isNightShift || false
      })
      setTotalHours(data.totalHours)
      fillDataEdit(data.shiftDetailRequestDtoList)
    } else if (isModalOpen && !id) {
      handleClean()
    }
  }, [data, isModalOpen])

  const handleSave = async (values) => {
    const list = []
    gridRef.current.api.forEachNode((item) => list.push(item))

    const listDataRow = list?.map((item) => item.data)

    const daysOfWeek = Object.entries(listDataRow[0])
      .slice(2)
      .map((item) => item[0])

    const shiftDetailRequestDtoList = daysOfWeek.map((day) => {
      const startTime = listDataRow[0][day]
      const endTime = listDataRow[1][day]
      const workHours = listDataRow[2][day]

      return {
        dayOfWeek: day,
        startTime: startTime || null,
        endTime: endTime || null,
        workHours: workHours
      }
    })

    const payload = {
      ...values,
      shiftDetailRequestDtoList,
      totalHours: +totalHours,
      status: !id ? 'ACTIVE' : data.status
    }

    mutate(payload)
  }

  const handleClose = () => {
    handleClean()
    handleCancel()
  }

  const getRowId = useCallback((params) => params.data.id, [])

  return (
    <div>
      <Modal
        width="900px"
        title={!id ? 'Thêm ca làm việc' : 'Chỉnh sửa ca làm việc'}
        open={isModalOpen}
        footer={null}
        onCancel={handleClose}
      >
        <Form onFinish={handleSave} form={form} layout="vertical" className="form-working-shifts">
          <Flex justify="space-between" align="end" gap={32}>
            <Form.Item
              label="Tên ca làm việc"
              name="name"
              rules={[{ whitespace: true, required: true, message: 'Vui lòng nhập tên ca làm việc' }]}
              style={{ flex: 1 }}
            >
              <Input placeholder="Tên ca" />
            </Form.Item>
            <Form.Item name="isNightShift" valuePropName="checked">
              <Checkbox>Ca đêm</Checkbox>
            </Form.Item>
          </Flex>
          <Form.Item label="Ghi chú" name="notes">
            <TextArea rows={1} placeholder="Ghi chú" />
          </Form.Item>
          <Form.Item label="Thời gian làm việc" name="totalHours" className="grid-working-hour">
            <div className="ag-theme-quartz">
              <AgGridReact
                ref={gridRef}
                style={{
                  marginTop: 0
                }}
                getRowId={getRowId}
                columnDefs={columnDefs}
                rowData={rowData}
                setRowData={setRowData}
                autoSizeStrategy={{ type: 'fitGridWidth' }}
                domLayout="autoHeight"
              />
            </div>
          </Form.Item>
          <Form.Item name="totalHours">
            <Flex align="center" gap={10}>
              <div>Tổng giờ</div>
              <Input
                value={totalHours}
                readOnly
                style={{
                  width: 70,
                  textAlign: 'center'
                }}
              />
              <div>giờ /Tuần</div>
            </Flex>
          </Form.Item>
          <Flex justify="flex-end" gap={8}>
            <Button htmlType="submit" onClick={handleClose}>
              Hủy
            </Button>
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </Flex>
        </Form>
      </Modal>
    </div>
  )
}

export default ModalWorkingShifts
