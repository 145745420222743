import { useRef, useState } from 'react'
import ListPage from '../../../../components/list-page'
import { Checkbox, Flex, message } from 'antd'
import Status from '../../../../constant/constantStatus'
import { ActivisionColumn } from '../../../../components/list-page/ActivisionColumn'
import ModalWorkingShifts from './ModalWorkingShifts'
import BaseService from '../../../../api/base-service'
import { useMutation } from '@tanstack/react-query'

const WorkingShiftsService = new BaseService('/core/working-shifts')

const WorkingShifts = () => {
  const [id, setId] = useState(null)
  const childRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleCancel = () => {
    setId(null)
    setIsModalOpen(false)
  }

  const columns = [
    {
      title: 'Tên ca làm',
      dataIndex: 'name',
      key: 'name',
      width: 220,
      align: 'center'
    },
    {
      title: 'Giờ công/Tuần',
      dataIndex: 'totalHours',
      key: 'totalHours',
      width: 250,
      align: 'center'
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'createdBy',
      key: 'createdBy',
      showSetting: false
    },
    {
      title: 'Ghi chú',
      dataIndex: 'notes',
      key: 'notes',
      ellipsis: true
    },
    {
      showSetting: false,
      title: 'Ca đêm',
      dataIndex: 'isNightShift',
      key: 'isNightShift',
      ellipsis: true,
      render: (_, record) => <Checkbox checked={record.isNightShift} disabled></Checkbox>,
      align: 'center'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      render: (_, record) => (
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <ActivisionColumn status={record.status} />
        </Flex>
      ),
      align: 'center',
      width: 150
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (text, record) => (
        <Flex justify="center" gap={4}>
          <a onClick={() => handleOpenModalEdit(record.id)}>Edit</a>/
          <a onClick={() => handleToggleStatus(record)}>
            {record.status === Status.ACTIVE.description ? 'Khóa' : 'Mở khóa'}
          </a>
        </Flex>
      ),
      align: 'center',
      dataIndex: 'action',
      width: 200,
      fixed: 'right'
    }
  ]

  const { mutate } = useMutation({
    mutationFn: async (record) => {
      const newStatus = record.status === Status.ACTIVE.description ? 'DEACTIVE' : 'ACTIVE'
      const result = await WorkingShiftsService.update(`${record.id}/status`, { status: newStatus })
      return result
    },
    onSuccess: (result) => {
      if (result.code === 2000) {
        handleRefresh()
      } else {
        message.error('Thao tác thất bại')
      }
    },
    onError: (error) => {
      message.error(`Gửi yêu cầu lưu thất bại (Lỗi: ${error.message}})`)
    }
  })

  const handleRefresh = () => {
    childRef.current.fetch()
  }

  const handleOpenModalAdd = () => {
    setId(null)
    setIsModalOpen(true)
  }

  const handleOpenModalEdit = (recordId) => {
    setId(recordId)
    setIsModalOpen(true)
  }

  const handleToggleStatus = (record) => {
    mutate(record)
  }

  return (
    <div>
      <ListPage
        request={async (params) => await WorkingShiftsService.get(params)}
        columns={columns}
        onCreate={handleOpenModalAdd}
        showPagination={false}
        ref={childRef}
        searchPrimaryKey="name"
        name={'working-shifts'}
      />
      <ModalWorkingShifts id={id} isModalOpen={isModalOpen} handleCancel={handleCancel} handleRefresh={handleRefresh} />
    </div>
  )
}

export default WorkingShifts
