import { Link, useNavigate } from 'react-router-dom'
import ListPage from '../../../components/list-page'
import BaseService from '../../../api/base-service'
import { URL_ACCOUNT, URL_ACCOUNT_ME } from '../../../constant/constantURL'
import { Flex, Space, Typography, message } from 'antd'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import { ActivisionColumn } from '../../../components/list-page/ActivisionColumn'
import UserChangePasswordModal from './UserChangePasswordModal'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import Status from '../../../constant/constantStatus'
import useCompaniesBrief from '../../../hooks/system/useCompaniesBrief'
import useDepartmentsBrief from '../../../hooks/system/useDepartmentsBrief'
import useFetchRoles from '../../../hooks/system/useFetchRoles'
import useConstructionsBriefs from '../../../hooks/system/useConstructionsBrief'
import { enumBooleanSelectOptions, enumStatusSelectOptions } from '../../../constant/enumSelectOptions'
import useAccountsMe from '../../../hooks/system/useAccountMe'

const UserAccountService = new BaseService(URL_ACCOUNT)

const UserList = () => {
  let navigate = useNavigate()
  const listPageRef = useRef()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [companies, setCompanies] = useState([])
  const [departments, setDepartments] = useState([])
  const [roles, setRoles] = useState([])
  const [constructions, setConstructions] = useState([])
  const companyResult = useCompaniesBrief()
  const departmentResult = useDepartmentsBrief()
  const roleResult = useFetchRoles()
  const constructionResult = useConstructionsBriefs()
  const myAccountResult = useAccountsMe()

  const mutaion = useMutation({
    mutationFn: (value) =>
      !!value?.status && value?.status !== 'ACTIVE'
        ? UserAccountService.patch(value?.id, 'unlock-account')
        : UserAccountService.patch(value?.id, 'lock-account'),
    onSuccess: (result) => {
      if (result.code === 2000) {
        message.success('Cập nhật trạng thái thành công!')
        listPageRef.current.fetch()
      } else {
        message.error(`Cập nhật trạng thái thất bại (Lỗi: ${result.message})`)
      }
    },
    onError: (error) => {
      message.error(`Gửi yêu cầu cập nhật trạng thái thất bại (Lỗi: ${error.message})`)
    }
  })

  const columns = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'staffId',
      key: 'staffId',
      width: 125
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 220
    },
    {
      title: 'Tài khoản',
      dataIndex: 'username',
      key: 'name',
      width: 150
    },
    {
      title: 'Admin',
      dataIndex: 'isSuperAdmin',
      key: 'isSuperAdmin',
      width: 75,
      align: 'center',
      render: (_, record) => (
        <Flex justify="center">{record?.isSuperAdmin ? <CheckOutlined /> : <CloseOutlined />}</Flex>
      ),
      search: true,
      inputType: 'select',
      searchField: 'isSuperAdmin',
      options: enumBooleanSelectOptions
    },
    {
      title: 'Mobile',
      dataIndex: 'isAccessMobile',
      key: 'isAccessMobile',
      width: 75,
      align: 'center',
      render: (_, record) => (
        <Flex justify="center">{record?.isAccessMobile ? <CheckOutlined /> : <CloseOutlined />}</Flex>
      ),
      search: true,
      inputType: 'select',
      searchField: 'isAccessMobile',
      options: enumBooleanSelectOptions
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      key: 'note',
      width: 150,
      ellipsis: true
    },
    {
      title: 'Đổi mật khẩu',
      dataIndex: 'changePassword',
      key: 'changePassword',
      width: 125,
      align: 'center',
      render: (_, record) => (
        <Flex justify="center">
          <a onClick={() => handleOpenModal(record?.id)}>Đổi mật khẩu</a>
        </Flex>
      )
    },
    {
      title: 'Công ty',
      dataIndex: 'companyOfStaffCode',
      key: 'companyOfStaffCode',
      width: 150,
      mainSearch: true,
      inputType: 'select',
      searchField: 'companyId',
      options: companies
    },
    {
      title: 'Chức vụ',
      dataIndex: 'position',
      key: 'position',
      width: 150,
      showSetting: false
    },
    {
      title: 'Phòng ban',
      dataIndex: 'companyOfDepartmentCode',
      key: 'companyOfDepartmentCode',
      width: 125,
      showSetting: false,
      search: true,
      inputType: 'select',
      searchField: 'departmentId',
      options: departments
    },
    {
      title: 'Công trình',
      dataIndex: 'constructionOfAccountCode',
      key: 'constructionOfAccountCode',
      width: 150,
      showSetting: false,
      render: (_, record) => (
        <Typography.Text>
          {record?.isAllConstruction ? 'Toàn quyền' : record?.constructionOfAccountCode}
        </Typography.Text>
      ),
      search: true,
      inputType: 'select',
      searchField: 'constructionId',
      options: [{ value: 0, label: 'Toàn quyền' }, ...constructions]
    },
    {
      title: 'Quyền',
      dataIndex: 'roles',
      key: 'roles',
      width: 150,
      showSetting: false,
      search: true,
      inputType: 'select',
      searchField: 'roleId',
      options: roles
    },
    {
      title: 'Ngày sửa cuối',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      width: 150,
      showSetting: false
    },
    {
      title: 'Người sửa cuối',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      width: 150,
      showSetting: false
    },
    {
      title: 'Trạng thái',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (_, record) => (
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <ActivisionColumn status={record?.status} />
        </Flex>
      ),
      search: true,
      inputType: 'select',
      searchField: 'status',
      options: enumStatusSelectOptions
    },
    {
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`chi-tiet/${record.id}`}>Edit</Link>
          <a onClick={() => handleLock(record)}> {record?.status === Status.ACTIVE.description ? 'Khóa' : 'Mở khóa'}</a>
        </Space>
      ),
      fixed: 'right',
      width: 150
    }
  ]

  const handleLock = (value) => {
    mutaion.mutate(value)
  }

  const handleOpenModal = (id) => {
    setCurrentId(id)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setCurrentId(null)
    setIsModalOpen(false)
  }

  const handleRefetch = () => {
    listPageRef.current?.fetch()
  }

  const handleCreate = () => {
    navigate('them-moi')
  }

  useEffect(() => {
    if (myAccountResult.data?.data) {
      setIsSuperAdmin(myAccountResult.data.data.isSuperAdmin)
    }
  }, [myAccountResult.data])

  useEffect(() => {
    if (companyResult.data?.data) {
      setCompanies(
        companyResult.data.data.map((value) => {
          return { value: value?.id, label: value?.name }
        })
      )
    }
  }, [companyResult.data])

  useEffect(() => {
    if (departmentResult.data?.data) {
      setDepartments(
        departmentResult.data.data.map((value) => {
          return { value: value?.id, label: value?.name }
        })
      )
    }
  }, [departmentResult.data])

  useEffect(() => {
    if (roleResult.data?.data) {
      setRoles(
        roleResult.data.data.map((value) => {
          return { value: value?.id, label: value?.name }
        })
      )
    }
  }, [roleResult.data])

  useEffect(() => {
    if (constructionResult.data?.data) {
      setConstructions(
        constructionResult.data.data.map((value) => {
          return { value: value?.id, label: value?.name }
        })
      )
    }
  }, [constructionResult.data])

  return (
    <div>
      <ListPage
        ref={listPageRef}
        request={async (params) => UserAccountService.get(params)}
        key={'account'}
        columns={columns}
        onCreate={handleCreate}
      />
      {isModalOpen && (
        <UserChangePasswordModal
          id={currentId}
          isModalOpen={isModalOpen}
          isSuperAdmin={isSuperAdmin}
          handleCloseModal={handleCloseModal}
          handleRefetch={handleRefetch}
        />
      )}
    </div>
  )
}

export default UserList
