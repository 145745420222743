import Axios from 'axios'

export const headerRequest = () => {
  const header = {
    'Content-Type': 'application/json'
  }
  const token = JSON.parse(localStorage.getItem('accessToken'))
  const tzOffset = Intl.DateTimeFormat().resolvedOptions().timeZone

  header['X-TZ-Offset'] = tzOffset
  header['Accept-Language'] = 'vi'

  if (token && token !== 'undefined') {
    header.Authorization = `Bearer ${token}`
  }
  return header
}

const httpRequest = Axios.create({
  baseURL: process.env.REACT_APP_API_ROOT
})

httpRequest.interceptors.request.use(
  function (config) {
    config.headers = headerRequest()
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

httpRequest.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default httpRequest
