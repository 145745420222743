export const themeAntd = {
  token: {
    colorPrimary: '#4479c5',
    colorInfo: '#4479c5'
  },
  components: {
    Button: {
      defaultColor: 'rgb(112, 126, 147)',
      colorBgContainerDisabled: 'rgb(242, 246, 251)',
      colorTextDisabled: 'rgb(199, 210, 227)',
      defaultBorderColor: '#C7D2E3'
    },
    Input: {
      colorBorder: '#C7D2E3'
    },
    Form: {
      colorBorder: '#C7D2E3'
    },
    Select: {
      colorBorder: 'rgb(199, 210, 227)'
    },
    DatePicker: {
      colorBorder: 'rgb(199, 210, 227)'
    },
    Pagination: {
      itemSize: 32,
      itemSizeSM: 32
    }
  }
}
