import { useQuery } from '@tanstack/react-query'
import { Tabs } from 'antd'
import { configOptionCategoryService } from '../config-service'
import OptionsContent from './OptionsContent'

const ConfigPage = () => {
  const { data = [] } = useQuery({
    queryKey: ['config-option-categories'],
    queryFn: () => configOptionCategoryService.get().then((res) => res.data)
  })

  const mappingTabs = data.map((item) => ({
    key: item.id,
    label: item.name,
    children: <OptionsContent id={item.id} key={item.id} />
  }))
  return (
    <div>
      <Tabs items={mappingTabs} />
    </div>
  )
}

export default ConfigPage
