import { useRef, useState } from 'react'
import ListPage from '../../../../components/list-page'
import DocumentSettingModal, { DocumentSettingService } from './DocumentSettingModal'
import { Button, Flex } from 'antd'

const DocumentSetting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const listPageRef = useRef()

  const [columnsDef] = useState([
    {
      title: 'Tên chứng từ',
      dataIndex: 'name',
      key: 'name',
      width: 300
    },
    {
      title: 'Hiển thị',
      dataIndex: 'example',
      key: 'example'
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <Flex justify="center">
          <Button type="text" size="small" onClick={() => handleOpenModal(record.id)}>
            Edit
          </Button>
        </Flex>
      )
    }
  ])

  const handleOpenModal = (id) => {
    setCurrentId(id)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleRefresh = () => {
    listPageRef.current.fetch()
  }

  return (
    <>
      <ListPage
        request={async () => DocumentSettingService.get()}
        name="document-setting"
        columns={columnsDef}
        searchPrimaryKey=""
        showPagination={false}
        showRefreshButton={false}
        showSettingContent={false}
        ref={listPageRef}
      />
      {isModalOpen && (
        <DocumentSettingModal
          id={currentId}
          isOpenModal={isModalOpen}
          handleRefresh={handleRefresh}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  )
}

export default DocumentSetting
