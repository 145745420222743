import { ConfigProvider } from 'antd'
import viVN from 'antd/locale/vi_VN'
import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { themeAntd } from './config/theme-antd'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import RouterComponent from './router'
import store from './store'
import './styles/style.scss'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()

const App = () => {
  const loading = <div>Loading...</div>
  return (
    <Suspense fallback={loading}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Provider store={store}>
          <ConfigProvider theme={themeAntd} locale={viVN}>
            <RouterComponent />
          </ConfigProvider>
        </Provider>
      </QueryClientProvider>
    </Suspense>
  )
}

export default App
